import React, { Component } from 'react';
import { Input, Select, Row, Button, message, Radio, Icon, Switch, Alert } from 'antd';
import FormattingField from '../../../Partials/FormattingField';
import { set, merge } from 'lodash';
import { CDN_URL, BASE_URL } from '../../../../../config';
import playButton1 from '../../../../../assets/images/playbtns/1.png';
import playButton2 from '../../../../../assets/images/playbtns/2.png';
import playButton3 from '../../../../../assets/images/playbtns/3.png';
import playButton4 from '../../../../../assets/images/playbtns/4.png';
import playButton5 from '../../../../../assets/images/playbtns/5.png';
import playButton6 from '../../../../../assets/images/playbtns/6.png';
import playButton7 from '../../../../../assets/images/playbtns/7.png';
import playButton8 from '../../../../../assets/images/playbtns/8.png';
import playButton9 from '../../../../../assets/images/playbtns/9.png';
import playButton10 from '../../../../../assets/images/playbtns/10.png';
import mute1 from '../../../../../assets/images/mutebtns/mute1.png';
import mute2 from '../../../../../assets/images/mutebtns/mute2.png';
const { Option } = Select;

class MessengerPersonalisedVideoElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'messenger_personalised_video',
      element_group: 'messenger_elements',
      element_domid: this.props.match.params.domid,
      data: {
        media_id: "",
        text: {
          enabled: "0",
          text: "[FNAME]",
          fallbacktext: "",
          position: "bottom-center"
        },
        play_btn: {
          enabled: "0",
          icon: "play1",
          position: ""
        },
        mute_btn: {
          enabled: "0",
          icon: "mute1"
        },
        redirect_url: "",
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.media_id.trim().length === 0) {
      errors.media_id = "This is a required field!";
    }
    if (element.data.text.enabled === 1 || element.data.text.enabled === '1') {
      if (element.data.text.text.trim() === '') {
        errors.text = "This is a required field!";
      }
      // if (element.data.text.fallbacktext.trim() === '') {
      //   errors.fallbacktext = "This is a required field!";
      // }
      if (element.data.text.position.trim() === '') {
        errors.textposition = "This is a required field!";
      }
    }
    if (element.data.play_btn.enabled === 1 || element.data.play_btn.enabled === '1') {
      if (element.data.play_btn.icon.trim() === '') {
        errors.play_icon = "This is a required field!";
      }
      if (element.data.play_btn.position.trim() === '') {
        errors.playposition = "This is a required field!";
      }
    }
    if (element.data.mute_btn.enabled === 1 || element.data.mute_btn.enabled === '1') {
      if (element.data.mute_btn.icon.trim() === '') {
        errors.mute_icon = "This is a required field!";
      }
    }
    if (!element.data.redirect_url.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.redirect_url = "Please enter a valid URL!";
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
            this.setState({
              saving: false
            })
            if (res.data.success !== 1) {
              message.error(res.data.msg);
            }
          })
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  onChoose(media) {
    let media_link = '';
    if (media.media_type === "link") {
      media_link = `${BASE_URL}uploads/links/${media.media_filename}`;
    } else if (media.media_type === "image") {
      media_link = `${BASE_URL}uploads/images/${media.media_filename}`;
    } else {
      if (media.processed === 1 || media.processed === '1') {
        if (media.media_type === "video") {
          media_link = `${CDN_URL}images/gifs/${media._id}.gif`;
        } else {
          media_link = `${CDN_URL}images/thumbs/${media._id}.png`;
        }
      } else {
        media_link = `${CDN_URL}global/images/processing.gif`;
      }
    }
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          media_id: media._id,
          image_link: `${media_link}`
        }
      }
    }, () => this.props.onUpdate(this.state.element));
    this.props.toggleLibraryModal();
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.media_id ? 'has-error' : ''}`}>
          <p className="form-labels">Choose Video</p>
          {this.state.errors.media_id && <div className="ant-form-explain">{this.state.errors.media_id}</div>}
        </div>

        <div className="upload-actions">
          {this.state.element.data.media_id.length > 0 &&
            <div className="upload-action">
              <img src={`${CDN_URL}images/thumbs/${this.state.element.data.media_id}.png`} alt="" style={{ width: '100%', height: '100%' }} />
            </div>
          }
          <div className="upload-action" onClick={() => this.props.toggleLibraryModal("videonew", (media) => this.onChoose(media), 'upload')}>
            <p>
              <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
              Upload
            </p>
          </div>
          <div className="upload-action" onClick={() => this.props.toggleLibraryModal("videonew", (media) => this.onChoose(media), 'library')}>
            <p>
              <Icon type="folder-open" style={{ color: "#D9D9D9" }} /> <br />
              Library
            </p>
          </div>
        </div>
        {this.state.element.data.media_id.length > 0 && <Alert style={{ marginTop: 10 }} message="While the video is being processed, the live preview will show a thumbnail of the video." type="info" />}


        <div className={`ant-form-item-control`}>
          <p className="form-labels">Enable Personalised Text &nbsp;&nbsp;&nbsp;
          <Switch
              checked={this.state.element.data.text.enabled === '1' || this.state.element.data.text.enabled === 1}
              onChange={(checked) => { this.updateField("data.text.enabled", checked ? '1' : '0') }} />
          </p>
        </div>
        {(this.state.element.data.text.enabled === 1 || this.state.element.data.text.enabled === '1') &&
          <div className="indented">
            <FormattingField
              maxChars={27}
              label={<span>Text (Character Limit: 27 Characters)</span>}
              error={this.state.errors.text}
              field={(props) => <Input placeholder="" {...props} />}
              personalizations={[
                { tag: '[FNAME]', title: 'Full Name' },
              ]}
              defaultValue={this.state.element.data.text.text}
              onChange={(val) => this.updateField("data.text.text", val)} />
            {/* <div className={`ant-form-item-control ${this.state.errors.fallbacktext ? 'has-error' : ''}`}>
              <p className="form-labels">Fallback to [FNAME]
              <Input
                  value={this.state.element.data.text.fallbacktext}
                  onChange={(e) => { this.updateField("data.text.fallbacktext", e.target.value) }} />
              </p>
              {this.state.errors.fallbacktext && <div className="ant-form-explain">{this.state.errors.fallbacktext}</div>}
            </div> */}

            <div className={`ant-form-item-control ${this.state.errors.textposition ? 'has-error' : ''}`}>
              <p className="form-labels">Position</p>
              <Select
                value={this.state.element.data.text.position}
                onChange={(val) => { this.updateField("data.text.position", val) }}
                placeholder="Select Position"
              >
                <Option value="top-right">Top Right</Option>
                <Option value="center">Center</Option>
                <Option value="bottom-center">Bottom Center</Option>
              </Select>
              {this.state.errors.textposition && <div className="ant-form-explain">{this.state.errors.textposition}</div>}
            </div>
          </div>
        }

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Enable Play Button &nbsp;&nbsp;&nbsp;
          <Switch
              checked={this.state.element.data.play_btn.enabled === '1' || this.state.element.data.play_btn.enabled === 1}
              onChange={(checked) => { this.updateField("data.play_btn.enabled", checked ? '1' : '0') }} />
          </p>
        </div>
        {(this.state.element.data.play_btn.enabled === 1 || this.state.element.data.play_btn.enabled === '1') &&
          <div className="indented">
            <div className={`ant-form-item-control ${this.state.errors.play_icon ? 'has-error' : ''}`}>
              <p className="form-labels">Choose Play Button</p>
              <Radio.Group onChange={(e) => this.updateField("data.play_btn.icon", e.target.value)} value={this.state.element.data.play_btn.icon}>
                <Radio.Button value="play1"><p style={{ width: 50, height: 50 }}><img src={playButton1} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="play2"><p style={{ width: 50, height: 50 }}><img src={playButton2} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="play3"><p style={{ width: 50, height: 50 }}><img src={playButton3} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="play4"><p style={{ width: 50, height: 50 }}><img src={playButton4} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="play5"><p style={{ width: 50, height: 50 }}><img src={playButton5} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="play6"><p style={{ width: 50, height: 50 }}><img src={playButton6} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="play7"><p style={{ width: 50, height: 50 }}><img src={playButton7} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="play8"><p style={{ width: 50, height: 50 }}><img src={playButton8} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="play9"><p style={{ width: 50, height: 50 }}><img src={playButton9} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="play10"><p style={{ width: 50, height: 50 }}><img src={playButton10} style={{ width: "100%" }} alt="" /></p></Radio.Button>
              </Radio.Group>
              {this.state.errors.play_icon && <div className="ant-form-explain">{this.state.errors.play_icon}</div>}
            </div>

            <div className={`ant-form-item-control ${this.state.errors.playposition ? 'has-error' : ''}`}>
              <p className="form-labels">Position</p>
              <Select
                value={this.state.element.data.play_btn.position}
                onChange={(val) => { this.updateField("data.play_btn.position", val) }}
                placeholder="Select Position"
              >
                <Option value="top-right">Top Right</Option>
                <Option value="center">Center</Option>
              </Select>
              {this.state.errors.playposition && <div className="ant-form-explain">{this.state.errors.playposition}</div>}
            </div>
          </div>
        }

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Enable Mute Button &nbsp;&nbsp;&nbsp;
          <Switch
              checked={this.state.element.data.mute_btn.enabled === '1' || this.state.element.data.mute_btn.enabled === 1}
              onChange={(checked) => { this.updateField("data.mute_btn.enabled", checked ? '1' : '0') }} />
          </p>
        </div>
        {(this.state.element.data.mute_btn.enabled === 1 || this.state.element.data.mute_btn.enabled === '1') &&
          <div className="indented">
            <div className={`ant-form-item-control ${this.state.errors.mute_icon ? 'has-error' : ''}`}>
              <p className="form-labels">Choose Mute Button</p>
              <Radio.Group onChange={(e) => this.updateField("data.mute_btn.icon", e.target.value)} value={this.state.element.data.mute_btn.icon}>
                <Radio.Button value="mute1"><p style={{ width: 50, height: 50 }}><img src={mute1} style={{ width: "100%" }} alt="" /></p></Radio.Button>
                <Radio.Button value="mute2"><p style={{ width: 50, height: 50 }}><img src={mute2} style={{ width: "100%" }} alt="" /></p></Radio.Button>
              </Radio.Group>
              {this.state.errors.mute_icon && <div className="ant-form-explain">{this.state.errors.mute_icon}</div>}
            </div>
          </div>
        }

        <div className={`ant-form-item-control ${this.state.errors.redirect_url ? 'has-error' : ''}`}>
          <p className="form-labels">Redirect URL
          <Input
              value={this.state.element.data.redirect_url}
              onChange={(e) => { this.updateField("data.redirect_url", e.target.value) }}
              onBlur={(e) => { this.updateField("data.redirect_url", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
          </p>
          {this.state.errors.redirect_url && <div className="ant-form-explain">{this.state.errors.redirect_url}</div>}
        </div>

        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button> &nbsp;
        </Row>
      </form>
    )
  }

}

export default MessengerPersonalisedVideoElement;