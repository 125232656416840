import React, { Component } from 'react';
import './LinkCard.scss';
import { Card, Menu, Dropdown, Icon, Alert, Skeleton, Row } from 'antd';
import { ReactComponent as PinterestLogo } from '../../../assets/images/logos/pinterest.svg';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ClickToCopy from '../Partials/ClickToCopy';
import { ReactComponent as ClickIcon } from '../../../assets/images/icons/click.svg';
import './LinkCard.scss';

class LinkCard extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: '',
    link: this.props.link ? this.props.link : {}
  };

  postLink(network, url) {
    const networks = {
      facebook: "https://www.facebook.com/sharer/sharer.php?u=",
      twitter: "https://twitter.com/intent/tweet?text=",
      gplus: "https://plus.google.com/share?url=",
      linkedin: "https://www.linkedin.com/cws/share?url=",
      pinterest: "https://pinterest.com/pin/create/button/?description=&media=&url="
    }
    const networkURL = networks[network];
    window.open(networkURL + encodeURIComponent(url), '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
  }

  render() {
    const { loading, error } = this.state;
    if (loading) {
      return <Card style={{ marginBottom: "30px" }}>
        <Skeleton active />
      </Card>
    }

    if (!loading && error) {
      return <div>
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      </div>;
    }

    const { link } = this.state;

    let clicks = 0;
    if (link.link_analytics && link.link_analytics.clicks && link.link_analytics.clicks.overall && link.link_analytics.clicks.overall.count) {
      clicks = link.link_analytics.clicks.overall.count;
    }

    let displayURL = "";
    let protocol = "https://";
    if (link.link_openas === "iframe") {
      protocol = "http://";
    }
    if (link.link_domtype === "Whitelabel") {
      displayURL = protocol + link.link_wlbldom + '/' + link.link_slug;
    } else {
      if (!link.link_domain) {
        link.link_domain = "letspin.io";
      }
      displayURL = protocol + link.link_sbdom + '.' + link.link_domain + '/' + link.link_slug;
    }

    const redirectTypes = {
      iframe: "Open in iFrame",
      redirect: "Redirect",
      redirect301: "Server Side Redirect (301 Redirect)"
    };

    return (
      <Card style={{ margin: "15px 0" }}>
        <div className="element-preview"></div>
        <div className="element-details">
          <ul>
            <li><b>Destination URL</b><br />{link.link_url}</li>
            <li><b>Type</b><br />{redirectTypes[link.link_openas]}</li>
            <li><b>Cloaked URL <ClickToCopy textToCopy={displayURL}><Icon type="copy" /></ClickToCopy></b><br /> {displayURL} </li>
            <li><b>Created</b><br />{moment(link.link_created).format("MMM DD, YYYY a\t HH:MM a")}</li>
          </ul>
        </div>
        <div className="element-actions">
          <ul>
            <li><Icon component={ClickIcon} /> <br /> {clicks} Clicks</li>
            {!this.props.hideReport && <li><Link to={`/${this.props.auth.domain.id}/link_details/${link._id["$id"]}`}><Icon type="pie-chart" /> <br /> Report</Link></li>}
            <li>
              <Dropdown overlay={
                <Menu>
                  <Menu.Item><a href="#/" onClick={() => this.postLink("facebook", displayURL)}><Icon type="facebook" /> Post Now on Facebook</a></Menu.Item>
                  <Menu.Item><a href="#/" onClick={() => this.postLink("twitter", displayURL)}><Icon type="twitter" /> Post Now on Twitter</a></Menu.Item>
                  <Menu.Item><a href="#/" onClick={() => this.postLink("gplus", displayURL)}><Icon type="google-plus" /> Post Now on Google Plus</a></Menu.Item>
                  <Menu.Item><a href="#/" onClick={() => this.postLink("linkedin", displayURL)}><Icon type="linkedin" /> Post Now on LinkedIn</a></Menu.Item>
                  <Menu.Item><a href="#/" onClick={() => this.postLink("pinterest", displayURL)}><Icon component={PinterestLogo} /> Post Now on Pinterest</a></Menu.Item>
                </Menu>
              }>
                <a className="ant-dropdown-link" href="#/">
                  <Icon type="share-alt" /> <br /> Share <Icon type="down" />
                </a>
              </Dropdown>
            </li>
          </ul>
        </div>
      </Card>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  };
};

export default connect(mapStateToProps)(LinkCard);