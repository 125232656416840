import React, { Component } from 'react';
import { Input, Select, Switch, Row, Col, Button, message, Tooltip, Icon } from 'antd';
import { set, merge } from 'lodash';
import ColorPicker from '../ColorPicker';
const { Option } = Select;

class YesNoComponent extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'yes_no_bar',
      element_group: 'self_interacting_elements',
      element_domid: this.props.match.params.domid,
      data: {
        question: '',
        yes_text: '',
        no_text: '',
        customurlenabled: "0",
        redirect_urls: {
          yes: '',
          no: '',
        },
        yestextcolor: '#fff',
        yesbgcolor: '#0000ff',
        notextcolor: '#909090',
        nobgcolor: '#fff'
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.question.trim().length === 0) {
      errors.question = "This is a required field!";
    }
    if (element.data.yes_text.trim().length === 0) {
      errors.yes_text = "This is a required field!";
    }
    if (element.data.no_text.trim().length === 0) {
      errors.no_text = "This is a required field!";
    }
    if (element.data.customurlenabled === '1' || element.data.customurlenabled === 1) {
      if (!element.data.redirect_urls.yes.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
        errors.yes_url = "Please enter a valid URL!";
      }
      if (!element.data.redirect_urls.no.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
        errors.no_url = "Please enter a valid URL!";
      }
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
            this.setState({
              saving: false
            })
            if (res.data.success !== 1) {
              message.error(res.data.msg);
            }
          })
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>
        <div className={`ant-form-item-control ${this.state.errors.question ? 'has-error' : ''}`}>
          <p className="form-labels">Question
          <Input
              value={this.state.element.data.question}
              onChange={(e) => { this.updateField("data.question", e.target.value) }} />
          </p>
          {this.state.errors.question && <div className="ant-form-explain">{this.state.errors.question}</div>}
        </div>
        <div className={`ant-form-item-control ${this.state.errors.yes_text ? 'has-error' : ''}`}>
          <p className="form-labels">Yes Button Text
          <Input
              value={this.state.element.data.yes_text}
              onChange={(e) => { this.updateField("data.yes_text", e.target.value) }} />
          </p>
          {this.state.errors.yes_text && <div className="ant-form-explain">{this.state.errors.yes_text}</div>}
        </div>
        <div className={`ant-form-item-control ${this.state.errors.no_text ? 'has-error' : ''}`}>
          <p className="form-labels">No Button Text
          <Input
              value={this.state.element.data.no_text}
              onChange={(e) => { this.updateField("data.no_text", e.target.value) }} />
          </p>
          {this.state.errors.no_text && <div className="ant-form-explain">{this.state.errors.no_text}</div>}
        </div>
        <div className={`ant-form-item-control`}>
          <p className="form-labels">Enable Custom Thank You URL &nbsp;&nbsp;&nbsp;
          <Switch
              disabled={!(this.props.plan && this.props.plan.plan_features && this.props.plan.plan_features.CUSTOM_URLS === 1)}
              checked={this.state.element.data.customurlenabled === '1' || this.state.element.data.customurlenabled === 1}
              onChange={(checked) => { this.updateField("data.customurlenabled", checked ? '1' : '0') }} />

            {!(this.props.plan && this.props.plan.plan_features && this.props.plan.plan_features.CUSTOM_URLS === 1) && <Tooltip title="Your plan does not allow you to add a Custom Thank You URL!"><Icon type="info-circle" style={{ marginLeft: 15, verticalAlign: 'middle' }} /></Tooltip>}
          </p>
        </div>
        {(this.state.element.data.customurlenabled === '1' || this.state.element.data.customurlenabled === 1) && <div className="url-settings indented">
          <div className={`ant-form-item-control ${this.state.errors.yes_url ? 'has-error' : ''}`}>
            <p className="form-labels">Custom Thank You URL for "Yes" button
            <Input
                value={this.state.element.data.redirect_urls.yes}
                onChange={(e) => { this.updateField("data.redirect_urls.yes", e.target.value) }}
                onBlur={(e) => { this.updateField("data.redirect_urls.yes", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
            </p>
            {this.state.errors.yes_url && <div className="ant-form-explain">{this.state.errors.yes_url}</div>}
          </div>
          <div className={`ant-form-item-control ${this.state.errors.no_url ? 'has-error' : ''}`}>
            <p className="form-labels">Custom Thank You URL for "No" button
            <Input
                value={this.state.element.data.redirect_urls.no}
                onChange={(e) => { this.updateField("data.redirect_urls.no", e.target.value) }}
                onBlur={(e) => { this.updateField("data.redirect_urls.no", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
            </p>
            {this.state.errors.no_url && <div className="ant-form-explain">{this.state.errors.no_url}</div>}
          </div>
        </div>}

        <Row>
          <Col xs={12}><ColorPicker label="Yes Button Text Color" defaultValue={this.state.element.data.yestextcolor} onChange={(color) => this.updateField("data.yestextcolor", color)} /></Col>
          <Col xs={12}><ColorPicker label="Yes Button Background Color" defaultValue={this.state.element.data.yesbgcolor} onChange={(color) => this.updateField("data.yesbgcolor", color)} /></Col>
        </Row>

        <Row>
          <Col xs={12}><ColorPicker label="No Button Text Color" defaultValue={this.state.element.data.notextcolor} onChange={(color) => this.updateField("data.notextcolor", color)} /></Col>
          <Col xs={12}><ColorPicker label="No Button Background Color" defaultValue={this.state.element.data.nobgcolor} onChange={(color) => this.updateField("data.nobgcolor", color)} /></Col>
        </Row>

        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button>
        </Row>
      </form>
    )
  }
}

export default YesNoComponent;