import React from "react";
import './index.scss';
import { Tabs } from "antd";
import Pieslice from "../../Partials/Pieslice";

const DeviceWiseStats = (props) => {
    const { device_wise_clicks } = props;

    return (
        <Tabs defaultActiveKey="2" className="devicewise-stats-tabs">
            <Tabs.TabPane tab="Clicks" key="2">
                <Pieslice data={device_wise_clicks} category='device' type='clicks' />
            </Tabs.TabPane>
        </Tabs>
    );
}

export default DeviceWiseStats;