import React, { Component } from 'react';
import './index.scss';
import ElementCard from './ElementCard';
import MainHeadingBar from '../Partials/MainHeadingBar';
import { Row, Col, Skeleton, Alert, Button, Dropdown, Icon, Menu, Card, Pagination } from 'antd';
import { connect } from 'react-redux';
import { getElements } from '../../../helpers/ajax/elementsAjax';
import NoData from '../Partials/NoData';
import { ReactComponent as Placeholder } from '../../../assets/images/icons/no-data.svg';

class Elements extends Component {
    state = {
        loading: true,
        error: false,
        errorMsg: '',
        elements: [],
        archived: this.props.archived === "1" ? 1 : 0,
        sort: { element_created: -1 },
        paging: {
            current: 1,
            total: 0,
            pageSize: 12
        }
    };

    fetch = (order) => {
        this.setState({
            elements: [],
            loading: true
        }, () => {
            getElements(this.props.auth.domain.id, this.state.archived, this.state.paging.current, this.state.paging.pageSize, order).then(res => {
                this.setState({
                    loading: false,
                    elements: res.data.elements,
                    paging: {
                        ...this.state.paging,
                        total: res.data.total,
                    }
                });
            });
        })
    };

    componentDidMount = () => {
        this.fetch();
    };

    shouldComponentUpdate(np) {
        if (this.props.archived !== np.archived) {
            this.setState({
                loading: true,
                error: false,
                errorMsg: '',
                elements: [],
                archived: np.archived === "1" ? 1 : 0,
                sort: { element_created: -1 },
                paging: {
                    current: 1,
                    total: 0,
                    pageSize: 10
                }
            }, () => this.fetch())
        }
        return true;
    }

    goToPage(page) {
        this.setState({
            paging: {
                ...this.state.paging,
                current: page
            }
        }, () => this.fetch())
    }

    onArchive(element) {
        this.setState({
            elements: this.state.elements.filter((e) => e._id !== element._id)
        })
    }

    render() {
        const { elements, loading, error, errorMsg, sort } = this.state;

        const sortMenu = (<Menu>
            <Menu.Item key={0}>
                <a href='#/' onClick={() => this.fetch({ element_created: 1 })}>
                    Asc. Order
                </a>
            </Menu.Item>
            <Menu.Item key={1}>
                <a href='#/' onClick={() => this.fetch({ element_created: -1 })}>
                    Desc. Order
                </a>
            </Menu.Item>
        </Menu>);

        const PageWrapper = (children) => (
            <div className="campaigns-container">
                <MainHeadingBar title={this.state.archived ? "Archived Elements" : "Elements"} helpText={`Here you will find all the ${this.state.archived ? 'archived' : ''} elements that you've created.`} pageCTA={
                    <span className="sort">
                        Sort By Date: <Dropdown overlay={sortMenu}>
                            {
                                sort.element_created === -1
                                    ? <Button size="default">Desc. Order <Icon type="down" /></Button>
                                    : <Button size="default">Asc. Order <Icon type="down" /></Button>
                            }
                        </Dropdown>
                    </span>
                } {...this.props} />
                {children}
            </div>
        );

        if (loading) {
            return PageWrapper(<Card style={{ marginBottom: "30px" }}>
                <Skeleton active />
            </Card>)
        }

        if (!loading && error) {
            return PageWrapper(<div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={errorMsg}
                        type="error"
                    />
                </Row>
            </div>);
        }

        return PageWrapper(
            <div className="elements">
                <Row gutter={30}>
                    {
                        elements.length
                            ? elements.map((element) => <Col xs={8} className="column" key={element._id} style={{ margin: "15px 0" }}><ElementCard onArchive={(element) => this.onArchive(element)} element={element} /></Col>)
                            : <NoData
                                image={Placeholder}
                                heading="No Elements Found!"
                            />
                    }
                </Row>
                <Pagination
                    onChange={(page) => this.goToPage(page)}
                    current={this.state.paging.current}
                    total={this.state.paging.total}
                    pageSize={this.state.paging.pageSize} />
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
};

export default connect(mapStateToProps)(Elements);