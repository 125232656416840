import Axios from "axios";

export const getAccounts = () => {
  return Axios({
    url: "/accounts/get_accounts",
    method: "post"
  })
    .then(res => {
      return {
        accounts: res.data.accounts,
        impressions: res.data.impressions,
        wlblCounts: res.data.wlblCounts,
        loading: false
      };
    })
    .catch(e => {
      return {
        error: true,
        errorMsg: e.message,
        loading: false
      };
    });
};

export const setViews = (domid, views) => {
  return Axios({
    url: "/accounts/set_account_views",
    method: "post",
    data: {
      domid,
      views
    }
  });
};

export const deleteAccount = domid => {
  return Axios({
    url: "/accounts/delete_account",
    method: "post",
    data: {
      domid
    }
  });
};

export const toggleAccountStatus = (domid, status) => {
  return Axios({
    url: "/accounts/toggle_account_status",
    method: "post",
    data: {
      domid,
      status
    }
  });
};

export const saveSignupQuestions = values => {
  return Axios({
    url: "/accounts/save_signup_questions",
    method: "post",
    data: {
      ...values
    }
  });
};
