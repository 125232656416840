import React from "react";
import { Table } from "antd";
import { Chart } from "react-google-charts";
import "./index.scss";
import NoData from "../../Partials/NoData";

const CountryWiseStats = props => {
  const { country_wise_clicks } = props;

  const tableData = {};
  country_wise_clicks.forEach(element => {
    if (tableData[element[0]] === undefined) {
      tableData[element[0]] = {
        clicks: 0,
        visitors: 0
      };
    }
    tableData[element[0]].clicks = element[1];
  });

  let tableArray = { ...tableData };
  delete tableArray["Country"];
  tableArray = Object.keys(tableArray).map(country => {
    return {
      country,
      visitors: tableArray[country]["visitors"],
      clicks: tableArray[country]["clicks"]
    };
  });

  return (
    <div className="countrywise-stats-container">
      {country_wise_clicks.length <= 1 ? (
        <NoData
          heading="Not enough data!"
          content="We could not find enough data to build a graph!"
        />
      ) : (
        <Chart
          height={500}
          chartType="GeoChart"
          data={country_wise_clicks}
          rootProps={{ "data-testid": "1" }}
          width="99%"
        />
      )}

      <div className="table">
        <div className="content-container">
          <Table
            columns={[
              {
                title: "Country",
                dataIndex: "country",
                key: "country",
                sorter: (a, b) =>
                  (a.country > b.country) - (a.country < b.country)
              },
              {
                title: props.type === "views" ? "Views" : "Clicks",
                dataIndex: "clicks",
                key: "clicks",
                sorter: (a, b) => a.clicks - b.clicks
              }
            ]}
            rowKey={r => r.country}
            pagination={false}
            dataSource={tableArray}
          />
        </div>
      </div>
    </div>
  );
};

export default CountryWiseStats;
