import React from "react";
import "./index.scss";
import Pieslice from "../../Partials/Pieslice";

const BrowserWiseStats = props => {
  const { browser_wise_clicks } = props;

  return (
    <Pieslice data={browser_wise_clicks} category="browser" type={props.type} />
  );
};

export default BrowserWiseStats;
