import React, { Component } from 'react';
import { Card, Table, Row, Col } from 'antd';
import moment from 'moment';
import SearchField from '../../Partials/SearchField';
import { getClickRecords } from '../../../../helpers/ajax/elementsAjax';

class RecordsClicks extends Component {
  state = {
    records: [],
    search: '',
    paging: {
      current: 1,
      total: 0,
      pageSize: 10
    }
  }

  fetch() {
    getClickRecords(this.props.match.params.domid, this.props.element._id["$id"], this.state.search, this.state.paging.pageSize, (this.state.paging.current - 1) * this.state.paging.pageSize).then(res => {
      this.setState({
        records: res.data.records,
        paging: {
          ...this.state.paging,
          total: res.data.total
        }
      })
    })
  }
  componentDidMount() {
    this.fetch();
  }

  goToPage(page) {
    this.setState({
      paging: {
        ...this.state.paging,
        current: page
      }
    }, () => this.fetch())
  }

  setFilter(val) {
    this.setState({ search: val }, () => {
      this.fetch();
    })
  }

  render() {
    const element = this.props.element;
    const recordElements = ["qna", "yes_no_bar", "feedback", "polls", "group_event_poll", "personalized_images", "self_destruct_message", "youtube_embed", "googlemap_embed", "footer_widget", "signature", "twitter_timeline", "twitter_tweet", "group_event_poll"];

    if (recordElements.indexOf(element.element_type) === -1) {
      let columns = [
        {
          title: "Email",
          dataIndex: '_id'
        },
        {
          title: "Name",
          dataIndex: 'name'
        },
        {
          title: 'Date',
          dataIndex: 'date',
          render: (text) => moment(text).format("DD MMM, YYYY")
        },
      ];
      return (
        <Card style={{ padding: "13px 30px" }}>
          <Row>
            <Col xs={12}><h1>Records</h1></Col>
            <Col xs={12}><SearchField placeholder="Search for emails" value={this.state.search} onChange={(val) => { this.setState({ search: val }) }} onSearch={(val) => this.setFilter(val)} /></Col>
          </Row>
          <Table
            key={r => r._id["$id"]}
            columns={columns}
            onChange={(page) => this.goToPage(page.current)}
            pagination={this.state.paging}
            dataSource={this.state.records} />
        </Card>
      )
    } else {
      return <div style={{ height: 0 }}></div>
    }
  }
}

export default RecordsClicks;