import React, { Component } from 'react';
import { Input, Select, Row, Button, message, Radio, Icon, Alert } from 'antd';
import { set, merge } from 'lodash';
import playButton1 from '../../../../../assets/images/playbtns/1.png';
import playButton2 from '../../../../../assets/images/playbtns/2.png';
import playButton3 from '../../../../../assets/images/playbtns/3.png';
import playButton4 from '../../../../../assets/images/playbtns/4.png';
import playButton5 from '../../../../../assets/images/playbtns/5.png';
import playButton6 from '../../../../../assets/images/playbtns/6.png';
import playButton7 from '../../../../../assets/images/playbtns/7.png';
import playButton8 from '../../../../../assets/images/playbtns/8.png';
import playButton9 from '../../../../../assets/images/playbtns/9.png';
import playButton10 from '../../../../../assets/images/playbtns/10.png';
import mute1 from '../../../../../assets/images/mutebtns/mute1.png';
import mute2 from '../../../../../assets/images/mutebtns/mute2.png';
const { Option } = Select;

class AutoplayVideoElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'video_to_gif',
      element_group: 'autoplay_video',
      element_domid: this.props.match.params.domid,
      data: {
        media_id: "",
        play_icon: "play0",
        mute_icon: "mute1",
        customurlenabled: "1",
        redirect_url: "",
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.media_id.trim().length === 0) {
      errors.media_id = "This is a required field!";
    }
    if (element.data.play_icon.trim().length === 0) {
      errors.play_icon = "This is a required field!";
    }
    if (element.data.mute_icon.trim().length === 0) {
      errors.mute_icon = "This is a required field!";
    }
    if (!element.data.redirect_url.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.redirect_url = "Please enter a valid URL!";
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
            this.setState({
              saving: false
            })
            if (res.data.success !== 1) {
              message.error(res.data.msg);
            }
          })
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  onChoose(media) {
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          media_id: media._id,
        }
      }
    }, () => this.props.onUpdate(this.state.element));
    this.props.toggleLibraryModal();
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.media_id ? 'has-error' : ''}`}>
          <p className="form-labels">Choose Video</p>
          {this.state.errors.media_id && <div className="ant-form-explain">{this.state.errors.media_id}</div>}
        </div>

        <div className="upload-actions">
          <div className="upload-action" onClick={() => this.props.toggleLibraryModal("videonew", (media) => this.onChoose(media), 'upload')}>
            <p>
              <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
              Upload
            </p>
          </div>
          <div className="upload-action" onClick={() => this.props.toggleLibraryModal("videonew", (media) => this.onChoose(media), 'library')}>
            <p>
              <Icon type="folder-open" style={{ color: "#D9D9D9" }} /> <br />
              Library
            </p>
          </div>
        </div>
        {this.state.element.data.media_id.length > 0 && <Alert style={{ marginTop: 10 }} message="While the video is being processed, the live preview will show a thumbnail of the video." type="info" />}

        <div className={`ant-form-item-control ${this.state.errors.play_icon ? 'has-error' : ''}`}>
          <p className="form-labels">Choose Play Button</p>
          <Radio.Group onChange={(e) => this.updateField("data.play_icon", e.target.value)} value={this.state.element.data.play_icon}>
            <Radio.Button value="play0"><p style={{ width: 50, height: 50 }}>No<br />Image</p></Radio.Button>
            <Radio.Button value="play1"><p style={{ width: 50, height: 50 }}><img src={playButton1} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="play2"><p style={{ width: 50, height: 50 }}><img src={playButton2} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="play3"><p style={{ width: 50, height: 50 }}><img src={playButton3} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="play4"><p style={{ width: 50, height: 50 }}><img src={playButton4} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="play5"><p style={{ width: 50, height: 50 }}><img src={playButton5} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="play6"><p style={{ width: 50, height: 50 }}><img src={playButton6} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="play7"><p style={{ width: 50, height: 50 }}><img src={playButton7} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="play8"><p style={{ width: 50, height: 50 }}><img src={playButton8} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="play9"><p style={{ width: 50, height: 50 }}><img src={playButton9} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="play10"><p style={{ width: 50, height: 50 }}><img src={playButton10} style={{ width: "100%" }} alt="" /></p></Radio.Button>
          </Radio.Group>
          {this.state.errors.play_icon && <div className="ant-form-explain">{this.state.errors.play_icon}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.mute_icon ? 'has-error' : ''}`}>
          <p className="form-labels">Choose Mute Button</p>
          <Radio.Group onChange={(e) => this.updateField("data.mute_icon", e.target.value)} value={this.state.element.data.mute_icon}>
            <Radio.Button value="mute1"><p style={{ width: 50, height: 50 }}><img src={mute1} style={{ width: "100%" }} alt="" /></p></Radio.Button>
            <Radio.Button value="mute2"><p style={{ width: 50, height: 50 }}><img src={mute2} style={{ width: "100%" }} alt="" /></p></Radio.Button>
          </Radio.Group>
          {this.state.errors.mute_icon && <div className="ant-form-explain">{this.state.errors.mute_icon}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.redirect_url ? 'has-error' : ''}`}>
          <p className="form-labels">Redirect URL
            <Input
              value={this.state.element.data.redirect_url}
              onChange={(e) => { this.updateField("data.redirect_url", e.target.value) }}
              onBlur={(e) => { this.updateField("data.redirect_url", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
          </p>
          {this.state.errors.redirect_url && <div className="ant-form-explain">{this.state.errors.redirect_url}</div>}
        </div>

        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button> &nbsp;
        </Row>
      </form>
    )
  }

}

export default AutoplayVideoElement;