import React, { Component } from 'react';
import './index.scss';
import { Card, Button, Row, Col, message, Checkbox, Icon, Skeleton, Alert, Input, Modal } from 'antd';
import { connect } from 'react-redux';
import { fetchYouzign, bulkFetch, saveYouzignKeys, removeYouzign } from '../../../helpers/ajax/libraryAjax';
import { ReactComponent as NoDataIcon } from '../../../assets/images/icons/no_data_found.svg';
import MainHeadingBar from '../Partials/MainHeadingBar';
import youzignLogo from '../../../assets/images/logos/youzign.png';
const { Meta } = Card;
const { confirm } = Modal;

class Youzign extends Component {
    state = {
        selectedMedia: [],
        media: [],
        total: 0,
        loading: true,
        error: false,
        errorMsg: '',
        filters: {
            search: ''
        },
        paging: {
            pageSize: 12,
            page: 1
        },
        publicKey: '',
        token: '',
        errors: {}
    };

    onSelectChange = (selectedMedia) => {
        this.setState({ selectedMedia });
    };

    fetchLibraryMedia() {
        fetchYouzign(
            this.props.match.params.domid,
            this.state.paging.page,
            this.state.paging.pageSize,
        ).then((res) => {
            if (res.data.success === 1) {
                this.setState({
                    loading: false,
                    media: [...this.state.media, ...res.data.media],
                    connected: res.data.connected,
                    next: res.data.next,
                    selectedMedia: [],
                })
            } else {
                this.setState({
                    error: true,
                    errorMsg: res.data.msg,
                    loading: false
                })
            }
        }).catch((e) => {
            this.setState({
                error: true,
                errorMsg: e.message,
                loading: false
            })
        });
    }

    componentDidMount() {
        this.fetchLibraryMedia();
    }

    importSelectedMedia(ids) {
        bulkFetch(this.props.match.params.domid, ids)
            .then((res) => {
                if (res.data.success === 1) {
                    this.setState({
                        selectedMedia: []
                    })
                    message.success("Image(s) added to library successfully!");
                } else {
                    message.error(res.data.msg);
                }
            }).catch(e => {
                message.error(e.message);
            })
    }

    onSelect(id) {
        const checked = this.state.selectedMedia.findIndex((m) => m === id) > -1 ? false : true;
        if (checked) {
            this.setState({
                selectedMedia: [...this.state.selectedMedia, id]
            })
        } else {
            this.setState({
                selectedMedia: this.state.selectedMedia.filter((m) => m !== id)
            })
        }
    }

    goToNextPage() {
        this.setState({
            loading: true,
            paging: {
                ...this.state.paging,
                page: this.state.paging.page + 1
            }
        }, () => this.fetchLibraryMedia());
    }

    integrateYouzign() {
        let errors = {};
        if (this.state.publicKey.trim().length === 0) {
            errors.publicKey = 'This is a required field!';
        }
        if (this.state.token.trim().length === 0) {
            errors.token = 'This is a required field!';
        }
        if (Object.keys(errors).length) {
            this.setState({ errors })
        } else {
            saveYouzignKeys(this.props.match.params.domid, this.state.publicKey, this.state.token).then(res => {
                if (res.data.success === 1) {
                    this.setState({
                        connected: 1
                    }, () => this.fetchLibraryMedia())
                } else {
                    message.error(res.data.msg);
                }
            }).catch(e => { message.error(e.message) });
        }
    }

    disconnectYouzign() {
        confirm({
            title: 'Are you sure?',
            content: 'Are you sure you want to disconnect your Youzign account?',
            onOk: () => {
                removeYouzign(this.props.match.params.domid).then(res => {
                    if (res.data.success === 1) {
                        this.setState({
                            connected: 0,
                            media: [],
                        }, () => this.fetchLibraryMedia())
                    } else {
                        message.error(res.data.msg);
                    }
                }).catch(e => { message.error(e.message) })
            },
            onCancel: () => { },
        })
    }

    render() {
        const PageWrapper = (children) => (
            <div className="library">
                <MainHeadingBar title="Youzign Library" helpText={`Here you can manage your YouZign Library herr .`} {...this.props} />
                <Card style={{ marginTop: "10px" }}>
                    <Row style={{ marginBottom: "2.5rem" }}>
                        <Col xs={24}>
                            {this.state.selectedMedia.length > 0 &&
                                <Button
                                    style={{ background: "#F84B3D", color: "white", marginLeft: "1rem" }}
                                    onClick={() => this.importSelectedMedia(this.state.selectedMedia)}>Import Selected</Button>}
                            <Button
                                style={{ background: "#F84B3D", color: "white", marginLeft: "1rem" }}
                                onClick={() => this.disconnectYouzign()}>Disconnect Youzign</Button>
                        </Col>
                    </Row>
                    {children}
                </Card>
            </div>
        );

        if (this.props.auth && this.props.auth.plan && this.props.auth.plan.plan_features && this.props.auth.plan.plan_features.YOUZIGN !== 1) {
            return <Card style={{ marginTop: 30, textAlign: 'center' }}>
                <Icon type="exclamation-circle" style={{ fontSize: 40, marginBottom: 10 }} />
                <h1>Your plan does not allow you to integrate with Youzign</h1>
            </Card>;
        }

        if (this.state.loading) {
            return <Card style={{ marginTop: 30 }}><Skeleton active /></Card>;
        }

        if (!this.state.loading && this.state.error) {
            return PageWrapper(
                <Alert
                    message="Error"
                    description={this.state.errorMsg}
                    type="error"
                    showIcon
                />
            )
        }

        if (!this.state.loading && this.state.connected !== 1) {
            return <Card style={{ marginTop: "30px" }}>
                <Row>
                    <Col xs={6}><img src={youzignLogo} style={{ maxWidth: "100%" }} alt="Youzign" /></Col>
                    <Col xs={18} style={{ textAlign: "center" }}>
                        <p>YouZign is a suite of all the design tools which is used to create graphics for ads, social media covers, banners, videos, Kindle covers, eBook covers, blog graphics, business cards, flyers, coupons, memes and more in minutes.	</p>
                        <p><a href="https://youzign.com/" target="_blank" rel="noopener noreferrer">Know more about YouZign here.</a></p>
                        <p style={{ color: this.state.connected === 1 ? 'green' : 'red' }}>{this.state.connected === 1 ? "Connected" : "Not Connected"}</p>

                        <div style={{ textAlign: 'left', maxWidth: 500, margin: '0 auto' }}>
                            <div className={`ant-form-item-control ${this.state.errors.publicKey ? 'has-error' : ''}`}>
                                <div className="ant-form-item-label"><label className="ant-form-item-required">Public Key</label></div>
                                <Input
                                    value={this.state.publicKey}
                                    onChange={(e) => { this.setState({ "publicKey": e.target.value }) }} />
                                {this.state.errors.publicKey && <div className="ant-form-explain">{this.state.errors.publicKey}</div>}
                            </div>
                            <div className={`ant-form-item-control ${this.state.errors.token ? 'has-error' : ''}`}>
                                <div className="ant-form-item-label"><label className="ant-form-item-required">Token</label></div>
                                <Input
                                    value={this.state.token}
                                    onChange={(e) => { this.setState({ "token": e.target.value }) }} />
                                {this.state.errors.token && <div className="ant-form-explain">{this.state.errors.token}</div>}
                            </div>
                            <div style={{ marginTop: 15, textAlign: 'center' }}>
                                <Button onClick={() => this.integrateYouzign()} type="primary" size="large">Connect</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card>
        }

        if (!this.state.loading && this.state.media.length === 0) {
            return PageWrapper(
                <Row style={{ textAlign: "center" }}>
                    <NoDataIcon />
                    <p style={{ margin: 0 }}>No Images Found!</p>
                </Row>
            );
        }

        return PageWrapper(
            <React.Fragment>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    {this.state.media.map(media => (
                        <Col xs={24} sm={12} md={6} key={media.previewImg}>
                            <Card
                                style={{ margin: "0 auto 2.9rem auto", cursor: "pointer" }}
                                cover={<div style={{
                                    background: `url(${media.previewImg})`,
                                    height: "100%",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }} />}
                                onClick={() => this.onSelect(media.previewImg)}
                            >
                                {this.state.selectedMedia.indexOf(media.previewImg) !== -1 && <div className="selected-overlay"></div>}
                                <Meta
                                    title={
                                        <div className="media-meta">
                                            <Checkbox size="large" checked={this.state.selectedMedia.indexOf(media.previewImg) !== -1}></Checkbox>
                                        </div>
                                    }
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>
                {this.state.media.length > 0 &&
                    <Row style={{ textAlign: 'center' }}>
                        <Button type="primary" onClick={() => this.goToNextPage()}>Load More {this.state.loading && <Icon type="loading" />}</Button>
                    </Row>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}
export default connect(mapStateToProps)(Youzign);