import React, { Component } from "react";
import { message } from "antd";

class ElementPreview extends Component {
  state = {
    intervalId: undefined
  };
  setPreview() {
    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    document.open();
    document.close();
    document.head.innerHTML = `<style>body {display: flex; align-items: center; pointer-events: ${
      this.props.element.element_type === "twitter_timeline" ? "auto" : "none"
    }; justify-content: center}</style>`;
    document.body.innerHTML = this.props.element.previewString;
    if (!this.state.intervalId) {
      this.setState({
        intervalId: setInterval(() => {
          if (
            Math.abs(
              iframe.contentDocument.body.getBoundingClientRect().height -
                iframe.getBoundingClientRect().height
            ) > 30
          ) {
            iframe.style.height =
              iframe.contentDocument.body.getBoundingClientRect().height +
              30 +
              "px";
          }
        }, 1000)
      });
    }
  }

  componentDidUpdate() {
    this.setPreview();
  }

  componentDidMount() {
    this.setPreview();
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  copyElementPreview() {
    var doc = this.refs.iframe.contentDocument,
      window = this.refs.iframe.contentWindow,
      text = doc.querySelector("body"),
      range,
      selection;
    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    doc.execCommand("copy");
    message.success("Copied Successfully!");
  }

  render() {
    return (
      <iframe title="Element Preview" ref="iframe" className="notranslate" />
    );
  }
}

export default ElementPreview;
