import React, { Component } from 'react';
import { Input, Select, DatePicker, Row, Button, message, Radio, Col } from 'antd';
import { set, merge } from 'lodash';
import moment from 'moment';
import ColorPicker from '../ColorPicker';
import timer1Image from '../../../../../assets/images/timers/timer1.png';
import timer2Image from '../../../../../assets/images/timers/timer2.png';
import timer3Image from '../../../../../assets/images/timers/timer3.png';
import timer4Image from '../../../../../assets/images/timers/timer4.png';
const { Option } = Select;

class TimerElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'timers',
      element_group: 'conversion_elements',
      element_domid: this.props.match.params.domid,
      data: {
        template: "template1",
        expiry: '',
        expirytz: "",
        //expiryimg: "exp1",
        labels: {
          days: "Days",
          hours: "Hours",
          minutes: "Minutes",
          seconds: "Seconds",
        },
        text: {
          "r": "255",
          "g": "255",
          "b": "255"
        },
        bg: {
          "r": "0",
          "g": "0",
          "b": "0"
        },
        redirect_urls: {
          url: "",
          expiry_url: "",
        },
        expirytext: 'Timer Has Expired',
        expirycolor: {
          "r": "255",
          "g": "255",
          "b": "255"
        },
        expirybg: {
          "r": "0",
          "g": "0",
          "b": "0"
        },
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.template.trim().length === 0) {
      errors.template = "This is a required field!";
    }
    if (element.data.template !== "template3") {
      if (element.data.labels.days.trim().length === 0) {
        errors.label_days = "This is a required field!";
      }
      if (element.data.labels.hours.trim().length === 0) {
        errors.label_hours = "This is a required field!";
      }
      if (element.data.labels.minutes.trim().length === 0) {
        errors.label_minutes = "This is a required field!";
      }
      if (element.data.labels.seconds.trim().length === 0) {
        errors.label_seconds = "This is a required field!";
      }
    }
    if (element.data.expiry.trim().length === 0) {
      errors.expiry = "This is a required field!";
    }
    if (element.data.expirytz.trim().length === 0) {
      errors.expirytz = "This is a required field!";
    }
    if (element.data.expirytext.trim().length === 0) {
      errors.expirytext = "This is a required field!";
    }
    if (!element.data.redirect_urls.url.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.url = "Please enter a valid URL!";
    }
    if (!element.data.redirect_urls.expiry_url.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.expiry_url = "Please enter a valid URL!";
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
  this.setState({
    saving: false
  })
  if (res.data.success !== 1) {
    message.error(res.data.msg);
  }
})
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.template ? 'has-error' : ''}`}>
          <p className="form-labels">Choose Template</p>
          <Radio.Group onChange={(e) => this.updateField("data.template", e.target.value)} value={this.state.element.data.template}>
            <Radio.Button value="template1"><p><img src={timer1Image} style={{ maxWidth: 150 }} alt="Timer 1" /> <br /> Template 1</p></Radio.Button>
            <Radio.Button value="template2"><p><img src={timer2Image} style={{ maxWidth: 150 }} alt="Timer 2" /> <br /> Template 2</p></Radio.Button>
            <Radio.Button value="template3"><p><img src={timer3Image} style={{ maxWidth: 150 }} alt="Timer 3" /> <br /> Template 3</p></Radio.Button>
            <Radio.Button value="template4"><p><img src={timer4Image} style={{ maxWidth: 150 }} alt="Timer 4" /> <br /> Template 4</p></Radio.Button>
          </Radio.Group>
          {this.state.errors.template && <div className="ant-form-explain">{this.state.errors.template}</div>}
        </div>

        {this.state.element.data.template !== "template3" && <>
          <div className={`ant-form-item-control ${this.state.errors.label_days ? 'has-error' : ''}`}>
            <p className="form-labels">Label for Days Field
            <Input
                value={this.state.element.data.labels.days}
                onChange={(e) => { this.updateField("data.labels.days", e.target.value) }} />
            </p>
            {this.state.errors.label_days && <div className="ant-form-explain">{this.state.errors.label_days}</div>}
          </div>

          <div className={`ant-form-item-control ${this.state.errors.label_hours ? 'has-error' : ''}`}>
            <p className="form-labels">Label for Hours Field
            <Input
                value={this.state.element.data.labels.hours}
                onChange={(e) => { this.updateField("data.labels.hours", e.target.value) }} />
            </p>
            {this.state.errors.label_hours && <div className="ant-form-explain">{this.state.errors.label_hours}</div>}
          </div>

          <div className={`ant-form-item-control ${this.state.errors.label_minutes ? 'has-error' : ''}`}>
            <p className="form-labels">Label for Minutes Field
            <Input
                value={this.state.element.data.labels.minutes}
                onChange={(e) => { this.updateField("data.labels.minutes", e.target.value) }} />
            </p>
            {this.state.errors.label_minutes && <div className="ant-form-explain">{this.state.errors.label_minutes}</div>}
          </div>

          <div className={`ant-form-item-control ${this.state.errors.label_seconds ? 'has-error' : ''}`}>
            <p className="form-labels">Label for Seconds Field
            <Input
                value={this.state.element.data.labels.seconds}
                onChange={(e) => { this.updateField("data.labels.seconds", e.target.value) }} />
            </p>
            {this.state.errors.label_seconds && <div className="ant-form-explain">{this.state.errors.label_seconds}</div>}
          </div>
        </>}

        <Row>
          <Col xs={12}><ColorPicker format="rgb" hideInput label="Timer Text Color" defaultValue={this.state.element.data.text} onChange={(color) => this.updateField("data.text", color)} /></Col>
          <Col xs={12}><ColorPicker format="rgb" hideInput label="Timer Background Color" defaultValue={this.state.element.data.bg} onChange={(color) => this.updateField("data.bg", color)} /></Col>
        </Row>

        <div className={`ant-form-item-control ${this.state.errors.expiry ? 'has-error' : ''}`}>
          <p className="form-labels">Expiry</p>
          <DatePicker
            disabledDate={(current) => current && current < moment().subtract(1, "days").endOf('day')}
            showTime
            inputReadOnly
            style={{ width: "100%" }}
            format="YYYY-MM-DD HH:mm:ss"
            value={this.state.element.data.expiry ? moment(this.state.element.data.expiry) : null}
            onChange={(date) => { this.updateField("data.expiry", date != null ? date.format("YYYY-MM-DD HH:mm:ss") : '') }}
          />
          {this.state.errors.expiry && <div className="ant-form-explain">{this.state.errors.expiry}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.expirytz ? 'has-error' : ''}`}>
          <p className="form-labels">Timezone</p>
          <Select
            value={this.state.element.data.expirytz ? this.state.element.data.expirytz : []}
            onChange={(val) => { this.updateField("data.expirytz", val) }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {Object.keys(this.props.timezones).map((timezone, i) => <Option value={`${timezone}|${this.props.timezones[timezone]}`} key={i}>{timezone}</Option>)}
          </Select>
          {this.state.errors.expirytz && <div className="ant-form-explain">{this.state.errors.expirytz}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.expirytext ? 'has-error' : ''}`}>
          <p className="form-labels">Post Expiry Text
          <Input
              value={this.state.element.data.expirytext}
              onChange={(e) => { this.updateField("data.expirytext", e.target.value) }} />
          </p>
          {this.state.errors.expirytext && <div className="ant-form-explain">{this.state.errors.expirytext}</div>}
        </div>

        <Row>
          <Col xs={12}><ColorPicker format="rgb" hideInput label="Post Expiry Text Color" defaultValue={this.state.element.data.expirycolor} onChange={(color) => this.updateField("data.expirycolor", color)} /></Col>
          <Col xs={12}><ColorPicker format="rgb" hideInput label="Post Expiry Background Color" defaultValue={this.state.element.data.expirybg} onChange={(color) => this.updateField("data.expirybg", color)} /></Col>
        </Row>

        <div className={`ant-form-item-control ${this.state.errors.url ? 'has-error' : ''}`}>
          <p className="form-labels">Timer Redirect URL
            <Input
              value={this.state.element.data.redirect_urls.url}
              onChange={(e) => { this.updateField("data.redirect_urls.url", e.target.value) }}
              onBlur={(e) => { this.updateField("data.redirect_urls.url", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
          </p>
          {this.state.errors.url && <div className="ant-form-explain">{this.state.errors.url}</div>}
        </div>
        <div className={`ant-form-item-control ${this.state.errors.expiry_url ? 'has-error' : ''}`}>
          <p className="form-labels">Timer Post Expiry Redirect URL
            <Input
              value={this.state.element.data.redirect_urls.expiry_url}
              onChange={(e) => { this.updateField("data.redirect_urls.expiry_url", e.target.value) }}
              onBlur={(e) => { this.updateField("data.redirect_urls.expiry_url", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
          </p>
          {this.state.errors.expiry_url && <div className="ant-form-explain">{this.state.errors.expiry_url}</div>}
        </div>

        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button>
        </Row>
      </form >
    )
  }

}

export default TimerElement;