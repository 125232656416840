import React, { Component } from 'react';
import MainHeadingBar from '../Partials/MainHeadingBar';
import { Row, Skeleton, Alert, Button, Icon, Card, Input, Select, Tooltip, message, Modal } from 'antd';
import LibraryModal from '../Library/LibraryModal';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ClickToCopy from '../Partials/ClickToCopy'
import { newLink, saveLink } from '../../../helpers/ajax/linksAjax';
import { CDN_URL } from '../../../config';
const { Option } = Select;

class LinkCloaker extends Component {
    state = {
        loading: true,
        error: false,
        errorMsg: '',
        wlbldoms: [],
        link: {
            "link_url": "",
            "link_openas": "redirect",
            "link_sbdom": "",
            "link_slug": "",
            "link_domtype": "letmailbox",
            "link_domid": this.props.match.params.domid,
            "link_pixel": "",
            "link_domain": "letmailbox.me",
            "link_wlbldom": "",
            "link_metatitle": "",
            "link_metadescription": "",
            "link_metaimage": "",
        },
        libraryModal: {
            visible: false,
            type: 'link',
            tab: 'upload',
            onChoose: (media) => { console.log(media) }
        },
        successModal: {
            visible: false,
            link: ''
        },
        errors: {}
    };

    updateField(k, v) {
        this.setState({
            link: {
                ...this.state.link,
                [k]: v
            }
        })
    }

    fetch = () => {
        this.setState({
            loading: true
        }, () => {
            newLink(this.props.auth.domain.id).then(res => {
                this.setState({
                    loading: false,
                    wlbldoms: res.data.domains.filter((w) => w.domain_status === "Pointed")
                });
            });
        })
    };

    componentDidMount = () => {
        this.fetch();
    };

    toggleLibraryModal(type, onChoose = (media) => { console.log(media) }, tab = 'upload') {
        this.setState({
            libraryModal: {
                type: type ? type : 'image',
                visible: !this.state.libraryModal.visible,
                tab,
                onChoose,
            }
        });
    }

    onChoose(media) {
        this.setState({
            link: {
                ...this.state.link,
                link_metaimage: `${media.media_filename}`
            }
        })
        this.toggleLibraryModal();
    }

    saveLink() {
        const link = this.state.link;
        let errors = {};
        if (!link.link_url.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
            errors.link_url = "This is a required field!";
        }
        if (link.link_openas.trim().length === 0) {
            errors.link_openas = "This is a required field!";
        }
        if (link.link_domtype.trim().length === 0) {
            errors.link_domtype = "This is a required field!";
        }
        if (link.link_domtype.trim() === "letmailbox") {
            if (link.link_domain.trim().length === 0) {
                errors.link_domain = "This is a required field!";
            }
            if (link.link_sbdom.trim().length === 0) {
                errors.link_sbdom = "Please enter a valid subdomain!";
            }
        } else {
            if (link.link_wlbldom.trim().length === 0) {
                errors.link_wlbldom = "This is a required field!";
            }
        }

        if (Object.keys(errors).length === 0) {
            this.setState({
                errors: {},
                saving: true,
            }, () => {
                saveLink(this.state.link, this.props.match.params.domid).then((res) => {
                    if (res.data.success === 1) {
                        this.setState({
                            saving: false,
                            successModal: {
                                visible: true,
                                link: res.data.url
                            }
                        });
                    } else {
                        message.error(res.data.msg);
                    }
                })
            });
        } else {
            this.setState({ errors })
        }
    }

    postLink(network) {
        const url = this.state.successModal.link;
        const networks = {
            facebook: "https://www.facebook.com/sharer/sharer.php?u=",
            twitter: "https://twitter.com/intent/tweet?text=",
            gplus: "https://plus.google.com/share?url=",
            linkedin: "https://www.linkedin.com/cws/share?url=",
            pinterest: "https://pinterest.com/pin/create/button/?description=&media=&url="
        }
        const networkURL = networks[network];
        window.open(networkURL + encodeURIComponent(url), '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
    }

    render() {
        const { loading, error, errorMsg } = this.state;
        const features = this.props.auth && this.props.auth.plan && this.props.auth.plan.plan_features ? this.props.auth.plan.plan_features : {};

        const PageWrapper = (children) => (
            <div className="campaigns-container">
                <MainHeadingBar title="New Link" helpText={`You can create cloaked links here.`} {...this.props} />
                {children}
            </div>
        );

        if (features['LINK_CLOAKER_ENABLED'] !== 1) {
            return PageWrapper(<Card style={{ marginTop: 30, textAlign: 'center' }}>
                <Icon type="exclamation-circle" style={{ fontSize: 40, marginBottom: 10 }} />
                <h1>Your plan does not allow you to create Cloaked Links</h1>
            </Card>);
        }

        if (loading) {
            return PageWrapper(<Card style={{ marginBottom: "30px" }}>
                <Skeleton active />
            </Card>)
        }

        if (!loading && error) {
            return PageWrapper(<div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={errorMsg}
                        type="error"
                    />
                </Row>
            </div>);
        }

        return PageWrapper(
            <Card className="link-cloaker">
                <LibraryModal
                    {...this.props}
                    tab={this.state.libraryModal.tab}
                    onChoose={this.state.libraryModal.onChoose}
                    uploadType={this.state.libraryModal.type}
                    visible={this.state.libraryModal.visible}
                    onCancel={() => this.toggleLibraryModal()} />
                <div className={`ant-form-item-control ${this.state.errors.link_url ? 'has-error' : ''}`}>
                    <div className="ant-form-item-label"><label className="ant-form-item-required">URL</label></div>
                    <Input
                        value={this.state.link.link_url}
                        onChange={(e) => { this.updateField("link_url", e.target.value) }}
                        onBlur={(e) => { this.updateField("link_url", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
                    {this.state.errors.link_url && <div className="ant-form-explain">{this.state.errors.link_url}</div>}
                </div>

                <div className={`ant-form-item-control ${this.state.errors.openas ? 'has-error' : ''}`}>
                    <div className="ant-form-item-label"><label className="ant-form-item-required">Redirect Type</label></div><br />
                    <Select
                        style={{ width: "100%" }}
                        value={this.state.link.link_openas}
                        onChange={(val) => { this.updateField("link_openas", val) }}
                    >
                        <Option value="redirect">JS Redirect</Option>
                        <Option value="iframe" disabled={features['LINK_CLOAKER'] !== "ADVANCED"}>iFrame</Option>
                        <Option value="redirect301" disabled={features['LINK_CLOAKER'] !== "ADVANCED"}>Server Side Redirect (301 Redirect)</Option>
                    </Select>
                    {this.state.errors.link_openas && <div className="ant-form-explain">{this.state.errors.link_openas}</div>}
                </div>

                <div className={`ant-form-item-control`}>
                    <div className="ant-form-item-label"><label>Meta Title (For Social Shares)</label></div>
                    <Input
                        value={this.state.link.link_metatitle}
                        onChange={(e) => { this.updateField("link_metatitle", e.target.value) }} />
                </div>

                <div className={`ant-form-item-control`}>
                    <div className="ant-form-item-label"><label>Meta Description (For Social Shares)</label></div>
                    <Input.TextArea
                        value={this.state.link.link_metadescription}
                        onChange={(e) => { this.updateField("link_metadescription", e.target.value) }} />
                </div>

                <div className={`ant-form-item-control`}>
                    <div className="ant-form-item-label"><label>Meta Image (For Social Shares)</label></div>
                    <div className="upload-actions">
                        {this.state.link.link_metaimage > 0 &&
                            <div className="upload-action">
                                <img src={`${CDN_URL}images/links/${this.state.link.link_metaimage}`} alt="" style={{ width: '100%', height: '100%' }} />
                            </div>
                        }
                        <div className="upload-action" onClick={() => this.toggleLibraryModal("link", (media) => this.onChoose(media), 'upload')}>
                            <p>
                                <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
                                Upload
                            </p>
                        </div>
                        <div className="upload-action" onClick={() => this.toggleLibraryModal("link", (media) => this.onChoose(media), 'library')}>
                            <p>
                                <Icon type="folder-open" style={{ color: "#D9D9D9" }} /> <br />
                                Library
                            </p>
                        </div>
                    </div>
                </div>

                <div className={`ant-form-item-control ${this.state.errors.link_domtype ? 'has-error' : ''}`}>
                    <div className="ant-form-item-label"><label className="ant-form-item-required">Choose Domain</label></div><br />
                    <Select
                        style={{ width: "100%" }}
                        value={this.state.link.link_domtype}
                        onChange={(val) => { this.updateField("link_domtype", val) }}
                    >
                        <Option value="letmailbox">Keep LetMailbox Domain</Option>
                        <Option value="Whitelabel">Use Whitelabel Domain</Option>
                    </Select>
                    {this.state.errors.link_domtype && <div className="ant-form-explain">{this.state.errors.link_domtype}</div>}
                </div>

                {this.state.link.link_domtype === "letmailbox" && <div className={`ant-form-item-control ${this.state.errors.link_domain ? 'has-error' : ''}`}>
                    <div className="ant-form-item-label"><label className="ant-form-item-required">Choose LetMailbox Domain</label></div><br />
                    <Select
                        style={{ width: "100%" }}
                        value={this.state.link.link_domain}
                        onChange={(val) => { this.updateField("link_domain", val) }}
                    >
                        <Option value="letmailbox.me">letmailbox.me</Option>
                    </Select>
                    {this.state.errors.link_domain && <div className="ant-form-explain">{this.state.errors.link_domain}</div>}
                </div>}

                {this.state.link.link_domtype === "Whitelabel" && <div className={`ant-form-item-control ${this.state.errors.link_wlbldom ? 'has-error' : ''}`}>
                    <div className="ant-form-item-label"><label className="ant-form-item-required">Choose Whitelabel Domain</label></div><br />
                    {this.state.wlbldoms.length > 0 ?
                        <Select
                            style={{ width: "100%" }}
                            value={this.state.link.link_wlbldom}
                            onChange={(val) => { this.updateField("link_wlbldom", val) }}
                        >
                            {this.state.wlbldoms.map((w) => <Option value={w.domain_name} key={w.domain_name}>{w.domain_name}</Option>)}
                        </Select>
                        : <p>You don't have any whitelabel domains. You can add whitelabel domain by going to <Link to={`/${this.props.match.params.domid}/settings/domains`}>settings</Link>.</p>}
                    {this.state.errors.link_wlbldom && <div className="ant-form-explain">{this.state.errors.link_wlbldom}</div>}
                </div>}

                <div className={`ant-form-item-control ${this.state.errors.slug || this.state.errors.link_sbdom ? 'has-error' : ''}`}>
                    <div className="ant-form-item-label"><label className="ant-form-item-required">Custom Link <Tooltip title="Brand-Name will be displayed on the image as a URL. The Slug should be unique"><Icon type="info-circle" /></Tooltip></label></div>
                    <Input.Group compact>
                        {this.state.link.link_domtype === "letmailbox" && <Input
                            style={{ width: "30%" }}
                            addonBefore={<span>{this.state.link.link_openas === "iframe" ? "http://" : "https://"}</span>}
                            value={this.state.link.link_sbdom}
                            onChange={(e) => { this.updateField("link_sbdom", e.target.value) }} />}
                        <Input
                            style={{ width: this.state.link.link_domtype === "letmailbox" ? "70%" : "100%" }}
                            addonBefore={`${this.state.link.link_domtype === "letmailbox" ? '.' + this.state.link.link_domain : (this.state.link.link_openas === "iframe" ? "http://" : "https://") + this.state.link.link_wlbldom + '/'}`}
                            value={this.state.link.link_slug}
                            onChange={(e) => { this.updateField("link_slug", e.target.value) }} />
                    </Input.Group>
                    {this.state.errors.link_sbdom && <div className="ant-form-explain">{this.state.errors.link_sbdom}</div>}
                </div>

                <div className={`ant-form-item-control`}>
                    <div className="ant-form-item-label"><label>Retargeting Pixels</label></div>
                    <Input.TextArea
                        value={this.state.link.link_pixel}
                        onChange={(e) => { this.updateField("link_pixel", e.target.value) }} />
                </div>
                <br /><br />
                <Row>
                    <Button type="primary" size="large" onClick={() => this.saveLink()}>Save</Button>
                </Row>

                <Modal
                    title={null}
                    footer={null}
                    closable={false}
                    visible={this.state.successModal.visible}
                >
                    <div style={{ textAlign: "center" }}>
                        <Icon type="check-circle" theme="filled" style={{ color: "#36C626", fontSize: "100px" }} />
                        <h2 style={{ margin: "30px 0 0 0" }}>Success</h2>
                        <p>Link has been created successfully!</p>
                        <p><ClickToCopy textToCopy={this.state.successModal.link}><Icon type="copy" /> {this.state.successModal.link}</ClickToCopy></p>

                        <Button type="primary" className="btn-green" onClick={() => this.postLink('facebook')}>Post Now On Facebook</Button><br /><br />
                        <Button type="primary" className="btn-green" onClick={() => this.postLink('twitter')}>Post Now On Twitter</Button><br /><br />
                        <Button type="primary" className="btn-green" onClick={() => this.postLink('gplus')}>Post Now On Google Plus</Button><br /><br />
                        <Button type="primary" className="btn-green" onClick={() => this.postLink('linkedin')}>Post Now On LinkedIn</Button><br /><br />
                        <Button type="primary" className="btn-green" onClick={() => this.postLink('pinterest')}>Post Now On Pinterest</Button><br /><br />
                        <Link to={`/${this.props.match.params.domid}/my_links`} className="ant-btn ant-btn-lg ant-btn-primary">Okay</Link>
                    </div>
                </Modal>

            </Card>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
};

export default connect(mapStateToProps)(LinkCloaker);