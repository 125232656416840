import React from "react";
import './index.scss';
import { Tabs } from "antd";
import Pieslice from "../../Partials/Pieslice";

const BrowserWiseStats = (props) => {
    const { browser_wise_clicks } = props;

    return (
        <Tabs defaultActiveKey="2" className="browserwise-stats-tabs">
            <Tabs.TabPane tab="Clicks" key="2">
                <Pieslice data={browser_wise_clicks} category='browser' type='clicks' />
            </Tabs.TabPane>
        </Tabs>
    );
}

export default BrowserWiseStats;