import React, { Component } from "react";
import "./index.scss";
import {
  Button,
  Divider,
  Row,
  Col,
  Input,
  Icon,
  Skeleton,
  Alert,
  Select,
  Switch,
  message,
  Tooltip
} from "antd";
import { connect } from "react-redux";
import {
  getDomDetails,
  saveDomDetails
} from "../../../../helpers/ajax/settingsAjax";

const { Option } = Select;

class AccountSettings extends Component {
  state = {
    error: false,
    errorMsg: "",
    loading: false,
    saving: false,
    dom_name: "",
    dom_timezone: "",
    all_timezones: {},
    branding_on: 0,
    branding_url: "",
    custom_apidom: 0,
    dom_settings: {},
    custom_thankyou: ""
  };

  changeState = (field, value) => {
    let disableBrandingAllowed = false;
    if (
      this.props.auth.plan.plan_features.DISABLE_BRANDING === 1 ||
      this.props.auth.plan.plan_features.DISABLE_BRANDING === "1"
    ) {
      disableBrandingAllowed = true;
    }
    if (field === "branding_on" && !disableBrandingAllowed) {
      message.error("Your plan does not allow you to disable branding!");
      return;
    }
    this.setState({
      [field]: value
    });
  };

  componentDidMount = () => {
    const { domain } = this.props.auth;

    getDomDetails(domain.id).then(res => {
      this.setState({
        ...this.state,
        ...res
      });
    });
  };

  validateDomainNames = (value, field, array, index) => {
    if (
      !value.match(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/g
      )
    ) {
      array[index] = true;
    } else {
      array[index] = false;
    }
    this.changeState(field, [...array]);
  };

  saveSettings = () => {
    const {
      dom_settings,
      dom_timezone,
      branding_on,
      branding_url,
      custom_apidom,
      custom_thankyou
    } = this.state;
    dom_settings.branding.on = branding_on;
    dom_settings.branding.url = branding_url;
    dom_settings.custom_apidom = custom_apidom;
    dom_settings.dom_timezone = dom_timezone;
    dom_settings.custom_thankyou = custom_thankyou;
    this.setState(
      {
        saving: true
      },
      () => {
        saveDomDetails(this.props.auth.domain.id, dom_settings).then(res => {
          this.setState({ saving: false });
          if (!res.error) {
            message.success("Settings updated!");
            getDomDetails(this.props.auth.domain.id).then(res => {
              this.setState({
                ...this.state,
                ...res
              });
            });
          } else {
            message.error(res.errorMsg);
          }
        });
      }
    );
  };

  render() {
    const {
      error,
      errorMsg,
      loading,
      dom_name,
      dom_timezone,
      all_timezones,
      branding_on,
      custom_apidom
    } = this.state;
    if (loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!loading && error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    return (
      <div className="account-settings">
        <h3 className="heading">
          <span className="text">
            Account Settings{" "}
            <Tooltip title="Here you can manage the settings of your account ">
              <Icon type="info-circle" style={{ fontSize: "1.5rem" }} />
            </Tooltip>
          </span>
          <Button
            type="default"
            onClick={this.saveSettings}
            loading={this.state.saving}
          >
            Save Settings
          </Button>
        </h3>

        <Divider />

        <Row gutter={24}>
          <Col xs={24} md={12} className="col-1">
            <div className="input-container">
              <p className="label">Website Name</p>
              <Input disabled={true} value={dom_name} />
            </div>

            <div className="input-container">
              <p className="label">Default Timezone</p>
              <Select
                value={dom_timezone}
                style={{ width: "100%" }}
                onChange={value => this.changeState("dom_timezone", value)}
              >
                {Object.keys(all_timezones).map((timezone, i) => (
                  <Option
                    value={`${timezone}|${all_timezones[timezone]}`}
                    key={i}
                  >
                    {timezone}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="input-container">
              <p className="label">LetMailbox Branding</p>
              <p className="switch-text">
                Enabled
                <Switch
                  checked={branding_on === 1}
                  size="small"
                  onChange={checked =>
                    this.changeState("branding_on", Number(checked))
                  }
                />
              </p>
            </div>

            {branding_on === 1 &&
              Number(this.props.auth.plan.plan_features.CUSTOM_BRANDING) ===
                1 && (
                <div className="input-container">
                  <p className="label">Custom Branding URL (Optional)</p>
                  <Input
                    value={this.state.branding_url}
                    onChange={e =>
                      this.changeState("branding_url", e.target.value)
                    }
                  />
                </div>
              )}

            <div className="input-container">
              <p className="label">Custom API Domain</p>
              <p className="switch-text">
                Enabled
                <Switch
                  checked={custom_apidom === 1}
                  size="small"
                  onChange={checked =>
                    this.changeState("custom_apidom", Number(checked))
                  }
                />
              </p>
            </div>

            <div className="input-container">
              <p className="label">Custom Thank You Message</p>
              <Input
                value={this.state.custom_thankyou}
                onChange={e =>
                  this.changeState("custom_thankyou", e.target.value)
                }
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};

export default connect(mapStateToProps)(AccountSettings);
