import React, { Component } from 'react';
import { Card, Table } from 'antd';
import moment from 'moment';
import { getRecords } from '../../../../helpers/ajax/elementsAjax';

class Records extends Component {
  state = {
    records: []
  }

  componentDidMount() {
    getRecords(this.props.match.params.domid, this.props.element._id["$id"], this.props.element.element_type).then(res => {
      this.setState({ records: res.data })
    })
  }

  getLabel(type, value) {
    if (type === "emoji") {
      return <img alt="" style={{ width: 30 }} src={`https://cdn.letmailbox.com/images/global/emojis/${value}.png`} />;
    } else if (type === "star") {
      let template = [];
      for (let i = 0; i < value; i++) {
        template.push(<img alt="" style={{ width: 30 }} src={`https://cdn.letmailbox.com/images/global/icons/stars/star${i + 1}.png`} />);
      }
      return template;
    } else if (type === "rating") {
      return value;
    } else if (type === "thumbs") {
      if (value === -1) {
        return <img alt="" style={{ width: 30 }} src="https://cdn.letmailbox.com/images/global/icons/thumbs/up.jpg" />;
      } else {
        return <img alt="" style={{ width: 30 }} src="https://cdn.letmailbox.com/images/global/icons/thumbs/down.jpg" />;
      }
    }
  }

  getLabelGEP(fields, value) {
    if (value === -1) {
      return 'I can\'t make it!';
    } else {
      const option = fields[parseInt(value)];
      return `${option.date ? moment(option.date).format("D MMM Y") : ''} ${option.starttime ? (', ' + option.starttime) : ''} ${option.endtime ? (' to ' + option.endtime) : ''}`;
    }
  }

  getLabelPolls(answers, index) {
    if (answers[parseInt(index)]) {
      return answers[parseInt(index)].answer;
    } else {
      return '-'
    }
  }

  render() {
    const element = this.props.element;
    const recordElements = ["yes_no_bar", "feedback", "polls", "group_event_poll"];
    if (recordElements.indexOf(element.element_type) !== -1) {
      let columns = [];
      if (element.element_type === "yes_no_bar" || element.element_type === "polls" || element.element_type === "feedback") {
        columns = [
          {
            title: 'Response',
            dataIndex: 'response',
            render: (text) => element.element_type === "feedback" ? this.getLabel(element.data['type'], text) : (element.element_type === "polls") ? this.getLabelPolls(element.data.answers, text) : text
          },
          {
            title: 'Count',
            dataIndex: 'count'
          },
        ]
      } else if (element.element_type === "group_event_poll") {
        columns = [
          {
            title: 'Response',
            dataIndex: '_id',
            render: (text) => this.getLabelGEP(element.data.options, text)
          },
          {
            title: 'Count',
            dataIndex: 'count'
          },
        ]
      } else if (element.element_type === "qna") {
        element.data.fields.forEach((f) => {
          columns.push({
            title: f.name,
            dataIndex: `data.${f.key}`
          })
        })
      }
      return (
        <Card style={{ padding: "13px 30px" }}>
          <h1>Response Statistics</h1>
          <Table
            key={_ => Date.now()}
            columns={columns}
            pagination={false}
            dataSource={this.state.records} />
        </Card>
      )
    } else {
      return <div style={{ height: 0 }}></div>
    }
  }
}

export default Records;