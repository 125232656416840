import React, { Component } from 'react';
import { Card, Table, Row, Col } from 'antd';
import moment from 'moment';
import SearchField from '../../Partials/SearchField';
import { getEmailRecords } from '../../../../helpers/ajax/elementsAjax';

class RecordsEmail extends Component {
  state = {
    records: [],
    search: '',
    paging: {
      current: 1,
      total: 0,
      pageSize: 10
    }
  }

  fetch() {
    getEmailRecords(this.props.match.params.domid, this.props.element._id["$id"], this.state.search, this.state.paging.pageSize, (this.state.paging.current - 1) * this.state.paging.pageSize).then(res => {
      this.setState({
        records: res.data.records,
        paging: {
          ...this.state.paging,
          total: res.data.total
        }
      })
    })
  }
  componentDidMount() {
    this.fetch();
  }

  getLabel(type, value) {
    if (type === "emoji") {
      return <img alt="" style={{ width: 30 }} src={`https://cdn.letmailbox.com/images/global/emojis/${value}.png`} />;
    } else if (type === "star") {
      let template = [];
      for (let i = 0; i < value; i++) {
        template.push(<img alt="" style={{ width: 30 }} src={`https://cdn.letmailbox.com/images/global/icons/stars/star${i + 1}.png`} />);
      }
      return template;
    } else if (type === "rating") {
      return value;
    } else if (type === "thumbs") {
      if (value === -1) {
        return <img alt="" style={{ width: 30 }} src="https://cdn.letmailbox.com/images/global/icons/thumbs/up.jpg" />;
      } else {
        return <img alt="" style={{ width: 30 }} src="https://cdn.letmailbox.com/images/global/icons/thumbs/down.jpg" />;
      }
    }
  }

  getLabelGEP(fields, value) {
    if (value.novote !== undefined) {
      return 'I can\'t make it!';
    } else {
      let ret = [];
      value.votes.forEach((index) => {
        const option = fields[parseInt(index)];
        ret.push(<p>{`${option.date ? moment(option.date).format("D MMM Y") : ''} ${option.starttime ? (', ' + option.starttime) : ''} ${option.endtime ? (' to ' + option.endtime) : ''}`}</p>);
      })
      return ret;
    }
  }

  getLabelPolls(answers, index) {
    if (answers[parseInt(index)]) {
      return answers[parseInt(index)].answer;
    } else {
      return '-'
    }
  }

  goToPage(page) {
    this.setState({
      paging: {
        ...this.state.paging,
        current: page
      }
    }, () => this.fetch())
  }

  setFilter(val) {
    this.setState({ search: val }, () => {
      this.fetch();
    })
  }

  render() {
    const element = this.props.element;
    const recordElements = ["qna", "yes_no_bar", "feedback", "polls", "group_event_poll"];
    if (recordElements.indexOf(element.element_type) !== -1) {
      let columns = [];
      if (element.element_type === "yes_no_bar" || element.element_type === "polls" || element.element_type === "feedback") {
        columns = [
          {
            title: "Email",
            dataIndex: 'email'
          },
          {
            title: 'Response',
            dataIndex: 'data',
            render: (text) => element.element_type === "feedback" ? this.getLabel(element.data['type'], text) : (element.element_type === "polls") ? this.getLabelPolls(element.data.answers, text) : text
          },
        ]
      } else if (element.element_type === "group_event_poll") {
        columns = [
          {
            title: "Email",
            dataIndex: 'email'
          },
          {
            title: 'Response',
            dataIndex: 'data',
            render: (text) => this.getLabelGEP(element.data.options, text)
          },
        ]
      } else if (element.element_type === "qna") {
        columns = [{
          title: "Email",
          dataIndex: 'email'
        }];
        element.data.fields.forEach((f) => {
          columns.push({
            title: f.name,
            dataIndex: `data.${f.key}`
          })
        })
      }
      return (
        <Card style={{ padding: "13px 30px" }}>
          <Row>
            <Col xs={12}><h1>Records</h1></Col>
            <Col xs={12}><SearchField placeholder="Search for emails" value={this.state.search} onChange={(val) => { this.setState({ search: val }) }} onSearch={(val) => this.setFilter(val)} /></Col>
          </Row>
          <Table
            key={r => r._id["$id"]}
            columns={columns}
            onChange={(page) => this.goToPage(page.current)}
            pagination={this.state.paging}
            dataSource={this.state.records} />
        </Card>
      )
    } else {
      return <div style={{ height: 0 }}></div>
    }
  }
}

export default RecordsEmail;