import React, { Component } from "react";
import { Input, Select, Row, Button, message } from "antd";
import FormattingField from "../../../Partials/FormattingField";
import { set, merge } from "lodash";
const { Option } = Select;

class ScarcityBarElement extends Component {
  state = {
    saving: false,
    element: {
      element_name: "",
      element_category: "",
      element_type: "scarcity_bar",
      element_group: "conversion_elements",
      element_domid: this.props.match.params.domid,
      data: {
        title: "",
        percentage: 50,
      },
    },
    scraping: false,
    errors: {},
  };

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element),
      });
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.title.trim().length === 0) {
      errors.title = "This is a required field!";
    }
    if (element.data.percentage < 1 || element.data.percentage > 100) {
      errors.title = "Please enter a valid percentage!";
    }

    if (Object.keys(errors).length === 0) {
      this.setState(
        {
          errors: {},
          saving: true,
        },
        () => {
          if (this.props.saveElement) {
            this.props.saveElement(this.state.element, (res) => {
              this.setState({
                saving: false,
              });
              if (res.data.success !== 1) {
                message.error(res.data.msg);
              }
            });
          }
        }
      );
    } else {
      this.setState({ errors });
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach((pv) => (c = set(c, pv.path, pv.value)));
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState(
      {
        element: c,
      },
      () => this.props.onUpdate(this.state.element)
    );
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div
          className={`ant-form-item-control ${
            this.state.errors.element_name ? "has-error" : ""
          }`}
        >
          <p className="form-labels">
            Name
            <Input
              value={this.state.element.element_name}
              onChange={(e) => {
                this.updateField("element_name", e.target.value);
              }}
            />
          </p>
          {this.state.errors.element_name && (
            <div className="ant-form-explain">
              {this.state.errors.element_name}
            </div>
          )}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={
              this.state.element.element_category
                ? this.state.element.element_category
                : []
            }
            onChange={(val) => {
              this.updateField("element_category", val[val.length - 1]);
            }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.props.categories.map((c) => (
              <Option key={c._id} value={c._id}>
                {c._id}
              </Option>
            ))}
          </Select>
        </div>

        <FormattingField
          label="Title"
          error={this.state.errors.title}
          field={(props) => <Input placeholder="" {...props} />}
          showFormatting
          value={this.state.element.data.title}
          onChange={(val) => this.updateField("data.title", val)}
        />

        <div
          className={`ant-form-item-control ${
            this.state.errors.percentage ? "has-error" : ""
          }`}
        >
          <p className="form-labels">
            Percentage
            <Input
              value={this.state.element.data.percentage}
              onChange={(e) => {
                this.updateField(
                  "data.percentage",
                  Number(e.target.value.replace(/\D/g, ""))
                );
              }}
            />
          </p>
          {this.state.errors.percentage && (
            <div className="ant-form-explain">
              {this.state.errors.percentage}
            </div>
          )}
        </div>

        <Row>
          <Button
            className="btn-green"
            size="large"
            onClick={() => this.saveElement()}
            loading={this.state.saving}
          >
            Save
          </Button>
          <Button
            type="danger"
            onClick={() => this.props.onCancel()}
            size="large"
          >
            Cancel
          </Button>{" "}
          &nbsp;
        </Row>
      </form>
    );
  }
}

export default ScarcityBarElement;
