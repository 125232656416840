import React, { Component } from "react";
import "./index.scss";
import {
  Card,
  Table,
  Button,
  Icon,
  Row,
  Col,
  InputNumber,
  message,
  Modal,
  Tooltip
} from "antd";
import { ReactComponent as EditIcon } from "../../../assets/images/icons/editReview.svg";
import { ReactComponent as DeleteReviewIcon } from "../../../assets/images/icons/delete.svg";
import MainHeadingBar from "../Partials/MainHeadingBar";
import {
  getAccounts,
  setViews,
  deleteAccount,
  toggleAccountStatus
} from "../../../helpers/ajax/accountAjax";

class ManageAccountsPG extends Component {
  state = {
    accounts: [],
    impressions: {},
    wlblCounts: { allocated: 0, used: 0 },
    loading: true,
    error: false,
    errorMsg: "",
    editViewsModal: {
      id: undefined,
      visible: false,
      accountName: "",
      value: 0
    }
  };

  fetchAccounts() {
    getAccounts().then(res => this.setState(res));
  }

  componentDidMount() {
    this.fetchAccounts();
  }

  confirmDelete(ids) {
    const that = this;
    Modal.confirm({
      title: "Are you sure",
      content:
        "Are you sure you want to delete the selected review pools(s). Once deleted, review pools cannot be recovered",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        that.deleteSelectedReviewPools(ids);
      }
    });
  }

  toggleEditViewsModal(id) {
    if (id) {
      const account = this.state.accounts.find(a => a.id === id);
      this.setState({
        editViewsModal: {
          id,
          visible: !this.state.editViewsModal.visible,
          accountName: account.name ? account.name : "",
          value: account.allocated ? account.allocated : 0
        }
      });
    } else {
      this.setState({
        editViewsModal: {
          id: undefined,
          visible: !this.state.editViewsModal.visible,
          accountName: "",
          value: 0
        }
      });
    }
  }

  editViewsModalConfirm() {
    setViews(this.state.editViewsModal.id, this.state.editViewsModal.value)
      .then(res => {
        if (res.data.success === 1) {
          this.setState({
            accounts: this.state.accounts.map(a => {
              if (a.id === this.state.editViewsModal.id) {
                return { ...a, allocated: this.state.editViewsModal.value };
              } else {
                return a;
              }
            }),
            editViewsModal: {
              id: undefined,
              visible: false,
              accountName: "",
              value: 0
            }
          });
          message.success("Views Updated Successfully!");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch(e => message.error(e.message));
  }

  confirmDeleteAccount(id) {
    Modal.confirm({
      title: "Confirm Delete",
      content:
        "Are you sure you want to delete this account? Once deleted, your account cannot be recovered!",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        deleteAccount(id)
          .then(res => {
            if (res.data.success === 1) {
              message.success("Account deleted successfully!");
              this.setState({
                accounts: this.state.accounts.filter(a => a.id !== id)
              });
            } else {
              message.error(res.data.msg);
            }
          })
          .catch(e => message.error(e.message));
      }
    });
  }

  confirmAccountStatusChange(id, status) {
    Modal.confirm({
      title: "Are you sure",
      content: `Are you sure you want to ${
        status === 0 ? "deactivate" : "activate"
      } this account?`,
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        toggleAccountStatus(id, status)
          .then(res => {
            if (res.data.success === 1) {
              message.success(
                `Account ${
                  status === 0 ? "deactivated" : "activated"
                } successfully!`
              );
              this.setState({
                accounts: this.state.accounts.map(a => {
                  if (a.id === id) {
                    return { ...a, status };
                  } else {
                    return a;
                  }
                })
              });
            } else {
              message.error(res.data.msg);
            }
          })
          .catch(e => message.error(e.message));
      }
    });
  }

  render() {
    const columns = [
      {
        title: "Account Name",
        dataIndex: "name"
      },
      {
        title: "Impressions Allocated",
        dataIndex: "allocated"
      },
      {
        title: "Impressions Left",
        dataIndex: "remaining",
        render: (_, record) => Math.max(0, record.allocated - record.used)
      },
      {
        title: "Actions",
        dataIndex: "id",
        width: "230px",
        render: (_, record) => (
          <div className="actions-div">
            <Tooltip placement="top" title="Edit">
              <Button
                type="danger"
                className="edit-btn"
                onClick={() => this.toggleEditViewsModal(record.id)}
              >
                <Icon component={EditIcon} />
                Edit
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <Button
                type="danger"
                className="delete-btn"
                onClick={() => this.confirmDeleteAccount(record.id)}
              >
                <Icon component={DeleteReviewIcon} />
                Delete
              </Button>
            </Tooltip>
          </div>
        )
      }
    ];

    return (
      <div className="manage-accounts">
        <MainHeadingBar
          title="Manage Accounts"
          helpText="Manage your accounts added here."
          hideCTA
        />
        <Card>
          <Row
            style={{
              background: "#FAFAFA",
              padding: "1rem 3rem",
              marginBottom: "1rem"
            }}
          >
            <Col xs={8} style={{ textAlign: "left" }}>
              <div style={{ textAlign: "left", display: "inline-block" }}>
                <h4 style={{ fontSize: "1.14rem", color: "#151515" }}>
                  Total Impressions
                </h4>
                <span style={{ fontSize: "1rem", color: "#979797" }}>
                  {this.state.planAlloted === -1
                    ? "Unlimited"
                    : this.state.planAlloted}
                  {this.state.impressions.total_fixed > 0
                    ? this.state.impressions.total_fixed
                    : ""}
                  {this.state.impressions.total_fixed > 0 &&
                  this.state.impressions.total_monthly > 0
                    ? " + "
                    : ""}
                  {this.state.impressions.total_monthly > 0
                    ? `${this.state.impressions.total_monthly}/mo`
                    : ""}
                </span>
              </div>
            </Col>
            <Col xs={8} style={{ textAlign: "center" }}>
              <div style={{ textAlign: "left", display: "inline-block" }}>
                <h4 style={{ fontSize: "1.14rem", color: "#151515" }}>
                  Allocated Impressions
                </h4>
                <span style={{ fontSize: "1rem", color: "#979797" }}>
                  {this.state.accounts.reduce((a, b) => a + b.allocated, 0) +
                    this.state.wlblCounts.allocated}
                </span>
              </div>
            </Col>
            <Col xs={8} style={{ textAlign: "right" }}>
              <div style={{ textAlign: "left", display: "inline-block" }}>
                <h4 style={{ fontSize: "1.14rem", color: "#151515" }}>
                  Unallocated Impressions
                </h4>
                <span style={{ fontSize: "1rem", color: "#979797" }}>
                  {this.state.impressions.total_remaining}
                </span>
              </div>
            </Col>
          </Row>
          <Table
            rowKey={record => record.id}
            dataSource={this.state.accounts}
            columns={columns}
            pagination={false}
            loading={this.state.loading}
            onChange={this.handleTableChange}
          />
        </Card>

        <Modal
          className="edit-views-modal"
          visible={this.state.editViewsModal.visible}
          onOk={() => this.toggleEditViewsModal()}
          onCancel={() => this.toggleEditViewsModal()}
          title="Edit Total Views"
          footer={null}
          style={{ width: "34.5rem", zIndex: 1000 }}
        >
          <p>
            Please enter the new limit of total views for <br /> “
            {this.state.editViewsModal.accountName}”
          </p>
          <InputNumber
            value={this.state.editViewsModal.value}
            placeholder="Enter the new limit"
            onChange={val =>
              this.setState({
                editViewsModal: { ...this.state.editViewsModal, value: val }
              })
            }
            style={{ width: "100%" }}
          />
          <Button
            className="edit-view-modal-save-btn"
            size="large"
            onClick={() => this.editViewsModalConfirm()}
          >
            Save & Continue
          </Button>
        </Modal>
      </div>
    );
  }
}

export default ManageAccountsPG;
