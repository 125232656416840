import React from "react";
import './index.scss';
import { Tabs } from "antd";
import Pieslice from "../../Partials/Pieslice";

const PlatformWiseStats = (props) => {
    const { platform_wise_clicks } = props;

    return (
        <Tabs defaultActiveKey="2" className="platformwise-stats-tabs">
            <Tabs.TabPane tab="Clicks" key="2">
                <Pieslice data={platform_wise_clicks} category='platform' type='clicks' />
            </Tabs.TabPane>
        </Tabs>
    );
}

export default PlatformWiseStats;