import React, { Component } from 'react';
import './index.scss';
import 'ant-design-pro/dist/ant-design-pro.css';
import OverallStats from './OverallStats';
import Last30DayStats from './Last30DayStats';
import NextSteps from './NextSteps';
import { getElements } from '../../../helpers/ajax/elementsAjax';
import { connect } from 'react-redux';
import { Skeleton, Alert, Row, Card, Col, Modal, Icon, Button } from 'antd';
import ElementCard from '../Elements/ElementCard';
import NoData from '../Partials/NoData';
import MainHeadingBar from '../Partials/MainHeadingBar';
import { ReactComponent as Placeholder } from '../../../assets/images/icons/no-data.svg';
import queryString from 'query-string';

class Snapshot extends Component {
    state = {
        activation_status: 0,
        product_updates_available: 1,
        loading: false,
        error: false,
        elements: [],
        errorMsg: '',
    };

    fetchRecentElements() {
        getElements(this.props.match.params.domid, 0, 1, 3).then((res) => {
            this.setState({
                elements: res.data.elements
            })
        })
    }

    componentDidMount() {
        this.fetchRecentElements();
    }

    render() {
        const {
            loading,
            error,
            errorMsg,
        } = this.state;

        if (loading) {
            return <div style={{ marginBottom: "30px" }}>
                <Card><Skeleton active /></Card>
            </div>
        }

        if (!loading && error) {
            return <div>
                <Row>
                    <Alert
                        message="There was an error"
                        description={errorMsg}
                        type="error"
                    />
                </Row>
            </div>;
        }

        const parsed = queryString.parse(window.location.search);
        return (
            <div className="snapshot">
                <MainHeadingBar title="Your Summary" {...this.props} />
                <OverallStats {...this.props} />
                <NextSteps />
                <Last30DayStats {...this.props} />
                <Row gutter={30} style={{ margin: "20px 0" }}>
                    <h2>Last Few Elements</h2>
                </Row>
                <Row className="elements" gutter={30} style={{ margin: "20px 0" }}>
                    {this.state.elements.length > 0 ?
                        this.state.elements.map((e) => <Col xs={24} md={8} key={e._id}><ElementCard element={e} /></Col>)
                        : <Card><NoData
                            image={Placeholder}
                            heading="No Elements Found!"
                        /></Card>}
                </Row>
                <Modal
                    visible={parsed['showWelcome'] === "true"}
                    footer={null}
                    closable={false}
                    width={536}
                    style={{ textAlign: 'center' }}
                >
                    <Icon type="check-circle" theme="filled" style={{ color: '#00a154', fontSize: 60 }} />

                    <h3 className="heading" style={{ fontSize: 30 }}>
                        Success!
                    </h3>

                    <p className="disclaimer">
                        Your account setup is complete. We have allocated 1,000 monthly impressions to your account. To manage your monthly impressions you can visit the Manage Accounts Page.
                    </p>
                    <Button
                        style={{
                            background: '#00a154',
                            borderColor: '#00a154',
                            color: '#fff'
                        }} onClick={() => this.props.history.push('/manage-accounts')}>Manage Accounts</Button>
                </Modal>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    };
};

export default connect(mapStateToProps)(Snapshot);