import React, { Component } from "react";
import { Modal, Row, Col, Input, message } from "antd";
import activecampaign from "../../../assets/images/autoresponders/activecampaign.png";
import convertkit from "../../../assets/images/autoresponders/convertkit.png";
import drip from "../../../assets/images/autoresponders/drip.png";
import mailchimp from "../../../assets/images/autoresponders/mailchimp.png";
import infusionsoft from "../../../assets/images/autoresponders/infusionsoft.png";
import ontraport from "../../../assets/images/autoresponders/ontraport.png";
import oneshoppingcart from "../../../assets/images/autoresponders/1shoppingcart.png";
import clickfunnels from "../../../assets/images/autoresponders/clickfunnels.png";
import autopilothq from "../../../assets/images/autoresponders/autopilothq.png";
import autorespond from "../../../assets/images/autoresponders/autorespond.jpg";
import aweber from "../../../assets/images/autoresponders/aweber.png";
import bronto from "../../../assets/images/autoresponders/bronto.png";
import campaignmonitor from "../../../assets/images/autoresponders/campaignmonitor.png";
import constantcontact from "../../../assets/images/autoresponders/constantcontact.png";
import customer from "../../../assets/images/autoresponders/customer.png";
import exacttarget from "../../../assets/images/autoresponders/exacttarget.png";
import genoo from "../../../assets/images/autoresponders/genoo.png";
import getresponse from "../../../assets/images/autoresponders/getresponse.png";
import hubspot from "../../../assets/images/autoresponders/hubspot.png";
import icontact from "../../../assets/images/autoresponders/icontact.png";
import intercom from "../../../assets/images/autoresponders/intercom.png";
import interspire from "../../../assets/images/autoresponders/interspire.png";
import megaphoneapp from "../../../assets/images/autoresponders/megaphoneapp.png";
import kartra from "../../../assets/images/autoresponders/kartra.jpg";
import klaviyo from "../../../assets/images/autoresponders/klaviyo.png";
import klicktipp from "../../../assets/images/autoresponders/klicktipp.png";
import madmimi from "../../../assets/images/autoresponders/madmimi.png";
import mailerlite from "../../../assets/images/autoresponders/mailerlite.png";
import mailgun from "../../../assets/images/autoresponders/mailgun.png";
import markethero from "../../../assets/images/autoresponders/markethero.png";
import maropost from "../../../assets/images/autoresponders/maropost.png";
import respondercoil from "../../../assets/images/autoresponders/responder.co.il.png";
import sendinblue from "../../../assets/images/autoresponders/sendinblue.png";
import sendlane from "../../../assets/images/autoresponders/sendlane.png";
import awprotools from "../../../assets/images/autoresponders/awprotools.png";
import sendreach from "../../../assets/images/autoresponders/sendreach.png";
import simplero from "../../../assets/images/autoresponders/simplero.png";
import wpmktgengine from "../../../assets/images/autoresponders/wpmktgengine.png";
import zoho from "../../../assets/images/autoresponders/zoho.png";
import mailpoet from "../../../assets/images/autoresponders/mailpoet.png";
import kyvio from "../../../assets/images/autoresponders/kyvio.png";
import platformly from "../../../assets/images/autoresponders/platformly.png";
import woocommerce from "../../../assets/images/autoresponders/woocommerce.jpg";
import gmail from "../../../assets/images/autoresponders/gmail.png";
import mailvio from "../../../assets/images/autoresponders/mailvio.jpg";
import "./CopyAutoresponderCodeModal.scss";
import ClickToCopy from "../Partials/ClickToCopy";
const { confirm } = Modal;

class CopyAutoresponderCodeModal extends Component {
  state = {
    name: "",
  };

  services = {
    activecampaign: {
      name: "ActiveCampaign",
      img: activecampaign,
      placeHolder: { email: "%EMAIL%", name: "%FIRSTNAME%" },
    },
    convertkit: {
      name: "ConvertKit",
      img: convertkit,
      placeHolder: {
        email: "{{subscriber.email_address}}",
        name: "{{subscriber.first_name}}",
      },
    },
    drip: {
      name: "Drip",
      img: drip,
      placeHolder: {
        email: "{{subscriber.email}}",
        name: "{{subscriber.first_name}}",
      },
    },
    mailchimp: {
      name: "MailChimp",
      img: mailchimp,
      placeHolder: { email: "*|EMAIL|*", name: "*|FNAME|*" },
    },
    infusionsoft: {
      name: "Infusionsoft",
      img: infusionsoft,
      placeHolder: { email: "~Contact.Email~", name: "" },
    },
    ontraport: {
      name: "Ontraport",
      img: ontraport,
      placeHolder: { email: "[Email]", name: "[First Name]" },
    },
    oneshoppingcart: {
      name: "1ShoppingCart",
      img: oneshoppingcart,
      placeHolder: { email: "[email]", name: "" },
    },
    clickfunnels: {
      name: "ClickFunnels",
      img: clickfunnels,
      placeHolder: { email: "#EMAIL#", name: "#FIRST#" },
    },
    autopilothq: {
      name: "Autopilot",
      img: autopilothq,
      placeHolder: { email: "--Email--", name: "--First Name--" },
    },
    autorespond: {
      name: "Autorespond.nl",
      img: autorespond,
      placeHolder: { email: "{relation.email}", name: "" },
    },
    aweber: {
      name: "AWeber",
      img: aweber,
      placeHolder: { email: "{!email}", name: "{!firstname_fix}" },
    },
    bronto: {
      name: "Bronto",
      img: bronto,
      placeHolder: { email: "%%email%%", name: "%%firstname%%" },
    },
    campaignmonitor: {
      name: "CampaignMonitor",
      img: campaignmonitor,
      placeHolder: { email: "[email]", name: "[firstname]" },
    },
    constantcontact: {
      name: "ConstantContact",
      img: constantcontact,
      placeHolder: { email: "Subscriber.Email", name: "" },
    },
    customer: {
      name: "Customer.io",
      img: customer,
      placeHolder: {
        email: "{{customer.email}}",
        name: "{{customer.first_name}}",
      },
    },
    exacttarget: {
      name: "ExactTarget",
      img: exacttarget,
      placeHolder: { email: "%%email%%", name: "%%firstname%%" },
    },
    genoo: {
      name: "Genoo",
      img: genoo,
      placeHolder: { email: "[emailaddress]", name: "" },
    },
    getresponse: {
      name: "GetResponse",
      img: getresponse,
      placeHolder: { email: "[[email]]", name: "[[firstname]]" },
    },
    hubspot: {
      name: "HubSpot",
      img: hubspot,
      placeHolder: {
        email: "{{contact.email}}",
        name: "{{contact.firstname}}",
      },
    },
    icontact: {
      name: "iContact",
      img: icontact,
      placeHolder: { email: "[email]", name: "[fname]" },
    },
    intercom: {
      name: "Intercom",
      img: intercom,
      placeHolder: { email: "{{ email }}", name: "{{ first_name }}" },
    },
    interspire: {
      name: "Interspire",
      img: interspire,
      placeHolder: { email: "%%email%%", name: "%%First Name%%" },
    },
    megaphoneapp: {
      name: "Kajabi",
      img: megaphoneapp,
      placeHolder: { email: "{{email}}", name: "" },
    },
    kartra: {
      name: "Kartra",
      img: kartra,
      placeHolder: { email: "{email}", name: "{first_name}" },
    },
    klaviyo: {
      name: "Klaviyo",
      img: klaviyo,
      placeHolder: { email: "{{ email }}", name: "{{ first_name }}" },
    },
    klicktipp: {
      name: "KlickTipp",
      img: klicktipp,
      placeHolder: { email: "%Subscriber:EmailAddress%", name: "" },
    },
    madmimi: {
      name: "MadMimi",
      img: madmimi,
      placeHolder: { email: "(email)", name: "(firstname)" },
    },
    mailerlite: {
      name: "MailerLite",
      img: mailerlite,
      placeHolder: { email: "{$email}", name: "{$name}" },
    },
    mailgun: {
      name: "Mailgun",
      img: mailgun,
      placeHolder: { email: "%recipient_email%", name: "%recipient_fname%" },
    },
    markethero: {
      name: "Market Hero",
      img: markethero,
      placeHolder: { email: "|lead.email|", name: "" },
    },
    maropost: {
      name: "Maropost",
      img: maropost,
      placeHolder: { email: "{{contact.email}}", name: "" },
    },
    respondercoil: {
      name: "Responder.co.il",
      img: respondercoil,
      placeHolder: { email: "[[דואר אלקטרוני]]", name: "" },
    },
    sendinblue: {
      name: "SendInBlue",
      img: sendinblue,
      placeHolder: { email: "{EMAIL}", name: "{{ contact.FIRSTNAME }}" },
    },
    sendlane: {
      name: "Sendlane",
      img: sendlane,
      placeHolder: { email: "VAR_EMAIL", name: "VAR_FIRST_NAME" },
    },
    awprotools: {
      name: "AWProTools",
      img: awprotools,
      placeHolder: { email: "{!email}", name: "" },
    },
    sendreach: {
      name: "SendReach",
      img: sendreach,
      placeHolder: { email: "[EMAIL]", name: "" },
    },
    simplero: {
      name: "Simplero",
      img: simplero,
      placeHolder: { email: "{{email}}", name: "" },
    },
    wpmktgengine: {
      name: "WPMktgEngine",
      img: wpmktgengine,
      placeHolder: { email: "[emailaddress]", name: "" },
    },
    zoho: {
      name: "Zoho",
      img: zoho,
      // eslint-disable-next-line
      placeHolder: { email: "${Email}$", name: "${FNAME}$" },
    },
    mailpoet: {
      name: "MailPoet",
      img: mailpoet,
      placeHolder: { email: "[subscriber:email]", name: "[user:firstname]" },
    },
    kyvio: {
      name: "Kyvio",
      img: kyvio,
      placeHolder: {
        email: "[[subscriber_email]]",
        name: "[[subscriber_first_name]]",
      },
    },
    platformly: {
      name: "Platform.ly",
      img: platformly,
      placeHolder: { email: "[email]", name: "" },
    },
    woocommerce: {
      name: "WooCommerce",
      img: woocommerce,
      placeHolder: {
        email: "{customer_email}",
        name: "{customer_first_name}",
      },
    },
    mailvio: {
      name: "Mailvio",
      img: mailvio,
      placeHolder: {
        email: "{{ contact.EMAIL }}",
        name: "{{ contact.FIRSTNAME }}",
      },
    },
  };

  confirmNameAndCopy() {
    confirm({
      title: "Enter Recipient Name",
      content: (
        <Input onChange={(e) => this.setState({ name: e.target.value })} />
      ),
      onOk: () => {
        const name = this.state.name;
        const textArea = document.createElement("textarea");
        textArea.value = this.getARCode({
          name: "GMail",
          img: "",
          placeHolder: { email: "%EMAIL%", name },
        });
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        message.success("Copied Successfully!");
        this.props.onCancel();
      },
    });
  }

  getARCode = (s) => {
    let params = [];
    let code = this.props.code;

    if (s["placeHolder"]) {
      params.push("name=" + s["placeHolder"].name);
      params.push("email=" + s["placeHolder"].email);
    }
    code = code.replace(new RegExp("\\[FNAME\\]", "g"), s["placeHolder"].name);
    code = code.replace(new RegExp("\\[EMAIL\\]", "g"), s["placeHolder"].email);
    code = code.replace(
      new RegExp("/trackrecord/\\?", "g"),
      "/trackrecord/?" + params.join("&") + "&autoresponder=" + s.name + "&"
    );
    code = code.replace(
      new RegExp("/trackclick/\\?", "g"),
      "/trackclick/?" + params.join("&") + "&autoresponder=" + s.name + "&"
    );
    code = code.replace(/'/g, "&apos;");
    return code;
  };

  render() {
    return (
      <Modal
        className="autoresponder-modal"
        visible={this.props.visible}
        title="Choose AutoResponder"
        width={700}
        onCancel={() => this.props.onCancel()}
        footer={null}
        destroyOnClose={true}
      >
        <Row>
          This helps us to customize the element for a better tracking
          integration with your autoresponder.
        </Row>
        <Row>
          <Col xs={6}>
            <div
              className="autoresponder-service"
              onClick={() => this.confirmNameAndCopy()}
            >
              <p>GMail</p>
              <div className="autoresponder-img">
                <img src={gmail} alt="GMail" style={{ maxWidth: "100%" }} />
              </div>
            </div>
          </Col>
          {Object.keys(this.services).map((s) => {
            const code = this.getARCode(this.services[s]);

            return (
              <Col xs={6} key={s}>
                <ClickToCopy textToCopy={code}>
                  <div
                    className="autoresponder-service"
                    onClick={() => this.props.onCancel()}
                  >
                    <p>{this.services[s].name}</p>
                    <div className="autoresponder-img">
                      <img
                        src={this.services[s].img}
                        alt={s}
                        style={{ maxWidth: "100%" }}
                      />
                    </div>
                  </div>
                </ClickToCopy>
              </Col>
            );
          })}
        </Row>
      </Modal>
    );
  }
}

export default CopyAutoresponderCodeModal;
