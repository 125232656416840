import React, { Component } from 'react';
import { Input, message } from 'antd';
import { connect } from 'react-redux';
import { ChromePicker } from 'react-color';

class ColorPicker extends Component {

  state = {
    displayColorPicker: false,
    color: this.props.defaultValue ? this.props.defaultValue : '#D40000',
  };

  componentDidUpdate(pp) {
    if (pp.defaultValue !== this.props.defaultValue) {
      this.setState({ color: this.props.defaultValue });
    }
  }

  handleClick = () => {
    if (this.props.auth.plan && this.props.auth.plan.plan_features && this.props.auth.plan.plan_features.ADVANCED_CUSTOMISATION === 1) {
      this.setState({ displayColorPicker: !this.state.displayColorPicker })
    } else {
      message.error("Your plan does not allow you to customize your elements!");
    }
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    if (!(this.props.auth.plan && this.props.auth.plan.plan_features && this.props.auth.plan.plan_features.ADVANCED_CUSTOMISATION === 1)) {
      message.error("Your plan does not allow you to customize your elements!");
    }
    this.setState({ color: color.hex }, () => {
      if (this.props.onChange) {
        let format = "hex";
        if (this.props.format && ["hex", "rgb", "hsl"].indexOf(this.props.format) !== -1) {
          format = this.props.format
        }
        this.props.onChange(color[format]);
      }
    })
  };

  render() {
    const popover = {
      position: 'absolute',
      zIndex: '1001',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    return (
      <div className="ant-form-item-control">
        <p className="form-labels">{this.props.label}</p>
        {!this.props.hideInput && <Input
          style={{ width: 100 }} value={this.state.color} onClick={this.handleClick} readOnly />}
        <div style={{
          display: "inline-block",
          verticalAlign: 'middle',
          marginLeft: 4,
          marginTop: -3,
          width: 36,
          height: 36,
          borderRadius: 3,
          background: this.state.color,
          border: '1px solid #d9d9d9'
        }} onClick={this.handleClick}>
        </div>
        {this.state.displayColorPicker ? <div style={popover}>
          <div style={cover} onClick={this.handleClose} />
          <ChromePicker color={this.state.color} onChange={this.handleChange} />
        </div> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  }
}

export default connect(mapStateToProps)(ColorPicker);