import React, { Component } from 'react';
import { Input, Select, Row, Button, message, Switch } from 'antd';
import { set, merge } from 'lodash';
const { Option } = Select;

class SocialBadgesElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'social_badges',
      element_group: 'growth_elements',
      element_domid: this.props.match.params.domid,
      data: {
        title: "Follow us on social media",
        networks: {
          "facebook": "",
          "twitter": "",
          "google_plus": "",
          "pinterest": "",
          "linkedin": ""
        }
      }
    },
    show: {
      "facebook": false,
      "twitter": false,
      "google_plus": false,
      "pinterest": false,
      "linkedin": false,
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      const element = merge(this.state.element, this.props.element);
      let show = this.state.show;
      Object.keys(show).forEach((k) => show[k] = element.data.networks[k] && element.data.networks[k].trim().length > 0 ? true : false)
      this.setState({
        element,
        show
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.title.trim().length === 0) {
      errors.title = "This is a required field!";
    }
    if (element.data.networks.facebook.trim().length > 0 && !element.data.networks.facebook.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.facebook = "Please enter a valid URL!";
    }
    if (element.data.networks.twitter.trim().length > 0 && !element.data.networks.twitter.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.twitter = "Please enter a valid URL!";
    }
    if (element.data.networks.google_plus.trim().length > 0 && !element.data.networks.google_plus.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.google_plus = "Please enter a valid URL!";
    }
    if (element.data.networks.pinterest.trim().length > 0 && !element.data.networks.pinterest.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.pinterest = "Please enter a valid URL!";
    }
    if (element.data.networks.linkedin.trim().length > 0 && !element.data.networks.linkedin.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
      errors.linkedin = "Please enter a valid URL!";
    }
    errors.atleastOneEnabled = "Please enable at least one social network!";
    Object.keys(element.data.networks).forEach((k) => {
      if (this.state.show[k] && element.data.networks[k].length > 0) {
        delete (errors.atleastOneEnabled)
      }
    });

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          let element = this.state.element;
          Object.keys(element.data.networks).forEach((k) => {
            if (element.data.networks[k].trim().length === 0 || !this.state.show[k]) {
              element.data.networks[k] = undefined;
            }
          });
          this.props.saveElement(this.state.element, (res) => {
  this.setState({
    saving: false
  })
  if (res.data.success !== 1) {
    message.error(res.data.msg);
  }
})
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.title ? 'has-error' : ''}`}>
          <p className="form-labels">Title
          <Input
              value={this.state.element.data.title}
              onChange={(e) => { this.updateField("data.title", e.target.value) }} />
          </p>
          {this.state.errors.title && <div className="ant-form-explain">{this.state.errors.title}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">
            <label><Switch
              checked={this.state.show.facebook}
              onChange={(checked) => { this.setState({ "show": { ...this.state.show, facebook: checked } }) }} />
              &nbsp;&nbsp;&nbsp; Facebook</label>
          </p>
        </div>
        {this.state.show.facebook && <div className={`ant-form-item-control indented ${this.state.errors.facebook ? 'has-error' : ''}`}>
          <p className="form-labels">Facebook URL
          <Input
              value={this.state.element.data.networks.facebook}
              onChange={(e) => { this.updateField("data.networks.facebook", e.target.value) }}
              onBlur={(e) => { this.updateField("data.networks.facebook", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
          </p>
          {this.state.errors.facebook && <div className="ant-form-explain">{this.state.errors.facebook}</div>}
        </div>}

        <div className={`ant-form-item-control`}>
          <p className="form-labels">
            <label><Switch
              checked={this.state.show.twitter}
              onChange={(checked) => { this.setState({ "show": { ...this.state.show, twitter: checked } }) }} />
              &nbsp;&nbsp;&nbsp; Twitter</label>
          </p>
        </div>
        {this.state.show.twitter && <div className={`ant-form-item-control indented ${this.state.errors.twitter ? 'has-error' : ''}`}>
          <p className="form-labels">Twitter URL
          <Input
              value={this.state.element.data.networks.twitter}
              onChange={(e) => { this.updateField("data.networks.twitter", e.target.value) }}
              onBlur={(e) => { this.updateField("data.networks.twitter", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
          </p>
          {this.state.errors.twitter && <div className="ant-form-explain">{this.state.errors.twitter}</div>}
        </div>}

        <div className={`ant-form-item-control`}>
          <p className="form-labels">
            <label><Switch
              checked={this.state.show.google_plus}
              onChange={(checked) => { this.setState({ "show": { ...this.state.show, google_plus: checked } }) }} />
              &nbsp;&nbsp;&nbsp; Google Plus</label>
          </p>
        </div>
        {this.state.show.google_plus && <div className={`ant-form-item-control indented ${this.state.errors.google_plus ? 'has-error' : ''}`}>
          <p className="form-labels">Google Plus URL
          <Input
              value={this.state.element.data.networks.google_plus}
              onChange={(e) => { this.updateField("data.networks.google_plus", e.target.value) }}
              onBlur={(e) => { this.updateField("data.networks.google_plus", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
          </p>
          {this.state.errors.google_plus && <div className="ant-form-explain">{this.state.errors.google_plus}</div>}
        </div>}

        <div className={`ant-form-item-control`}>
          <p className="form-labels">
            <label><Switch
              checked={this.state.show.pinterest}
              onChange={(checked) => { this.setState({ "show": { ...this.state.show, pinterest: checked } }) }} />
              &nbsp;&nbsp;&nbsp; Pinterest</label>
          </p>
        </div>
        {this.state.show.pinterest && <div className={`ant-form-item-control indented ${this.state.errors.pinterest ? 'has-error' : ''}`}>
          <p className="form-labels">Pinterest URL
          <Input
              value={this.state.element.data.networks.pinterest}
              onChange={(e) => { this.updateField("data.networks.pinterest", e.target.value) }}
              onBlur={(e) => { this.updateField("data.networks.pinterest", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
          </p>
          {this.state.errors.pinterest && <div className="ant-form-explain">{this.state.errors.pinterest}</div>}
        </div>}

        <div className={`ant-form-item-control`}>
          <p className="form-labels">
            <label><Switch
              checked={this.state.show.linkedin}
              onChange={(checked) => { this.setState({ "show": { ...this.state.show, linkedin: checked } }) }} />
              &nbsp;&nbsp;&nbsp; LinkedIn</label>
          </p>
        </div>
        {this.state.show.linkedin && <div className={`ant-form-item-control indented ${this.state.errors.linkedin ? 'has-error' : ''}`}>
          <p className="form-labels">LinkedIn URL
          <Input
              value={this.state.element.data.networks.linkedin}
              onChange={(e) => { this.updateField("data.networks.linkedin", e.target.value) }}
              onBlur={(e) => { this.updateField("data.networks.linkedin", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
          </p>
          {this.state.errors.linkedin && <div className="ant-form-explain">{this.state.errors.linkedin}</div>}
        </div>}
        <div className={`ant-form-item-control indented ${this.state.errors.atleastOneEnabled ? 'has-error' : ''}`}>
          {this.state.errors.atleastOneEnabled && <div className="ant-form-explain">{this.state.errors.atleastOneEnabled}</div>}
        </div>
        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button> &nbsp;
        </Row>
      </form>
    )
  }

}

export default SocialBadgesElement;