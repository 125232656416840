import React, { Component } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import Login from './Components/Auth/Login/';
import ForgotPassword from './Components/Auth/ForgotPassword';
import AddUserSignup from './Components/Auth/AddUserSignup';
import ResetPassword from './Components/Auth/ResetPassword';
import Signup from './Components/Auth/Signup';
import Logout from './Components/Auth/Logout';
import Dashboard from './Components/Dashboard';
import Exhausted from './Components/Dashboard/Exhausted';
import Snapshot from './Components/Dashboard/Snapshot';
import Library from './Components/Dashboard/Library';
import SearchMedia from './Components/Dashboard/SearchMedia';
import Youzign from './Components/Dashboard/Youzign';
import Elements from './Components/Dashboard/Elements/Elements';
import Links from './Components/Dashboard/Links/Links';
import NewElement from './Components/Dashboard/NewElement';
import ProfileForm from './Components/Dashboard/ProfileSettings';
import ManageAccountsMain from './Components/Dashboard/ManageAccountsMain';
import MailMasterySessions from './Components/Dashboard/MailMasterySessions';
import Settings from './Components/Dashboard/Settings';
import BonusPage from './Components/Dashboard/BonusPage';
import NotFound404 from './Components/NotFound404';
// import Billing from './Components/Dashboard/Billing';
import ActivationCodes from './Components/Dashboard/ActivationCodes';
import Onboarding from './Components/Onboarding';
import SignupQuestions from './Components/SignupQuestions';
import SwitchToAccount from './Components/Dashboard/SwitchToAccount';
import ElementReport from './Components/Dashboard/ElementReport';
import LinkReport from './Components/Dashboard/LinkReport';
import LinkCloaker from './Components/Dashboard/Links/LinkCloaker';

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<div className="content-body">
					<Switch>

						<Route
							exact
							path='/'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox"></Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Snapshot">
									<Snapshot {...props} />
								</Dashboard>
							)}
						/>

						<Route exact path='/login' render={(props) => <Login {...{ ...props, title: 'LetMailbox | Login' }} />} />
						<Route path='/login/forgot/:hash' render={(props) => <ResetPassword {...{ ...props, title: 'LetMailbox | Reset Password' }} />} />
						<Route path='/login/adduser/:hash' render={(props) => <AddUserSignup {...{ ...props, title: 'LetMailbox | Signup' }} />} />
						<Route path='/forgot-password' render={(props) => <ForgotPassword {...{ ...props, title: 'LetMailbox | Forgot Password' }} />} />
						<Route path='/signup' render={(props) => <Signup {...{ ...props, title: 'LetMailbox | Signup' }} />} />
						<Route path='/signup_invite' render={(props) => <Signup {...{ ...props, title: 'LetMailbox | Signup' }} />} />
						<Route path='/pitchground' render={(props) => <Signup {...{ ...props, title: 'LetMailbox | Signup' }} />} />


						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/settings'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Settings">
									<Settings {...props} />
								</Dashboard>
							)}
						/>
						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/settings/accounts'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Settings">
									<Settings {...props} activeTab="1" />
								</Dashboard>
							)}
						/>
						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/settings/users'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Settings">
									<Settings {...props} activeTab="2" />
								</Dashboard>
							)}
						/>
						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/settings/domains'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Settings">
									<Settings {...props} activeTab="3" />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/elements'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Elements" openKeys={["submenu_elements"]}>
									<Elements {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/elements/archived'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Archived Elements" openKeys={["submenu_elements"]}>
									<Elements {...props} archived="1" />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/my_links'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | My Links" openKeys={["submenu_links"]}>
									<Links {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/link_cloaker'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Link Cloaker" openKeys={["submenu_links"]}>
									<LinkCloaker {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/link_details/:linkid([a-fA-F0-9]{24})'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Link Details">
									<LinkReport {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/onboarding'
							render={(props) => (
								<Onboarding title="LetMailbox | Onboarding" {...props} />
							)}
						/>

						<Route
							exact
							path='/complete_profile'
							render={(props) => (
								<SignupQuestions title="LetMailbox | Complete Profile" {...props} />
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/library/youzign'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Youzign" openKeys={["submenu_library"]}>
									<Youzign {...props} />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/library/search-gifs'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Search GIFs" openKeys={["submenu_library"]}>
									<SearchMedia {...props} type="gifs" />
								</Dashboard>
							)}
						/>

						<Route
							path='/:domid([a-fA-F0-9]{24})/library/pixabay'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Pixabay" openKeys={["submenu_library"]}>
									<SearchMedia {...props} type="pixabay" />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/library'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | My Library" openKeys={["submenu_library"]}>
									<Library {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/settings'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Profile Settings">
									<ProfileForm {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/new_element/:elementid([a-fA-F0-9]{24})?'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | New Element" openKeys={["submenu_elements"]}>
									<NewElement {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/bonus'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Bonus">
									<BonusPage {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/manage-accounts'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Manage Accounts">
									<ManageAccountsMain {...props} />
								</Dashboard>
							)}
						/>
						<Route
							exact
							path='/mail-mastery-sessions'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Mail Mastery Sessions">
									<MailMasterySessions {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/activation-codes'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Activation Codes">
									<ActivationCodes {...props} />
								</Dashboard>
							)}
						/>

						{/* <Route
							exact
							path='/billing'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Billing">
									<Billing {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/steps/:stepid([0-9])?'
							render={(props) => (
								<Steps title="LetMailbox | Onboarding" {...props} />
							)}
						/> */}

						<Route path='/switch-account/:domid([a-fA-F0-9]{24})?' render={(props) => <SwitchToAccount {...props} />} />

						<Route
							exact
							path='/:domid([a-fA-F0-9]{24})/element_details/:campid([a-fA-F0-9]{24})'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox | Element Details">
									<ElementReport {...props} />
								</Dashboard>
							)}
						/>

						<Route
							exact
							path='/logout'
							render={(props) => (
								<Logout {...props} />
							)}
						/>

						<Route
							exact
							path='/exhausted'
							render={(props) => (
								<Dashboard {...props} title="LetMailbox">
									<Exhausted {...props} />
								</Dashboard>
							)}
						/>

						<Route
							path='*'
							render={() => (
								<NotFound404 />
							)}
						/>
					</Switch>
				</div>
			</BrowserRouter >
		);
	}
}

export default App;
