import React, { Component } from 'react';
import './index.scss';
import { Row, Col, Icon, Tooltip } from 'antd';
import { ChartCard, MiniArea, MiniProgress } from 'ant-design-pro/lib/Charts';
import { ReactComponent as Rocket } from '../../../../assets/images/icons/rocket.svg';
import { ReactComponent as Flag } from '../../../../assets/images/icons/flag.svg';
import { ReactComponent as InfoCircle } from '../../../../assets/images/icons/info-cirlce-o.svg';
import { getOverallStats } from '../../../../helpers/ajax/snapshotAjax';
import { formatNumber } from '../../../../helpers/numbers';
import { connect } from 'react-redux';

class OverallStats extends Component {
    state = {
        loading: true,
        error: false,
        errorMsg: '',
        clicks: { total: 0, this_month: [] },
        elements: { total: 0, this_month: [] },
        impressions: { total: 0, this_month: 0, overall_total: 0 },
    }

    componentDidMount() {
        getOverallStats(this.props.match.params.domid).then((res) => {
            this.setState({
                loading: false,
                clicks: res.data.analytics.clicks,
                elements: res.data.analytics.elements,
                impressions: res.data.analytics.impressions
            })
        }).catch((e) => {
            this.setState({
                error: true,
                errorMsg: e.message
            })
        })
    }

    render() {
        return (
            <div className="overall-stats">
                <Row gutter={24}>
                    <Col xs={24} md={6} className="column">
                        <ChartCard
                            loading={this.state.loading}
                            title="Total Elements"
                            action={
                                <Tooltip title="Total number of elements in this account">
                                    <Icon component={InfoCircle} />
                                </Tooltip>
                            }
                            footer={
                                <p>
                                    <span className="number-label">This month</span>
                                    <span className="number">{formatNumber(this.state.elements.this_month.map((a) => a.y).reduce((a, b) => a + b, 0), 0)}</span>
                                </p>
                            }
                        >
                            <p className="label">
                                <Icon component={Rocket} /> <span>{formatNumber(this.state.elements.total, 0)}</span>
                            </p>

                            <MiniArea
                                line
                                color="#FF2F2F"
                                borderColor="#FF2F2F"
                                height={20}
                                data={formatNumber(this.state.elements.this_month, 0)}
                            />
                        </ChartCard>
                    </Col>

                    <Col xs={24} md={6} className="column">
                        <ChartCard
                            loading={this.state.loading}
                            title="Total Impressions"
                            action={
                                <Tooltip title="Total Number of Impressions allocated to this account">
                                    <Icon component={InfoCircle} />
                                </Tooltip>
                            }
                            footer={
                                <p>
                                    <span className="number-label">Impressions This Month</span>
                                    <span className="number">{formatNumber(this.state.impressions.this_month, 0)}</span>
                                </p>
                            }
                        >
                            <p className="label">
                                <Icon component={Flag} /> <span>{formatNumber(this.state.impressions.overall_total, 0)}</span>
                            </p>

                            <MiniProgress
                                percent={this.state.impressions.total === 0 ? 0 : this.state.impressions.this_month / this.state.impressions.total * 100}
                                strokeWidth={8}
                            />
                        </ChartCard>
                    </Col>

                    <Col xs={24} md={6} className="column">
                        <ChartCard
                            loading={this.state.loading}
                            title="Total Clicks"
                            action={
                                <Tooltip title="Total number of clicks on all the elements created in this account">
                                    <Icon component={InfoCircle} />
                                </Tooltip>
                            }
                            footer={
                                <p>
                                    <span className="number-label">This month</span>
                                    <span className="number">{formatNumber(this.state.clicks.this_month.map((a) => a.y).reduce((a, b) => a + b, 0), 0)}</span>
                                </p>
                            }
                        >
                            <p className="label">
                                <Icon component={Flag} /> <span>{formatNumber(this.state.clicks.total, 0)}</span>
                            </p>

                            <MiniArea
                                line
                                color="#FF2F2F"
                                borderColor="#FF2F2F"
                                height={20}
                                data={formatNumber(this.state.clicks.this_month, 0)}
                            />
                        </ChartCard>
                    </Col>
                </Row>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth.auth
    }
}
export default connect(mapStateToProps)(OverallStats);