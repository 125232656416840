import React, { Component } from 'react';
import { Input, Row, Col, Alert, Skeleton, message, Card, Button, Icon } from 'antd';
import MainHeadingBar from '../Partials/MainHeadingBar';
import NoData from '../Partials/NoData';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { updatePlan } from '../../../store/actions/authActions';
import './index.scss';

class ActivationCodes extends Component {
  state = {
    code: '',
    codes: [],
    loading: true,
    error: false,
    errorMsg: '',
    validationError: '',
    validationInProgress: false
  };

  componentDidMount() {
    axios({
      url: '/user/activation_codes',
      method: 'post',
    }).then((res) => {
      this.setState({
        codes: res.data.codes,
        loading: false,
      })
    }).catch((e) => {
      this.setState({
        error: true,
        errorMsg: e.message,
        loading: false
      })
    })
  }

  handleSubmit = () => {
    if (!this.state.validationInProgress) {
      if (this.state.code.trim().length) {
        this.setState({
          validationInProgress: true
        }, () => {
          axios({
            url: '/user/add_activation_code',
            method: 'post',
            data: {
              domid: this.props.auth.domain.id,
              activation_code: this.state.code
            }
          }).then((res) => {
            if (res.data.success === 1) {
              message.success("Code Redeemed Successfully!");
              this.setState({
                validationInProgress: false,
                validationError: '',
                code: '',
                codes: [...this.state.codes, { code: res.data.code.code_value, usedon: res.data.code.code_usedon }]
              })
              this.props.updatePlan(res.data.plan, res.data.loginplan);
            } else {
              this.setState({
                validationInProgress: false,
                validationError: res.data.msg
              })
            }
          }).catch((e) => {
            this.setState({
              validationError: e.message,
              validationInProgress: false
            })
          })
        })
      } else {
        this.setState({
          validationError: 'Please enter a valid code',
          validationInProgress: false
        })
      }
    }
  }

  render() {
    const { user } = this.props.auth;
    if (user.origin && user.origin !== "PG") {
      return <Redirect to='/' />
    }

    const PageWrapper = (children) => (
      <div className="activation-codes">
        <MainHeadingBar title="Activation Codes" helpText="Manage your Activation codes here. " {...this.props} hideCTA />
        <Card>
          {children}
        </Card >
      </div>
    )

    if (this.state.loading) {
      return PageWrapper(<Skeleton active />)
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      )
    }

    return PageWrapper(
      <React.Fragment>
        <div style={{ padding: "45px 72px" }}>
          {this.state.codes.length === 0 &&
            <NoData content="No Activation Codes Found!" />
          }
          {this.state.codes.length > 0 &&
            <React.Fragment>
              <Row style={{ marginBottom: 10 }}>
                <Col xs={7} style={{ fontSize: 16, color: "#464646" }}>My Activation Codes</Col>
                <Col xs={{ span: 7, offset: 3 }} style={{ fontSize: 16, color: "#464646" }}>Activated On (UTC)</Col>
              </Row>
              {this.state.codes.map(ac => (
                <Row key={ac.code} style={{ marginBottom: 20 }}>
                  <Col xs={7}>
                    <Input value={ac.code} readOnly />
                  </Col>
                  <Col xs={{ span: 7, offset: 3 }} >
                    <Input value={ac.usedon} readOnly />
                  </Col>
                  {parseInt(ac.refunded) === 1 && <Col xs={{ span: 3, offset: 1 }}>Refunded</Col>}
                </Row>
              ))}
            </React.Fragment>
          }
        </div>
        {
          this.state.codes.length < 20 &&
          <React.Fragment>
            <div style={{ border: "1px solid #E8E8E8" }} />
            <div style={{ padding: "45px 72px" }}>
              <Row>
                <Col xs={7}>
                  <div className={`ant-item-form-control ${this.state.validationError ? 'has-error' : ''}`}>
                    <p style={{ fontSize: 16, color: "#464646", marginBottom: 10 }}>Redeem New Code</p>
                    <Input value={this.state.code} onChange={e => this.setState({ code: e.target.value, validationError: '' })} />
                    {this.state.validationError && <div className="ant-form-explain">{this.state.validationError}</div>}
                    <Button style={{ color: "white", background: "#FF5858", borderColor: "#FF5858", borderRadius: "3px", width: "100%", marginTop: 16 }} size="large" onClick={this.handleSubmit}>Redeem New Code {this.state.validationInProgress && <Icon type="loading" />}</Button>
                  </div>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth
});

const mapDispatchToProps = (dispatch) => {
  return {
    updatePlan: (plan, loginplan) => dispatch(updatePlan(plan, loginplan))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ActivationCodes);