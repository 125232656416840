import React, { Component } from "react";
import {
  Upload,
  Icon,
  message,
  Row,
  Col,
  Input,
  Button,
  Alert,
  Progress
} from "antd";
import axios from "axios";
const Dragger = Upload.Dragger;

class UploadVideo extends Component {
  state = {
    fileList: [],
    start: 0,
    end: 10,
    error: "",
    uploading: false,
    percentCompleted: 0
  };

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    this.setState({ error: "" });
    if (fileList.length === 0) {
      this.setState({ error: "Please select a file!" });
      return;
    }
    if (this.state.start === undefined || this.state.start < 0) {
      this.setState({ error: "Please enter a valid start time!" });
      return;
    }
    if (this.state.end === undefined || this.state.end < 0) {
      this.setState({ error: "Please enter a valid end time!" });
      return;
    }
    if (parseInt(this.state.end) < parseInt(this.state.start)) {
      this.setState({ error: "Start time should be less than the end time." });
      return;
    }
    if (this.state.end - this.state.start > 10) {
      this.setState({
        error: "Clipped video length cannot be more than 10 seconds."
      });
      return;
    }
    formData.append("media", fileList[0]);
    formData.append("start", this.state.start);
    formData.append("end", this.state.end);
    formData.append("domid", this.props.match.params.domid);

    this.setState({
      uploading: true
    });

    // You can use any AJAX library you like
    axios({
      url: "/library/upload_video",
      method: "post",
      processData: false,
      data: formData,
      onUploadProgress: progressEvent => {
        this.setState({
          percentCompleted: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
        });
      }
    })
      .then(res => {
        if (res.data.success === 1) {
          this.setState({
            uploading: false
          });
          if (this.props.onChoose) {
            this.props.onChoose(res.data.media);
          }
        } else {
          message.error(res.data.msg);
        }
      })
      .catch(e => message.error(e.message));
  };

  render() {
    const props = {
      accept: ".mp4,.gif",
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList
          };
        });
      },
      beforeUpload: file => {
        if (file.type === "video/mp4" || file.type === "image/gif") {
          this.setState(state => ({
            fileList: [file],
            percentCompleted: 0
          }));
          return false;
        } else {
          message.error("Only MP4 videos and GIFs are allowed to be uploaded!");
        }
      },
      fileList: this.state.fileList
    };
    return (
      <div className="upload-media">
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            <b>
              Click or drag MP4 video or GIF to this area to upload (Max. 50MB)
            </b>
          </p>
        </Dragger>
        {(this.state.uploading || this.state.percentCompleted === 100) && (
          <Progress percent={this.state.percentCompleted} />
        )}
        <p style={{ margin: "1em 0 0.5em 0" }}>
          <b>Clip Video</b>
        </p>
        <Row gutter={30}>
          <Col xs={12}>
            <Input
              type="number"
              placeholder="Start Time"
              addonAfter="Seconds"
              style={{ width: "100%" }}
              value={this.state.start}
              onChange={e => this.setState({ start: e.target.value })}
            />
          </Col>
          <Col xs={12}>
            <Input
              type="number"
              placeholder="End Time"
              addonAfter="Seconds"
              style={{ width: "100%" }}
              value={this.state.end}
              onChange={e => this.setState({ end: e.target.value })}
            />
          </Col>
        </Row>
        <div
          className={`ant-form-item-control ${
            this.state.error ? "has-error" : ""
          }`}
        >
          {this.state.error && (
            <div className="ant-form-explain">{this.state.error}</div>
          )}
        </div>

        {this.state.uploading && (
          <Alert
            style={{ marginTop: 15 }}
            message={
              <span>
                Please wait while the video is being uploaded. This window will
                close automatically once the upload is complete.{" "}
                <b>
                  Please do not close this window or your upload will get
                  cancelled!
                </b>
              </span>
            }
            type="warning"
          />
        )}
        <Row>
          <Button
            onClick={() => this.handleUpload()}
            className="btn-green"
            size="large"
            style={{ width: "100%", margin: "1em 0 0 0" }}
            loading={this.state.uploading}
          >
            Start Upload
          </Button>
        </Row>
      </div>
    );
  }
}

export default UploadVideo;
