import React, { Component } from "react";
import { Input, Select, DatePicker, Row, Button, message, Col } from "antd";
import { set, merge } from "lodash";
import moment from "moment";
import ColorPicker from "../ColorPicker";
const { Option } = Select;

class HellobarTimerElement extends Component {
  state = {
    saving: false,
    element: {
      element_name: "",
      element_category: "",
      element_type: "hellobar_timer",
      element_group: "conversion_elements",
      element_domid: this.props.match.params.domid,
      data: {
        expiry: "",
        expirytz: "",
        hellobartext: "",
        labels: {
          days: "Days",
          hours: "Hours",
          minutes: "Minutes",
          seconds: "Seconds"
        },
        text: {
          r: "255",
          g: "255",
          b: "255"
        },
        bg: {
          r: "0",
          g: "0",
          b: "0"
        }
      }
    },
    errors: {}
  };

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      });
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.expiry.trim().length === 0) {
      errors.expiry = "This is a required field!";
    }
    if (element.data.expirytz.trim().length === 0) {
      errors.expirytz = "This is a required field!";
    }
    if (element.data.hellobartext.trim().length === 0) {
      errors.hellobartext = "This is a required field!";
    }
    if (element.data.labels.days.trim().length === 0) {
      errors.label_days = "This is a required field!";
    }
    if (element.data.labels.hours.trim().length === 0) {
      errors.label_hours = "This is a required field!";
    }
    if (element.data.labels.minutes.trim().length === 0) {
      errors.label_minutes = "This is a required field!";
    }
    if (element.data.labels.seconds.trim().length === 0) {
      errors.label_seconds = "This is a required field!";
    }

    if (Object.keys(errors).length === 0) {
      this.setState(
        {
          errors: {},
          saving: true
        },
        () => {
          if (this.props.saveElement) {
            this.props.saveElement(this.state.element, res => {
              this.setState({
                saving: false
              });
              if (res.data.success !== 1) {
                message.error(res.data.msg);
              }
            });
          }
        }
      );
    } else {
      this.setState({ errors });
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => (c = set(c, pv.path, pv.value)));
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState(
      {
        element: c
      },
      () => this.props.onUpdate(this.state.element)
    );
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div
          className={`ant-form-item-control ${
            this.state.errors.element_name ? "has-error" : ""
          }`}
        >
          <p className="form-labels">
            Name
            <Input
              value={this.state.element.element_name}
              onChange={e => {
                this.updateField("element_name", e.target.value);
              }}
            />
          </p>
          {this.state.errors.element_name && (
            <div className="ant-form-explain">
              {this.state.errors.element_name}
            </div>
          )}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={
              this.state.element.element_category
                ? this.state.element.element_category
                : []
            }
            onChange={val => {
              this.updateField("element_category", val[val.length - 1]);
            }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.props.categories.map(c => (
              <Option key={c._id} value={c._id}>
                {c._id}
              </Option>
            ))}
          </Select>
        </div>

        <div
          className={`ant-form-item-control ${
            this.state.errors.expiry ? "has-error" : ""
          }`}
        >
          <p className="form-labels">Expiry</p>
          <DatePicker
            disabledDate={current =>
              current &&
              current <
                moment()
                  .subtract(1, "days")
                  .endOf("day")
            }
            showTime
            inputReadOnly
            style={{ width: "100%" }}
            format="YYYY-MM-DD HH:mm:ss"
            value={
              this.state.element.data.expiry
                ? moment(this.state.element.data.expiry)
                : null
            }
            onChange={date => {
              this.updateField(
                "data.expiry",
                date != null ? date.format("YYYY-MM-DD HH:mm:ss") : ""
              );
            }}
          />
          {this.state.errors.expiry && (
            <div className="ant-form-explain">{this.state.errors.expiry}</div>
          )}
        </div>

        <div
          className={`ant-form-item-control ${
            this.state.errors.expirytz ? "has-error" : ""
          }`}
        >
          <p className="form-labels">Timezone</p>
          <Select
            value={
              this.state.element.data.expirytz
                ? this.state.element.data.expirytz
                : []
            }
            onChange={val => {
              this.updateField("data.expirytz", val);
            }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.keys(this.props.timezones).map((timezone, i) => (
              <Option
                value={`${timezone}|${this.props.timezones[timezone]}`}
                key={i}
              >
                {timezone}
              </Option>
            ))}
          </Select>
          {this.state.errors.expirytz && (
            <div className="ant-form-explain">{this.state.errors.expirytz}</div>
          )}
        </div>

        <div
          className={`ant-form-item-control ${
            this.state.errors.hellobartext ? "has-error" : ""
          }`}
        >
          <p className="form-labels">
            Hellobar Text
            <Input
              value={this.state.element.data.hellobartext}
              onChange={e => {
                this.updateField("data.hellobartext", e.target.value);
              }}
            />
          </p>
          {this.state.errors.hellobartext && (
            <div className="ant-form-explain">
              {this.state.errors.hellobartext}
            </div>
          )}
        </div>

        <div
          className={`ant-form-item-control ${
            this.state.errors.label_days ? "has-error" : ""
          }`}
        >
          <p className="form-labels">
            Label for Days Field
            <Input
              value={this.state.element.data.labels.days}
              onChange={e => {
                this.updateField("data.labels.days", e.target.value);
              }}
            />
          </p>
          {this.state.errors.label_days && (
            <div className="ant-form-explain">
              {this.state.errors.label_days}
            </div>
          )}
        </div>

        <div
          className={`ant-form-item-control ${
            this.state.errors.label_hours ? "has-error" : ""
          }`}
        >
          <p className="form-labels">
            Label for Hours Field
            <Input
              value={this.state.element.data.labels.hours}
              onChange={e => {
                this.updateField("data.labels.hours", e.target.value);
              }}
            />
          </p>
          {this.state.errors.label_hours && (
            <div className="ant-form-explain">
              {this.state.errors.label_hours}
            </div>
          )}
        </div>

        <div
          className={`ant-form-item-control ${
            this.state.errors.label_minutes ? "has-error" : ""
          }`}
        >
          <p className="form-labels">
            Label for Minutes Field
            <Input
              value={this.state.element.data.labels.minutes}
              onChange={e => {
                this.updateField("data.labels.minutes", e.target.value);
              }}
            />
          </p>
          {this.state.errors.label_minutes && (
            <div className="ant-form-explain">
              {this.state.errors.label_minutes}
            </div>
          )}
        </div>

        <div
          className={`ant-form-item-control ${
            this.state.errors.label_seconds ? "has-error" : ""
          }`}
        >
          <p className="form-labels">
            Label for Seconds Field
            <Input
              value={this.state.element.data.labels.seconds}
              onChange={e => {
                this.updateField("data.labels.seconds", e.target.value);
              }}
            />
          </p>
          {this.state.errors.label_seconds && (
            <div className="ant-form-explain">
              {this.state.errors.label_seconds}
            </div>
          )}
        </div>

        <Row>
          <Col xs={12}>
            <ColorPicker
              format="rgb"
              hideInput
              label="Text Color"
              defaultValue={this.state.element.data.text}
              onChange={color => this.updateField("data.text", color)}
            />
          </Col>
          <Col xs={12}>
            <ColorPicker
              format="rgb"
              hideInput
              label="Background Color"
              defaultValue={this.state.element.data.bg}
              onChange={color => this.updateField("data.bg", color)}
            />
          </Col>
        </Row>

        <Row>
          <Button
            className="btn-green"
            size="large"
            onClick={() => this.saveElement()}
            loading={this.state.saving}
          >
            Save
          </Button>
          <Button
            type="danger"
            onClick={() => this.props.onCancel()}
            size="large"
          >
            Cancel
          </Button>
        </Row>
      </form>
    );
  }
}

export default HellobarTimerElement;
