import Axios from "axios";

export const getLinks = (domid, page, pageSize, order) => {
    return Axios({
        url: '/links/get_links',
        method: 'post',
        data: {
            domid,
            page,
            pageSize,
            order
        }
    })
};

export const newLink = (domid) => {
    return Axios({
        url: '/links/new_link',
        method: 'post',
        data: {
            domid,
        }
    })
};

export const saveLink = (link, domid) => {
    return Axios({
        url: '/links/save_link',
        method: 'post',
        data: {
            domid,
            link
        }
    })
};

export const getLinkAnalytics = (domid, linkid, startDate, endDate) => {
    return Axios({
        url: '/links/get_link_analytics',
        method: 'post',
        data: {
            domid,
            linkid,
            startDate,
            endDate
        }
    }).then((res) => {
        return {
            analytics: res.data.analytics,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};