import React, { Component } from 'react';
import { Input, Select, Row, Col, Button, message, DatePicker, TimePicker, Icon } from 'antd';
import { set, merge } from 'lodash';
import moment from 'moment';
const { Option } = Select;

class GroupEventElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'group_event_poll',
      element_group: 'self_interacting_elements',
      element_domid: this.props.match.params.domid,
      data: {
        title: "",
        description: "",
        helptext: "Pick the options that work for you below or vote on the web.",
        location: "",
        expirytz: "",
        options: [
          {
            id: Date.now(),
            date: "",
            starttime: "",
            endtime: ""
          }
        ]
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      let element = merge(this.state.element, this.props.element);
      if (element.data.options.length) {
        element.data.options = element.data.options.map((o) => ({ ...o, id: Date.now() }))
      }
      this.setState({
        element
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.title.trim().length === 0) {
      errors.title = "This is a required field!";
    }
    if (element.data.description.trim().length === 0) {
      errors.description = "This is a required field!";
    }
    if (element.data.helptext.trim().length === 0) {
      errors.helptext = "This is a required field!";
    }
    if (element.data.location.trim().length === 0) {
      errors.location = "This is a required field!";
    }
    if (element.data.expirytz.trim().length === 0) {
      errors.timezone = "This is a required field!";
    }
    if (element.data.options.length === 0) {
      errors.options_zero = "You must add atlease one option for this poll!";
    } else {
      element.data.options.forEach((a) => {
        if (a.date.trim().length === 0) {
          errors[`option_${a.id}`] = "This is a required field!";
        }
      })
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
            this.setState({
              saving: false
            })
            if (res.data.success !== 1) {
              message.error(res.data.msg);
            }
          })
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  addAnswer() {
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          options: [{ id: Date.now(), date: '', starttime: '', endtime: '' }, ...this.state.element.data.options]
        }
      }
    }, () => this.props.onUpdate(this.state.element))
  }

  updateAnswer(id, field, value) {
    let options = this.state.element.data.options.map((a) => {
      if (a.id === id) {
        return { ...a, [field]: value }
      } else {
        return a;
      }
    })
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          options
        }
      }
    }, () => this.props.onUpdate(this.state.element))
  }

  removeAnswer(id) {
    let options = this.state.element.data.options.filter((a) => a.id !== id);
    if (options.length === 0) {
      options.push({
        id: Date.now(),
        answer: '',
        url: ''
      })
    }
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          options
        }
      }
    }, () => this.props.onUpdate(this.state.element))
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>
        <div className={`ant-form-item-control ${this.state.errors.title ? 'has-error' : ''}`}>
          <p className="form-labels">Title
          <Input
              value={this.state.element.data.title}
              onChange={(e) => { this.updateField("data.title", e.target.value) }} />
          </p>
          {this.state.errors.title && <div className="ant-form-explain">{this.state.errors.title}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.description ? 'has-error' : ''}`}>
          <p className="form-labels">Description
          <Input.TextArea
              value={this.state.element.data.description}
              onChange={(e) => { this.updateField("data.description", e.target.value) }} />
          </p>
          {this.state.errors.description && <div className="ant-form-explain">{this.state.errors.description}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.helptext ? 'has-error' : ''}`}>
          <p className="form-labels">Help Text
          <Input.TextArea
              value={this.state.element.data.helptext}
              onChange={(e) => { this.updateField("data.helptext", e.target.value) }} />
          </p>
          {this.state.errors.helptext && <div className="ant-form-explain">{this.state.errors.helptext}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.location ? 'has-error' : ''}`}>
          <p className="form-labels">Location
          <Input
              value={this.state.element.data.location}
              onChange={(e) => { this.updateField("data.location", e.target.value) }} />
          </p>
          {this.state.errors.location && <div className="ant-form-explain">{this.state.errors.location}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Timezone</p>
          <Select
            value={this.state.element.data.expirytz}
            onChange={(val) => { this.updateField("data.expirytz", val) }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {Object.keys(this.props.timezones).map((timezone, i) => <Option value={`${timezone}|${this.props.timezones[timezone]}`} key={i}>{timezone}</Option>)}
          </Select>
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Options (<a href="#/" onClick={() => this.addAnswer()}>Add</a>)</p>
        </div>
        {this.state.element.data.options.map(a => {
          return (
            <div className="poll-option" key={a.id} >
              <a href="#/" className="poll-option-remove" onClick={() => this.removeAnswer(a.id)}><Icon type="close" /></a>
              <Row>
                <Col xs={8}>
                  <div className={`ant-form-item-control ${this.state.errors[`option_${a.id}`] ? 'has-error' : ''}`}>
                    <p className="form-labels">Date</p>
                    <DatePicker
                      disabledDate={(current) => current && current < moment().subtract(1, "days").endOf('day')}
                      inputReadOnly
                      format="YYYY-MM-DD"
                      value={a.date ? moment(a.date) : null}
                      onChange={(date) => { this.updateAnswer(a.id, 'date', date !== null ? date.format("YYYY-MM-DD") : null) }}
                    />
                    {this.state.errors[`option_${a.id}`] && <div className="ant-form-explain">{this.state.errors[`option_${a.id}`]}</div>}
                  </div>
                </Col>
                <Col xs={8}>
                  <div className={`ant-form-item-control`}>
                    <p className="form-labels">Start Time (Optional)</p>
                    <TimePicker
                      inputReadOnly
                      format="HH:mm"
                      value={a.starttime ? moment(a.starttime, "HH:mm") : null}
                      onChange={(date) => { this.updateAnswer(a.id, 'starttime', date !== null ? date.format("HH:mm") : null) }}
                    />
                  </div>
                </Col>
                <Col xs={8}>
                  <div className={`ant-form-item-control`}>
                    <p className="form-labels">End Time (Optional)</p>
                    <TimePicker
                      inputReadOnly
                      format="HH:mm"
                      value={a.endtime ? moment(a.endtime, "HH:mm") : null}
                      onChange={(date) => { this.updateAnswer(a.id, 'endtime', date !== null ? date.format("HH:mm") : null) }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )
        })}

        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button>
        </Row>
      </form>
    )
  }

}

export default GroupEventElement;