import React, { Component } from 'react';
import step1Img from '../../../../assets/images/next_steps/1.png'
import step2Img from '../../../../assets/images/next_steps/2.png'
import step3Img from '../../../../assets/images/next_steps/3.png'
import step4Img from '../../../../assets/images/next_steps/4.png'
import { Row, Col, Card } from "antd";

class NextSteps extends Component {
  render() {
    return <div className="howitworks" style={{ marginTop: 30 }}>
      <Row><h2>How It Works</h2></Row>
      <Row gutter={15}>
        <Col xs={6}>
          <Card>
            <img src={step1Img} alt="Step 1" style={{ maxWidth: "100%" }} />
            <p style={{ textAlign: "center", marginTop: 10 }}>
              <b>Customise the Element</b><br />
              Choose the type of element that you want to create.
          </p>
          </Card>
        </Col>
        <Col xs={6}>
          <Card>
            <img src={step2Img} alt="Step 2" style={{ maxWidth: "100%" }} />
            <p style={{ textAlign: "center", marginTop: 10 }}>
              <b>Copy the code</b><br />
              Simply copy the resultant code from the dashboard.
          </p>
          </Card>
        </Col>
        <Col xs={6}>
          <Card>
            <img src={step3Img} alt="Step 3" style={{ maxWidth: "100%" }} />
            <p style={{ textAlign: "center", marginTop: 10 }}>
              <b>Place the code</b><br />
              Place the code in the desired location.
          </p>
          </Card>
        </Col>
        <Col xs={6}>
          <Card>
            <img src={step4Img} alt="Step 4" style={{ maxWidth: "100%" }} />
            <p style={{ textAlign: "center", marginTop: 10 }}>
              <b>View Detailed Reporting</b><br />
              View and analyse the detailed reporting of the element.
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  }
}

export default NextSteps;