import React from "react";
import { Alert } from "antd";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./index.scss";

const Exhausted = props => {
  const { domain } = props.auth;
  let description =
    "The monthly unique impression limit allotted for this account has been exhausted. Please contact your account owner to increase the impressions.";
  if (domain.role === "owner") {
    description = (
      <span>
        The monthly unique impressons limit allotted for this account has been
        exhausted. Please go to your{" "}
        <Link to="/manage-accounts">Manage account</Link> page and increase the
        impressions or upgrade your plan.
      </span>
    );
  }
  return (
    <div className="account-deactivated">
      <MainHeadingBar title="Account Limited" hideCTA {...props} />
      <Alert
        message="Warning"
        description={description}
        type="warning"
        showIcon
      />
    </div>
  );
};
const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};
export default connect(mapStateToProps)(Exhausted);
