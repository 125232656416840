import React, { Component } from 'react';
import { Input, Select, Switch, Row, Button, message, Tooltip, Icon, Radio } from 'antd';
import { set, merge } from 'lodash';
import { ReactComponent as StartRatingIcon } from '../../../../../assets/images/element_icons/star_ratings.svg';
import { ReactComponent as EmojiRatingIcon } from '../../../../../assets/images/element_icons/emoji_rating.svg';
import { ReactComponent as ScaleRatingIcon } from '../../../../../assets/images/element_icons/scale_rating.svg';
import { ReactComponent as ThumbsRatingIcon } from '../../../../../assets/images/element_icons/thumbs_up_down.svg';
const { Option } = Select;

class FeedbackElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'feedback',
      element_group: 'self_interacting_elements',
      element_domid: this.props.match.params.domid,
      data: {
        question: "",
        type: "star",
        customurlenabled: "0",
        redirect_urls: {
          "-1": "",
          "0": "",
          "1": "",
        },
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.question.trim().length === 0) {
      errors.question = "This is a required field!";
    }
    if (element.data.type.trim().length === 0) {
      errors.type = "This is a required field!";
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
            this.setState({
              saving: false
            })
            if (res.data.success !== 1) {
              message.error(res.data.msg);
            }
          })
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.question ? 'has-error' : ''}`}>
          <p className="form-labels">Question
          <Input
              value={this.state.element.data.question}
              onChange={(e) => { this.updateField("data.question", e.target.value) }} />
          </p>
          {this.state.errors.question && <div className="ant-form-explain">{this.state.errors.question}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.type ? 'has-error' : ''}`}>
          <p className="form-labels">Choose Template</p>
          <Radio.Group onChange={(e) => this.updateField("data.type", e.target.value)} value={this.state.element.data.type}>
            <Radio.Button value="star"><p><StartRatingIcon style={{ width: 75, height: 75 }} /> <br /> Star</p></Radio.Button>
            <Radio.Button value="emoji"><p><EmojiRatingIcon style={{ width: 75, height: 75 }} /> <br /> Emoji</p></Radio.Button>
            <Radio.Button value="rating"><p><ScaleRatingIcon style={{ width: 75, height: 75 }} /> <br />Rating</p></Radio.Button>
            <Radio.Button value="thumbs"><p><ThumbsRatingIcon style={{ width: 75, height: 75 }} /> <br />Thumbs</p></Radio.Button>
          </Radio.Group>
          {this.state.errors.type && <div className="ant-form-explain">{this.state.errors.type}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Enable Custom Thank You URL &nbsp;&nbsp;&nbsp;
            <Switch
              disabled={!(this.props.plan && this.props.plan.plan_features && this.props.plan.plan_features.CUSTOM_URLS === 1)}
              checked={this.state.element.data.customurlenabled === '1' || this.state.element.data.customurlenabled === 1}
              onChange={(checked) => { this.updateField("data.customurlenabled", checked ? '1' : '0') }} />

            {!(this.props.plan && this.props.plan.plan_features && this.props.plan.plan_features.CUSTOM_URLS === 1) && <Tooltip title="Your plan does not allow you to add a Custom Thank You URL!"><Icon type="info-circle" style={{ marginLeft: 15, verticalAlign: 'middle' }} /></Tooltip>}
          </p>
        </div>
        {(this.state.element.data.customurlenabled === '1' || this.state.element.data.customurlenabled === 1) && <div className="url-settings indented">
          <div className={`ant-form-item-control ${this.state.errors.negative_url ? 'has-error' : ''}`}>
            <p className="form-labels">Custom Thank You URL for Negative Feedback <Tooltip title="You can choose to leave this field empty, in the case of which the default submission confirmation page will open"><Icon type="info-circle" /></Tooltip>
              <Input
                value={this.state.element.data.redirect_urls["-1"]}
                onChange={(e) => { this.updateField("data.redirect_urls['-1']", e.target.value) }}
                onBlur={(e) => { this.updateField("data.redirect_urls['-1']", e.target.value.trim().length === 0 || e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
            </p>
            {this.state.errors.negative_url && <div className="ant-form-explain">{this.state.errors.negative_url}</div>}
          </div>
          <div className={`ant-form-item-control ${this.state.errors.neutral_url ? 'has-error' : ''}`}>
            <p className="form-labels">Custom Thank You URL for Neutral Feedback <Tooltip title="You can choose to leave this field empty, in the case of which the default submission confirmation page will open"><Icon type="info-circle" /></Tooltip>
              <Input
                value={this.state.element.data.redirect_urls["0"]}
                onChange={(e) => { this.updateField("data.redirect_urls['0']", e.target.value) }}
                onBlur={(e) => { this.updateField("data.redirect_urls['0']", e.target.value.trim().length === 0 || e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
            </p>
            {this.state.errors.neutral_url && <div className="ant-form-explain">{this.state.errors.neutral_url}</div>}
          </div>
          <div className={`ant-form-item-control ${this.state.errors.positive_url ? 'has-error' : ''}`}>
            <p className="form-labels">Custom Thank You URL for Positive Feedback <Tooltip title="You can choose to leave this field empty, in the case of which the default submission confirmation page will open"><Icon type="info-circle" /></Tooltip>
              <Input
                value={this.state.element.data.redirect_urls["1"]}
                onChange={(e) => { this.updateField("data.redirect_urls['1']", e.target.value) }}
                onBlur={(e) => { this.updateField("data.redirect_urls['1']", e.target.value.trim().length === 0 || e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
            </p>
            {this.state.errors.positive_url && <div className="ant-form-explain">{this.state.errors.positive_url}</div>}
          </div>
        </div>}

        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button>
        </Row>
      </form>
    )
  }

}

export default FeedbackElement;