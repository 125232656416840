import React, { Component } from 'react';
import { Input, Select, Row, Button, message, Icon } from 'antd';
import { set, merge } from 'lodash';
const { Option } = Select;

class TwitterTimelineElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'twitter_timeline',
      element_group: 'growth_elements',
      element_domid: this.props.match.params.domid,
      data: {
        handle: "",
        count: 1,
        scraped_data: {}
      }
    },
    scraping: false,
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.handle === undefined) {
      errors.handle = "This is a required field!";
    }
    if (!element.data.scraped_data.name) {
      errors.scrape = "There was an error fetching the tweets!";
    }
    if (!element.data.count) {
      errors.count = "Please enter a valid number!";
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
  this.setState({
    saving: false
  })
  if (res.data.success !== 1) {
    message.error(res.data.msg);
  }
})
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  scrapeURL() {
    if (!this.state.scraping) {
      const handle = this.state.element.data.handle;
      const count = this.state.element.data.count;
      if (handle && count) {
        this.setState({
          scraping: true
        }, () => {
          this.props.scrapeTwitterURL({ screen_name: handle, count }, (res) => {
            if (res.error) {
              this.setState({
                scraping: false,
                errors: { ...this.state.errors, scrape: 'There was an error scraping this link!' }
              })
            } else {
              this.setState({
                scraping: false,
                element: {
                  ...this.state.element,
                  data: {
                    ...this.state.element.data,
                    scraped_data: res
                  }
                }
              }, () => this.props.onUpdate(this.state.element));
            }
          })
        })
      }
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>
        <div className={`ant-form-item-control ${this.state.errors.handle ? 'has-error' : ''}`}>
          <p className="form-labels"> Twitter Handle
          <Input
              value={this.state.element.data.handle}
              onChange={(e) => { this.updateField("data.handle", e.target.value) }}
              onBlur={(e) => this.scrapeURL()} />
          </p>
          {this.state.errors.handle && <div className="ant-form-explain">{this.state.errors.handle}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.count ? 'has-error' : ''}`}>
          <p className="form-labels"> How many tweets to show
          <Input
              type="number"
              min={1}
              value={this.state.element.data.count}
              onChange={(e) => { this.updateField("data.count", e.target.value) }}
              onBlur={(e) => this.scrapeURL()} />
          </p>
          {this.state.errors.count && <div className="ant-form-explain">{this.state.errors.count}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.scrape ? 'has-error' : ''}`}>
          {this.state.errors.scrape && <div className="ant-form-explain">{this.state.errors.scrape}</div>}
        </div>

        <Row>
          {this.state.scraping && <p>Scraping Link <Icon type="loading" /></p>}
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} disabled={this.state.scraping}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large" disabled={this.state.scraping}>Cancel</Button> &nbsp;
  </Row>
      </form>
    )
  }

}

export default TwitterTimelineElement;