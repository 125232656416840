import React from "react";
import "./index.scss";
import Pieslice from "../../Partials/Pieslice";

const PlatformWiseStats = props => {
  const { platform_wise_clicks } = props;

  return (
    <Pieslice
      data={platform_wise_clicks}
      category="platform"
      type={props.type}
    />
  );
};

export default PlatformWiseStats;
