import React, { Component } from "react";
import "./index.scss";
import { Row, Col } from "antd";
import axios from "axios";

class MailMasterySessions extends Component {
  state = {
    sessions: []
  };

  componentDidMount() {
    axios.post("/user/mail_mastery_sessions", {}).then(res => {
      this.setState({
        sessions: res.data
      });
    });
  }

  render() {
    return (
      <div
        className="mail-mastery-sessions"
        style={{ textAlign: "center", marginTop: 30 }}
      >
        <h1 style={{ fontSize: "35px", color: "#08a362" }}>
          Mail Mastery LIVE Sessions
        </h1>
        <p>
          <b>MailMastery</b> is a LIVE training where we will train the users on
          the <b>360-degrees of email marketing</b>. <br />
          We will also expose the users to the{" "}
          <b>best-kept in-house REAL EMAIL hacks</b> & tips that we ourselves
          use on a daily basis.
        </p>

        <Row gutter={20}>
          {this.state.sessions.map((s, i) => {
            return (
              <Col md={8} style={{ margin: "30px 0" }}>
                <div>
                  <h3>Session #{i + 1}</h3>
                  <p style={{ minHeight: 42 }}>{s.title}</p>
                  {s.link.length > 0 ? (
                    <iframe
                      width="100%"
                      style={{ minHeight: 212 }}
                      src={s.link}
                      title={s.title}
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    />
                  ) : (
                    <div
                      style={{
                        background: "black",
                        color: "white",
                        height: "0",
                        paddingTop: "56.25%"
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          top: "60%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          margin: "0",
                          fontSize: "30px"
                        }}
                      >
                        Coming Soon
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default MailMasterySessions;
