import React, { Component } from "react";
import "./index.scss";
import MainHeadingBar from "../Partials/MainHeadingBar";
import { Card, Skeleton, Row, Alert, Col, Icon, DatePicker } from "antd";
import HourWiseStats from "./HourWiseStats";
import { getElementAnalytics } from "../../../helpers/ajax/elementsAjax";
import DayWiseStats from "./DaywiseStats";
import BrowserWiseStats from "./BrowserWiseStats";
import PlatformWiseStats from "./PlatFormWiseStats";
import DeviceWiseStats from "./DeviceWiseStats";
import CountryWiseStats from "./CountryWiseStats";
import ElementCard from "../Elements/ElementCard";
import Records from "./Records";
import RecordsEmail from "./RecordsEmail";
import RecordsClicks from "./RecordsClicks";
import moment from "moment";
const { RangePicker } = DatePicker;

class CampaignReport extends Component {
  state = {
    error: false,
    loading: true,
    errorMsg: "",
    type: "views",
    filters: {
      startDate: moment().subtract(1, "months"),
      endDate: moment()
    },
    dayWiseLoading: false,
    element: undefined,
    analytics: {
      camp_created: "",
      camp_name: "",

      hour_wise_clicks: {},
      day_wise_clicks: {},
      browser_wise_clicks: {},
      platform_wise_clicks: {},
      device_wise_clicks: {},
      country_wise_clicks: {}
    }
  };

  componentDidMount = () => {
    const { domid, campid } = this.props.match.params;

    getElementAnalytics(
      domid,
      campid,
      this.state.type,
      this.state.filters.startDate.format("YYYY-MM-DD"),
      this.state.filters.endDate.format("YYYY-MM-DD")
    ).then(res => {
      this.setState(res);
    });
  };

  onDateChange(date) {
    this.setState(
      {
        filters: {
          startDate: date[0],
          endDate: date[1]
        },
        dayWiseLoading: true
      },
      () => {
        getElementAnalytics(
          this.props.match.params.domid,
          this.props.match.params.campid,
          this.state.type,
          this.state.filters.startDate.format("YYYY-MM-DD"),
          this.state.filters.endDate.format("YYYY-MM-DD")
        ).then(res => {
          this.setState({
            dayWiseLoading: false,
            analytics: {
              ...this.state.analytics,
              day_wise_clicks: res.analytics.day_wise_clicks,
              day_wise_visitors: res.analytics.day_wise_visitors
            }
          });
        });
      }
    );
  }

  toggleStats = () => {
    this.setState(
      state => ({
        type: state.type === "views" ? "clicks" : "views",
        loading: true
      }),
      () => {
        getElementAnalytics(
          this.props.match.params.domid,
          this.props.match.params.campid,
          this.state.type,
          this.state.filters.startDate.format("YYYY-MM-DD"),
          this.state.filters.endDate.format("YYYY-MM-DD")
        ).then(res => {
          this.setState(res);
        });
      }
    );
  };

  render() {
    const { loading, error, errorMsg, analytics } = this.state;
    const PageWrapper = children => {
      return (
        <div className="campaign-report">
          <MainHeadingBar
            title={`Campaign Report ${this.state.analytics.camp_name &&
              "(" + this.state.analytics.camp_name + ")"}`}
            {...this.props}
            pageCTA={
              <span>
                <a
                  href="#/"
                  className="ant-btn btn-default"
                  onClick={() => this.props.history.goBack()}
                >
                  <Icon type="left" /> Back
                </a>
              </span>
            }
          />
          {children}
        </div>
      );
    };

    if (loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Card>
            <Skeleton active />
          </Card>
        </div>
      );
    }

    if (!loading && error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    return PageWrapper(
      <div>
        <Row className="elements">
          <Col xs={8} offset={8}>
            {this.state.analytics.element && (
              <ElementCard hideReport element={this.state.analytics.element} />
            )}
          </Col>
        </Row>

        <Row className="records">
          <Records {...this.props} element={this.state.analytics.element} />
          <RecordsEmail
            {...this.props}
            element={this.state.analytics.element}
          />
          <RecordsClicks
            {...this.props}
            element={this.state.analytics.element}
          />
        </Row>

        <Row>
          <h3>
            Showing {this.state.type === "views" ? "Impression" : "Click"}{" "}
            Statistics (
            <a href="#!" onClick={this.toggleStats}>
              View {this.state.type === "views" ? "Clicks" : "Impression"}{" "}
              Statistics
            </a>
            )
          </h3>
        </Row>

        <Card className="hourwise-stats-card">
          <HourWiseStats
            type={this.state.type}
            hour_wise_visitors={analytics.hour_wise_visitors}
            hour_wise_clicks={analytics.hour_wise_clicks}
          />
        </Card>

        <Card className="hourwise-stats-card">
          <DayWiseStats
            addons={
              <RangePicker
                defaultValue={[
                  this.state.filters.startDate,
                  this.state.filters.endDate
                ]}
                format="YYYY-MM-DD"
                onChange={date => this.onDateChange(date)}
                allowClear={false}
                ranges={{
                  Today: [moment(), moment()],
                  "This Week": [
                    moment().startOf("week"),
                    moment().endOf("week")
                  ],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment()
                      .subtract(1, "month")
                      .startOf("month"),
                    moment()
                      .subtract(1, "month")
                      .endOf("month")
                  ],
                  Overall: [
                    moment(
                      moment(this.state.analytics.camp_created),
                      "YYYY-MM-DD"
                    ),
                    moment()
                  ]
                }}
              />
            }
            loading={this.state.dayWiseLoading}
            day_wise_visitors={analytics.day_wise_visitors}
            day_wise_clicks={analytics.day_wise_clicks}
            type={this.state.type}
          />
        </Card>

        <Row gutter={30} className="pie-chart-cards">
          <Col xs={8} style={{ display: "flex" }}>
            <Card
              className="browserwise-stats-card"
              title="Browser-wise Stats"
              style={{ width: "100%" }}
            >
              <BrowserWiseStats
                type={this.state.type}
                browser_wise_visitors={analytics.browser_wise_visitors}
                browser_wise_clicks={analytics.browser_wise_clicks}
              />
            </Card>
          </Col>
          <Col xs={8} style={{ display: "flex" }}>
            <Card
              className="platformwise-stats-card"
              title="Platform-wise Stats"
              style={{ width: "100%" }}
            >
              <PlatformWiseStats
                type={this.state.type}
                platform_wise_visitors={analytics.platform_wise_visitors}
                platform_wise_clicks={analytics.platform_wise_clicks}
              />
            </Card>
          </Col>
          <Col xs={8} style={{ display: "flex" }}>
            <Card
              className="devicewise-stats-card"
              title="Device-wise Stats"
              style={{ width: "100%" }}
            >
              <DeviceWiseStats
                type={this.state.type}
                device_wise_visitors={analytics.device_wise_visitors}
                device_wise_clicks={analytics.device_wise_clicks}
              />
            </Card>
          </Col>
        </Row>

        <Card className="countrywise-stats-card">
          <CountryWiseStats
            type={this.state.type}
            country_wise_visitors={analytics.country_wise_visitors}
            country_wise_clicks={analytics.country_wise_clicks}
          />
        </Card>
      </div>
    );
  }
}

export default CampaignReport;
