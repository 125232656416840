import React, { Component } from 'react';
import { Input, Select, Row, Col, Button, message, Slider, Icon, Alert } from 'antd';
import FormattingField from '../../../Partials/FormattingField';
import { set, merge } from 'lodash';
import ColorPicker from '../ColorPicker';
import { CDN_URL, BASE_URL } from '../../../../../config';
import { Rnd } from 'react-rnd';
const { Option } = Select;

class PersonalisedImageElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'personalized_images',
      element_group: 'conversion_elements',
      element_domid: this.props.match.params.domid,
      data: {
        media_id: "",
        image: "",
        image_link: "",
        text: "[FNAME]",
        font: 'Roboto-Regular.ttf',
        size: "31",
        color: {
          r: "255",
          g: "255",
          b: "255"
        },
        position: {
          x: "0",
          y: "0",
        },
        lineheight: "",
        wordwrap: "",
        actual: {
          width: "",
          height: ""
        },
        screen: {
          width: "",
          height: ""
        },
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.text.trim().length === 0) {
      errors.text = "This is a required field!";
    }
    if (element.data.media_id.trim().length === 0) {
      errors.media_id = "This is a required field!";
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
            this.setState({
              saving: false
            })
            if (res.data.success !== 1) {
              message.error(res.data.msg);
            }
          })
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  onChoose(media) {
    let media_link = '';
    if (media.media_type === "link") {
      media_link = `${BASE_URL}uploads/links/${media.media_filename}`;
    } else if (media.media_type === "image") {
      media_link = `${BASE_URL}uploads/images/${media.media_filename}`;
    } else {
      if (media.processed === 1 || media.processed === '1') {
        if (media.media_type === "video") {
          media_link = `${CDN_URL}images/gifs/${media._id}.gif`;
        } else {
          media_link = `${CDN_URL}images/thumbs/${media._id}.png`;
        }
      } else {
        media_link = `${CDN_URL}global/images/processing.gif`;
      }
    }
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          media_id: media._id,
          image: `${media.media_filename}`,
          image_link: media_link
        }
      }
    }, () => { this.updateImageProps() })
    this.props.toggleLibraryModal();
  }

  onDrag(data) {
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          position: {
            x: data.x,
            y: data.y,
          }
        }
      }
    }, () => this.updateImageProps())
  }

  getLineHeight(element) {
    let temp = document.createElement(element.nodeName);
    temp.setAttribute("style", "margin:0px;padding:0px;font-family:" + element.style.fontFamily + ";font-size:" + element.style.fontSize);
    temp.innerHTML = "test";
    temp = element.parentNode.appendChild(temp);
    const lineHeight = temp.clientHeight;
    temp.parentNode.removeChild(temp);
    return lineHeight;
  }

  getWordWrapCount(element) {
    element.innerHTML = element.innerText.split(" ").map((i) => "<span>" + i + "</span>").join(" ")
    let counts = {};
    let wordCount = {};
    Array.from(element.querySelectorAll("span")).forEach((e) => {
      counts[e.offsetTop] !== undefined ? counts[e.offsetTop] += e.innerText.length : counts[e.offsetTop] = e.innerText.length;
      wordCount[e.offsetTop] !== undefined ? wordCount[e.offsetTop]++ : wordCount[e.offsetTop] = 1;
    });

    Object.keys(wordCount).forEach(row => {
      counts[row] += wordCount[row] - 1;
    });

    element.innerHTML = element.innerText;
    return Math.max.apply(Math, Object.values(counts));
  }

  updateImageProps() {
    const lineHeight = this.getLineHeight(this.refs.text);

    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          actual: {
            width: this.refs.image.naturalWidth,
            height: this.refs.image.naturalHeight,
          },
          screen: {
            width: this.refs.image.width,
            height: this.refs.image.height,
          },
          lineheight: lineHeight,
          wordwrap: this.getWordWrapCount(this.refs.text)
        }
      }
    }, () => this.props.onUpdate(this.state.element));
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.media_id ? 'has-error' : ''}`}>
          <p className="form-labels">Choose Image</p>
          {this.state.errors.media_id && <div className="ant-form-explain">{this.state.errors.media_id}</div>}
        </div>

        <div className="upload-actions">
          <div className="upload-action" onClick={() => this.props.toggleLibraryModal("image", (media) => this.onChoose(media), 'upload')}>
            <p>
              <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
              Upload
            </p>
          </div>
          <div className="upload-action" onClick={() => this.props.toggleLibraryModal("image", (media) => this.onChoose(media), 'library')}>
            <p>
              <Icon type="folder-open" style={{ color: "#D9D9D9" }} /> <br />
              Library
            </p>
          </div>
        </div>
        <div className="image-customize" style={{ position: "relative", marginTop: "30px", display: this.state.element.data.image_link.length > 0 ? 'inline-block' : 'none' }}>
          <React.Fragment>
            <Rnd
              position={{
                x: parseInt(this.state.element.data.position.x),
                y: parseInt(this.state.element.data.position.y),
              }}
              bounds="parent"
              style={{ border: `1px solid rgb(${this.state.element.data.color.r}, ${this.state.element.data.color.g}, ${this.state.element.data.color.b})` }}
              onDragStop={(_, data) => this.onDrag(data)}
              onResizeStop={() => this.updateImageProps()}
            >
              <p ref="text" style={{
                color: `rgb(${this.state.element.data.color.r}, ${this.state.element.data.color.g}, ${this.state.element.data.color.b})`,
                fontSize: `${this.state.element.data.size}px`,
                margin: 0,
                fontFamily: `'${this.state.element.data.font}'`
              }}>{this.state.element.data.text}</p>
            </Rnd>
            <img ref="image" src={`${this.state.element.data.image_link}`} alt="" style={{ width: '100%', height: '100%', maxWidth: "500px" }} />
          </React.Fragment>
        </div>

        <FormattingField
          label="Text"
          error={this.state.errors.text}
          field={(props) => <Input placeholder="" {...props} />}
          personalizations={[
            { tag: '[FNAME]', title: 'Full Name' },
          ]}
          value={this.state.element.data.text}
          onChange={(val) => this.updateField("data.text", val)} />

        <Alert type="info" message="We suggest you to replace [FNAME] with the personalisation tag of your own Autoresponder." style={{ marginTop: 10 }} />

        <div className="ant-form-item-control">
          <p className="form-labels">Font</p>
          <Select
            value={this.state.element.data.font}
            onChange={(val) => { this.updateField("data.font", val) }}
            style={{ fontFamily: `'${this.state.element.data.font}'` }}
            placeholder="Select a font"
          >
            <Option value="Roboto-Regular.ttf"><div style={{ fontFamily: `'Roboto-Regular.ttf'` }}>Roboto Regular</div></Option>
            <Option value="Roboto-Medium.ttf"><div style={{ fontFamily: `'Roboto-Medium.ttf'` }}>Roboto Medium</div></Option>
            <Option value="Arimo-Bold.ttf"><div style={{ fontFamily: `'Arimo-Bold.ttf'` }}>Arimo Bold</div></Option>
            <Option value="Arimo-Regular.ttf"><div style={{ fontFamily: `'Arimo-Regular.ttf'` }}>Arimo Regular</div></Option>
            <Option value="OpenSans-Bold.ttf"><div style={{ fontFamily: `'OpenSans-Bold.ttf'` }}>OpenSans Bold</div></Option>
            <Option value="OpenSans-Regular.ttf"><div style={{ fontFamily: `'OpenSans-Regular.ttf'` }}>OpenSans Regular</div></Option>
            <Option value="Quicksand-Medium.ttf"><div style={{ fontFamily: `'Quicksand-Medium.ttf'` }}>Quicksand Medium</div></Option>
            <Option value="Quicksand-Regular.ttf"><div style={{ fontFamily: `'Quicksand-Regular.ttf'` }}>Quicksand Regular</div></Option>
            <Option value="Raleway-Medium.ttf"><div style={{ fontFamily: `'Raleway-Medium.ttf'` }}>Raleway Medium</div></Option>
            <Option value="Raleway-Regular.ttf"><div style={{ fontFamily: `'Raleway-Regular.ttf'` }}>Raleway Regular</div></Option>
            <Option value="font1.ttf"><div style={{ fontFamily: `'font1.ttf'` }}>Tom's Handwriting</div></Option>
            <Option value="font2.ttf">< div style={{ fontFamily: `'font2.ttf'` }}>Attract more women</div></Option>
            <Option value="font3.ttf"><div style={{ fontFamily: `'font3.ttf'` }}>Mulder's handwriting</div></Option>
            <Option value="font4.ttf"><div style={{ fontFamily: `'font4.ttf'` }}>Wolves</div></Option>
            <Option value="font5.ttf"><div style={{ fontFamily: `'font5.ttf'` }}>Attack of the Cucumbers</div></Option>
            <Option value="font6.ttf"><div style={{ fontFamily: `'font6.ttf'` }}>A Sensible Armadillo</div></Option>
            <Option value="font7.ttf"><div style={{ fontFamily: `'font7.ttf'` }}>WC Mano Negra Bta</div></Option>
            <Option value="font8.ttf"><div style={{ fontFamily: `'font8.ttf'` }}>GoodDog Plain</div></Option>
          </Select>
        </div>

        <Row>
          <Col xs={12}><ColorPicker hideInput format="rgb" label="Text Color" defaultValue={this.state.element.data.color} onChange={(color) => this.updateField("data.color", color)} /></Col>
        </Row>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Size</p>
          <Slider defaultValue={parseInt(this.state.element.data.size)} min={5} max={75} onChange={(value) => this.updateField("data.size", value)} />
        </div>


        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button> &nbsp;
        </Row>
      </form>
    )
  }

}

export default PersonalisedImageElement;