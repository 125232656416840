import React, { Component } from "react";
import { Modal, message, Icon, Tooltip, Alert } from "antd";
import { ReactComponent as LivePreviewIcon } from "../../../../../assets/images/live-preview-arrow.svg";
import { Link } from "react-router-dom";
import ClickToCopy from "../../../Partials/ClickToCopy";
import AutoplayVideoElement from "../AutoplayVideoElement";
import PersonalisedVideoElement from "../PersonalisedVideoElement";
import MessengerPersonalisedVideoElement from "../MessengerPersonalisedVideoElement";
import YesNoElement from "../YesNoElement";
import FeedbackElement from "../FeedbackElement";
import PollsElement from "../PollsElement";
import QNAElement from "../QNAElement";
import GroupEventElement from "../GroupEventElement";
import TimerElement from "../TimerElement";
import HellobarTimerElement from "../HellobarTimerElement";
import PersonalisedImageElement from "../PersonalisedImageElement";
import MessengerPersonalisedImageElement from "../MessengerPersonalisedImageElement";
import LinkPreviewElement from "../LinkPreviewElement";
import CTAElement from "../CTAElement";
import FooterWidgetElement from "../FooterWidgetElement";
import SignatureElement from "../SignatureElement";
import SocialBadgesElement from "../SocialBadgesElement";
import SocialShareElement from "../SocialShareElement";
import SelfDestructMessageElement from "../SelfDestructMessageElement";
import TwitterEmbedElement from "../TwitterEmbedElement";
import YoutubeEmbedElement from "../YoutubeEmbedElement";
import VimeoEmbedElement from "../VimeoEmbedElement";
import MapEmbedElement from "../MapEmbedElement";
import InstagramEmbedElement from "../InstagramEmbedElement";
import PinterestEmbedElement from "../PinterestEmbedElement";
import TwitterTimelineElement from "../TwitterTimelineElement";
import GIFEmbedElement from "../GIFEmbedElement";
import ImageEmbedElement from "../ImageEmbedElement";
import ScarcityBarElement from "../ScarcityBarElement";
import ElementPreview from "../../../Elements/ElementPreview";
import CopyAutoresponderCodeModal from "../../../Elements/CopyAutoresponderCodeModal";
import { debounce } from "lodash";
import "./index.scss";
import {
  saveElement,
  getPreview,
} from "../../../../../helpers/ajax/elementsAjax";

class ElementFormContainer extends Component {
  state = {
    successModal: {
      visible: false,
      code: "",
      messengerlink: "",
    },
    autoresponderModal: {
      visible: false,
    },
    element: { previewString: "" },
    loadingPreview: true,
  };

  componentDidUpdate(props) {
    if (props.visible !== this.props.visible && !this.props.visible) {
      this.setState({ element: { previewString: "" } });
    }
  }

  toggleAutoresponderModal() {
    this.setState({
      autoresponderModal: { visible: !this.state.autoresponderModal.visible },
    });
  }

  onSaveElement(element, callback) {
    saveElement(this.props.match.params.domid, element)
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            successModal: {
              ...this.state.successModal,
              code: res.data.code,
              messengerlink: res.data.messengerlink,
              id: res.data.id,
              visible: true,
            },
            element: { ...element, previewString: res.data.code },
          });
        }
        callback(res);
      })
      .catch((e) => {
        message.error(e.message);
      });
  }

  onUpdate = debounce((element) => {
    let clearPreview = false;
    if (
      element.element_type === "youtube_embed" &&
      !element.data.link.match(
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      )
    ) {
      clearPreview = true;
    }
    if (
      element.element_type === "vimeo_embed" &&
      !element.data.link.match(
        /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/
      )
    ) {
      clearPreview = true;
    }
    if (
      element.element_type === "instagram_embed" &&
      !element.data.link.match(/https:\/\/www\.instagram.com\/p\/(\w+)\/?/)
    ) {
      clearPreview = true;
    }
    if (
      element.element_type === "pinterest_embed" &&
      !element.data.link.match(
        /https:\/\/([\w]+\.)?pinterest.com\/pin\/(\d+)\/?(\?.*)?/
      )
    ) {
      clearPreview = true;
    }
    if (
      element.element_type === "twitter_tweet" &&
      !element.data.link.match(
        /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\/.*)?$/
      )
    ) {
      clearPreview = true;
    }
    if (
      element.data.link &&
      (element.data.scraped_data === undefined ||
        element.data.scraped_data.image === undefined ||
        element.data.scraped_data.image === "")
    ) {
      clearPreview = true;
    }
    if (clearPreview) {
      this.setState({
        element: { previewString: "" },
      });
    } else {
      this.setState(
        {
          loadingPreview: true,
        },
        () => {
          getPreview(element).then((res) => {
            element.previewString = res.data;
            this.setState({
              loadingPreview: false,
              element,
            });
          });
        }
      );
    }
  }, 1000);

  render() {
    const hidePreview = ["PersonalisedImageElement"];
    const formTitles = {
      AutoplayVideoElement: "Autoplay Video with Mute Button",
      PersonalisedVideoElement: "Personalised Autoplay Video",
      MessengerPersonalisedVideoElement:
        "Personalised Autoplay Video for Messenger",
      YesNoElement: "Yes / No Bar",
      FeedbackElement: "Feedback",
      PollsElement: "Polls",
      QNAElement: "QnA",
      GroupEventElement: "Group Event Poll",
      TimerElement: "Timer",
      HellobarTimerElement: "Hellobar Timer",
      PersonalisedImageElement: "Personalised Image",
      MessengerPersonalisedImageElement: "Personalised Image for Messenger",
      LinkPreviewElement: "Link Preview",
      CTAElement: "CTA Button",
      FooterWidgetElement: "Footer Widget",
      SignatureElement: "Signature",
      SocialBadgesElement: "Social Badges",
      SocialShareElement: "Social Share",
      SelfDestructMessageElement: `"Self-Desctructing" Message`,
      TwitterEmbedElement: "Twitter Tweet Embed",
      YoutubeEmbedElement: "Youtube Embed",
      VimeoEmbedElement: "Vimeo Embed",
      MapEmbedElement: "Google Map Embed",
      InstagramEmbedElement: "Instagram Embed",
      PinterestEmbedElement: "Pinterest Embed",
      TwitterTimelineElement: "Twitter Timeline Embed",
      GIFEmbedElement: "GIF Embed",
      ImageEmbedElement: "Image Embed",
      ScarcityBarElement: "Scarcity Bar",
    };
    return (
      <React.Fragment>
        <div
          className={`preview-sidebar ${this.props.visible ? "visible" : ""}`}
        >
          {hidePreview.indexOf(this.props.type) === -1 && (
            <div className="live-preview">
              <div
                style={{
                  display: "block",
                  margin: "auto",
                  textAlign: "center",
                  color: "white",
                  padding: "30px",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                }}
              >
                <LivePreviewIcon />{" "}
                <span className="circle-ripple" style={{ marginTop: 10 }} />
              </div>
              {this.state.loadingPreview && (
                <div
                  style={{
                    color: "white",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  Loading <Icon type="loading" />
                </div>
              )}
              {this.props.visible && (
                <ElementPreview ref="preview" element={this.state.element} />
              )}
            </div>
          )}
        </div>
        <div
          className={`element-form-container ${
            this.props.visible ? "visible" : ""
          }`}
        >
          <a href="#/" onClick={() => this.props.onCancel()}>
            <Icon
              type="close-circle"
              style={{ position: "fixed", top: 20, right: 20, fontSize: 20 }}
            />
          </a>
          <div className="form-container">
            {this.props.type === "AutoplayVideoElement" && (
              <AutoplayVideoElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "PersonalisedVideoElement" && (
              <PersonalisedVideoElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "MessengerPersonalisedVideoElement" && (
              <MessengerPersonalisedVideoElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "YesNoElement" && (
              <YesNoElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "FeedbackElement" && (
              <FeedbackElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "PollsElement" && (
              <PollsElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "QNAElement" && (
              <QNAElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "GroupEventElement" && (
              <GroupEventElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "TimerElement" && (
              <TimerElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "HellobarTimerElement" && (
              <HellobarTimerElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "PersonalisedImageElement" && (
              <PersonalisedImageElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "MessengerPersonalisedImageElement" && (
              <MessengerPersonalisedImageElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "LinkPreviewElement" && (
              <LinkPreviewElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "CTAElement" && (
              <CTAElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "FooterWidgetElement" && (
              <FooterWidgetElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "SignatureElement" && (
              <SignatureElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "SocialBadgesElement" && (
              <SocialBadgesElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "SocialShareElement" && (
              <SocialShareElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "SelfDestructMessageElement" && (
              <SelfDestructMessageElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "TwitterEmbedElement" && (
              <TwitterEmbedElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "YoutubeEmbedElement" && (
              <YoutubeEmbedElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "VimeoEmbedElement" && (
              <VimeoEmbedElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "MapEmbedElement" && (
              <MapEmbedElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "InstagramEmbedElement" && (
              <InstagramEmbedElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "PinterestEmbedElement" && (
              <PinterestEmbedElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "TwitterTimelineElement" && (
              <TwitterTimelineElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "GIFEmbedElement" && (
              <GIFEmbedElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "ImageEmbedElement" && (
              <ImageEmbedElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
            {this.props.type === "ScarcityBarElement" && (
              <ScarcityBarElement
                {...this.props}
                formTitle={formTitles[this.props.type]}
                onUpdate={(e) => this.onUpdate(e)}
                saveElement={(element, callback) =>
                  this.onSaveElement(element, callback)
                }
              />
            )}
          </div>
        </div>

        <Modal
          title={null}
          footer={null}
          closable={false}
          visible={this.state.successModal.visible}
        >
          <div style={{ textAlign: "center" }}>
            <Icon
              type="check-circle"
              theme="filled"
              style={{ color: "#36C626", fontSize: "100px" }}
            />
            <h2 style={{ margin: "30px 0 0 0" }}>Success</h2>
            <p>Element has been created successfully!</p>

            {this.state.element.element_group === "autoplay_video" ? (
              <Alert
                type="info"
                message="The element video is still under processing. You can get the code for the element once the processing is complete!"
              />
            ) : this.state.element.element_group === "messenger_elements" ? (
              <React.Fragment>
                <p>
                  <ClickToCopy
                    textToCopy={`https://${
                      this.state.successModal.messengerlink
                    }/main/${
                      this.state.element.element_type ===
                      "messenger_personalised_video"
                        ? "getmessengervideo"
                        : "getmessengerimage"
                    }?elemid=${this.state.successModal.id}`}
                  >
                    <Icon type="copy" /> Copy ManyChat Link
                  </ClickToCopy>
                </p>

                <p>
                  <ClickToCopy
                    textToCopy={`https://${
                      this.state.successModal.messengerlink
                    }/main/${
                      this.state.element.element_type ===
                      "messenger_personalised_video"
                        ? "getactivechatvideo"
                        : "getactivechatimage"
                    }/${this.state.successModal.id}`}
                  >
                    <Icon type="copy" /> Copy ActiveChat Link
                  </ClickToCopy>
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p>
                  <ClickToCopy textToCopy={this.state.successModal.code}>
                    <Icon type="copy" /> COPY HTML CODE
                  </ClickToCopy>
                  <Tooltip
                    placement="right"
                    title="Use This Function To Copy The HTML Source Code To Place It In Your AutoResponder Emails"
                  >
                    <Icon type="info-circle" />
                  </Tooltip>
                </p>
                <p>
                  <a href="#/" onClick={() => this.toggleAutoresponderModal()}>
                    <Icon type="copy" /> COPY AUTORESPONDER CODE
                  </a>
                  <Tooltip
                    placement="right"
                    title="Use This Function To Copy The HTML Source Code To Place It In Your AutoResponder Emails"
                  >
                    <Icon type="info-circle" />
                  </Tooltip>
                </p>
                {[
                  "qna",
                  "group_event_poll",
                  "personalised_video",
                  "personalized_image",
                ].indexOf(this.state.element.element_type) === -1 && (
                  <p>
                    <a
                      href="#/"
                      onClick={() => this.refs.preview.copyElementPreview()}
                    >
                      <Icon type="copy" /> COPY ELEMENT PREVIEW
                    </a>
                    <Tooltip
                      placement="right"
                      title="Use This Function To Directly Copy The Element Final Preview To Place It In Your Normal Email Service Provider Compose Email Box e.g. in Gmail."
                    >
                      <Icon type="info-circle" />
                    </Tooltip>
                  </p>
                )}
              </React.Fragment>
            )}
            <br />
            <br />
            <Link
              to={`/${this.props.match.params.domid}/elements`}
              className="ant-btn ant-btn-lg btn-green"
            >
              Okay
            </Link>
          </div>
        </Modal>
        <CopyAutoresponderCodeModal
          onCancel={() => this.toggleAutoresponderModal()}
          visible={this.state.autoresponderModal.visible}
          code={this.state.successModal.code}
        />
      </React.Fragment>
    );
  }
}

export default ElementFormContainer;
