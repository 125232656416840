import React, { Component } from 'react';
import { Input, Select, Button, message, Icon, Row } from 'antd';
import { CDN_URL, BASE_URL } from '../../../../../config';
import { set, merge } from 'lodash';
const { Option } = Select;

class SignatureElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'signature',
      element_group: 'conversion_elements',
      element_domid: this.props.match.params.domid,
      data: {
        media_id: "",
        image_link: "",
        name: "",
        position: "",
        email: "",
        phone: ""
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.media_id.trim().length === 0) {
      errors.media_id = "This is a required field!";
    }
    if (element.data.name.trim().length === 0) {
      errors.name = "This is a required field!";
    }
    if (element.data.position.trim().length === 0) {
      errors.position = "This is a required field!";
    }
    // eslint-disable-next-line
    if (element.data.email.trim().length > 0 && !element.data.email.trim().match(new RegExp("([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+"))) {
      errors.email = "Please enter a valid Email!";
    }
    let phone = element.data.phone.replace(/\s/g, '').trim();
    if (phone.length > 0 && !phone.match(/^\+?\d{10,}$/)) {
      errors.phone = "Please enter a valid phone number!";
    }


    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
            this.setState({
              saving: false
            })
            if (res.data.success !== 1) {
              message.error(res.data.msg);
            }
          })
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  onChoose(media) {
    let media_link = '';
    if (media.media_type === "link") {
      media_link = `${BASE_URL}uploads/links/${media.media_filename}`;
    } else if (media.media_type === "image") {
      media_link = `${BASE_URL}uploads/images/${media.media_filename}`;
    } else {
      if (media.processed === 1 || media.processed === '1') {
        if (media.media_type === "video") {
          media_link = `${CDN_URL}images/gifs/${media._id}.gif`;
        } else {
          media_link = `${CDN_URL}images/thumbs/${media._id}.png`;
        }
      } else {
        media_link = `${CDN_URL}global/images/processing.gif`;
      }
    }
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          media_id: media._id,
          image_link: `${media_link}`
        }
      }
    }, () => this.props.onUpdate(this.state.element));
    this.props.toggleLibraryModal();
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.media_id ? 'has-error' : ''}`}>
          <p className="form-labels">Choose Image</p>
          {this.state.errors.media_id && <div className="ant-form-explain">{this.state.errors.media_id}</div>}
        </div>

        <div className="upload-actions">
          {this.state.element.data.image_link.length > 0 &&
            <div className="upload-action">
              <img src={`${this.state.element.data.image_link}`} alt="" style={{ width: '100%', height: '100%' }} />
            </div>
          }
          <div className="upload-action" onClick={() => this.props.toggleLibraryModal("image", (media) => this.onChoose(media), 'upload')}>
            <p>
              <Icon type="upload" style={{ color: "#D9D9D9" }} /> <br />
              Upload
            </p>
          </div>
          <div className="upload-action" onClick={() => this.props.toggleLibraryModal("image", (media) => this.onChoose(media), 'library')}>
            <p>
              <Icon type="folder-open" style={{ color: "#D9D9D9" }} /> <br />
              Library
            </p>
          </div>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.data.name}
              onChange={(e) => { this.updateField("data.name", e.target.value) }} />
          </p>
          {this.state.errors.name && <div className="ant-form-explain">{this.state.errors.name}</div>}
        </div>
        <div className={`ant-form-item-control ${this.state.errors.position ? 'has-error' : ''}`}>
          <p className="form-labels">Position
          <Input
              value={this.state.element.data.position}
              onChange={(e) => { this.updateField("data.position", e.target.value) }} />
          </p>
          {this.state.errors.position && <div className="ant-form-explain">{this.state.errors.position}</div>}
        </div>
        <div className={`ant-form-item-control ${this.state.errors.email ? 'has-error' : ''}`}>
          <p className="form-labels">Email
          <Input
              value={this.state.element.data.email}
              onChange={(e) => { this.updateField("data.email", e.target.value) }} />
          </p>
          {this.state.errors.email && <div className="ant-form-explain">{this.state.errors.email}</div>}
        </div>
        <div className={`ant-form-item-control ${this.state.errors.phone ? 'has-error' : ''}`}>
          <p className="form-labels">Phone
          <Input
              value={this.state.element.data.phone}
              onChange={(e) => { this.updateField("data.phone", e.target.value) }} />
          </p>
          {this.state.errors.phone && <div className="ant-form-explain">{this.state.errors.phone}</div>}
        </div>

        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button>
        </Row>
      </form>
    )
  }

}

export default SignatureElement;