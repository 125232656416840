import React, { Component } from "react";
import "./index.scss";
import { Tabs } from "antd";
import AccountSettings from "./AccountSettings";
import UserSettings from "./UserSettings";
import MainHeadingBar from "../Partials/MainHeadingBar";
import WhitelabelDomains from "./WhitelabelDomains";
import { connect } from "react-redux";

const { TabPane } = Tabs;

class Settings extends Component {
  state = {
    activeTab:
      this.props.activeTab &&
      ["1", "2", "3"].indexOf(this.props.activeTab) !== -1
        ? this.props.activeTab
        : "1"
  };

  shouldComponentUpdate(np) {
    this.setState();
    return true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab) {
      this.setState({
        activeTab: this.props.activeTab
      });
    }
  }
  componentDid;

  render() {
    const role = this.props.auth.user.user_domains[this.props.auth.domain.id][
      "role"
    ];
    return (
      <div className="settings">
        <MainHeadingBar title="Settings" {...this.props} />
        <Tabs
          tabPosition="left"
          activeKey={this.state.activeTab}
          onChange={ac => this.setState({ activeTab: ac })}
        >
          <TabPane tab="Account Settings" key="1">
            <AccountSettings />
          </TabPane>
          {role === "owner" &&
            (this.props.auth.plan.plan_features.USER_COUNT === -1 ||
              this.props.auth.plan.plan_features.USER_COUNT > 0) && (
              <TabPane tab="Users" key="2">
                <UserSettings />
              </TabPane>
            )}
          <TabPane tab="Custom Domains" key="3">
            <WhitelabelDomains />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};

export default connect(mapStateToProps)(Settings);
