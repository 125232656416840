import Axios from "axios";

export const getLibraryMedia = (domid, limit, offset, type, onlydom) => {
    return Axios({
        url: '/library/get_media',
        method: 'post',
        data: {
            onlydom,
            domid,
            limit,
            offset,
            type
        }
    }).then((res) => {
        return {
            media: res.data.media,
            total: res.data.total,
            loading: false,
        }
    }).catch((e) => {
        return {
            error: true,
            errorMsg: e.message,
            loading: false
        }
    });
};

export const searchMedia = (type, domid, search, page, per_page, next) => {
    return Axios({
        url: '/library/search_media',
        method: 'post',
        data: {
            type,
            domid,
            search,
            page,
            per_page,
            next
        }
    })
};
export const fetchYouzign = (domid, page, pageCount) => {
    return Axios({
        url: '/library/fetch_youzign',
        method: 'post',
        data: {
            domid,
            page,
            pageCount
        }
    }).then((res) => {
        return res;
    })
};

export const bulkFetch = (domid, urls) => {
    return Axios({
        url: '/library/fetch_media',
        method: 'post',
        data: {
            domid,
            urls
        }
    }).then((res) => {
        return res;
    })
};

export const deleteLibraryMedia = (domid, ids) => {
    return Axios({
        url: '/library/delete_media',
        method: 'post',
        data: {
            domid,
            ids
        }
    }).then((res) => {
        return res;
    })
};

export const saveYouzignKeys = (domid, publicKey, token) => {
    return Axios({
        url: '/library/save_youzign_keys',
        method: 'post',
        data: {
            domid,
            publicKey,
            token
        }
    })
};

export const removeYouzign = (domid) => {
    return Axios({
        url: '/library/remove_youzign',
        method: 'post',
        data: {
            domid,
        }
    })
};

export const fetchVideo = (domid, url, type, start, end) => {
    return Axios({
        url: '/library/fetch_video',
        method: 'post',
        data: {
            domid,
            url,
            type,
            start,
            end
        }
    })
}