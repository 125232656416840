import React, { Component } from 'react';
import { Input, Select, Row, Col, Button, message, Tooltip, Icon } from 'antd';
import { set, merge } from 'lodash';
import ColorPicker from '../ColorPicker';
const { Option } = Select;

class PollsElement extends Component {

  state = {
    saving: false,
    element: {
      element_name: '',
      element_category: '',
      element_type: 'polls',
      element_group: 'self_interacting_elements',
      element_domid: this.props.match.params.domid,
      data: {
        question: '',
        answers: [{
          id: Date.now(),
          answer: '',
          url: ''
        }],
        votetextcolor: "#fff",
        votebgcolor: "#0000ff"
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.question.trim().length === 0) {
      errors.question = "This is a required field!";
    }
    if (element.data.answers.length === 0) {
      errors.answers_zero = "You must add atlease one option for this poll!";
    } else {
      element.data.answers.forEach((a) => {
        if (a.answer.trim().length === 0) {
          errors[`answer_${a.id}`] = "This is a required field!";
        }
        if (a.url.trim().length > 0 && !a.url.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
          errors[`answer_${a.id}_url`] = "Please enter a valid URL!";
        }
      })
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
  this.setState({
    saving: false
  })
  if (res.data.success !== 1) {
    message.error(res.data.msg);
  }
})
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  addAnswer() {
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          answers: [...this.state.element.data.answers, { id: Date.now(), answer: '', url: '' }]
        }
      }
    }, () => this.props.onUpdate(this.state.element))
  }

  updateAnswer(id, field, value) {
    let answers = this.state.element.data.answers.map((a) => {
      if (a.id === id) {
        return { ...a, [field]: value }
      } else {
        return a;
      }
    })
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          answers
        }
      }
    }, () => this.props.onUpdate(this.state.element))
  }

  removeAnswer(id) {
    let answers = this.state.element.data.answers.filter((a) => a.id !== id);
    if (answers.length === 0) {
      answers.push({
        id: Date.now(),
        answer: '',
        url: ''
      })
    }
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          answers
        }
      }
    }, () => this.props.onUpdate(this.state.element))
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>
        <div className={`ant-form-item-control ${this.state.errors.question ? 'has-error' : ''}`}>
          <p className="form-labels">Question
          <Input
              value={this.state.element.data.question}
              onChange={(e) => { this.updateField("data.question", e.target.value) }} />
          </p>
          {this.state.errors.question && <div className="ant-form-explain">{this.state.errors.question}</div>}
        </div>


        <div className={`ant-form-item-control`}>
          <p className="form-labels">Answers (<a href="#/" onClick={() => this.addAnswer()}>Add</a>)</p>
        </div>
        {this.state.element.data.answers.map(a => {
          return (
            <div className="poll-option" key={a.id} >
              <a href="#/" className="poll-option-remove" onClick={() => this.removeAnswer(a.id)}><Icon type="close" /></a>
              <div className={`ant-form-item-control ${this.state.errors[`answer_${a.id}`] ? 'has-error' : ''}`}>
                <p className="form-labels">Option
                <Input
                    value={a.answer}
                    onChange={(e) => { this.updateAnswer(a.id, 'answer', e.target.value) }} />
                </p>
                {this.state.errors[`answer_${a.id}`] && <div className="ant-form-explain">{this.state.errors[`answer_${a.id}`]}</div>}
              </div>
              <div className={`ant-form-item-control ${this.state.errors[`answer_${a.id}_url`] ? 'has-error' : ''}`}>
                <p className="form-labels">URL to redirect to for this option <Tooltip title="You can choose to leave this field empty, in the case of which the default submission confirmation page will open"><Icon type="info-circle" /></Tooltip>
                  <Input
                    value={a.url}
                    onChange={(e) => { this.updateAnswer(a.id, 'url', e.target.value) }}
                    onBlur={(e) => { this.updateAnswer(a.id, 'url', e.target.value.trim().length > 0 && !e.target.value.match(/^https?:\/\/.*/) ? `http://${e.target.value.trim()}` : e.target.value.trim()) }} />
                </p>
                {this.state.errors[`answer_${a.id}_url`] && <div className="ant-form-explain">{this.state.errors[`answer_${a.id}_url`]}</div>}
              </div>
            </div>
          )
        })}

        <Row>
          <Col xs={12}><ColorPicker label="Vote Button Text Color" defaultValue={this.state.element.data.votetextcolor} onChange={(color) => this.updateField("data.votetextcolor", color)} /></Col>
          <Col xs={12}><ColorPicker label="Vote Button Background Color" defaultValue={this.state.element.data.votebgcolor} onChange={(color) => this.updateField("data.votebgcolor", color)} /></Col>
        </Row>

        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button>
        </Row>
      </form>
    )
  }

}

export default PollsElement;