import React, { Component } from 'react';
import './index.scss';
import { Card, Button, Icon, Row, Col, message, Modal, Checkbox, Pagination, Skeleton, Alert, Tabs, Dropdown, Menu } from 'antd';
import { getLibraryMedia, deleteLibraryMedia } from '../../../helpers/ajax/libraryAjax';
import { ReactComponent as NoDataIcon } from '../../../assets/images/icons/no_data_found.svg';
import { CDN_URL, BASE_URL } from '../../../config';
import MainHeadingBar from '../Partials/MainHeadingBar';
import LibraryModal from './LibraryModal';
import moment from 'moment';
const { Meta } = Card;
const TabPane = Tabs.TabPane;

class LibraryViewer extends Component {
    state = {
        selectedMedia: [],
        media: [],
        total: 0,
        loading: true,
        error: false,
        errorMsg: '',
        type: this.props.type,
        paging: {
            pageSize: 12,
            page: 1
        },
    };

    reloadViewer() {
        this.setState({
            media: [],
            selectedMedia: [],
            paging: {
                pageSize: 12,
                page: 1
            },
        }, () => this.fetchLibraryMedia());
    }

    onSelectChange = (selectedMedia) => {
        this.setState({ selectedMedia });
    };

    fetchLibraryMedia() {
        getLibraryMedia(
            this.props.match.params.domid,
            this.state.paging.pageSize,
            (this.state.paging.page - 1) * this.state.paging.pageSize,
            this.state.type,
            true
        ).then(res => this.setState(res));
    }

    componentDidMount() {
        this.fetchLibraryMedia();
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;

        this.setState({
            media: [],
            loading: true,
            paging: {
                ...this.state.pagination,
                page: pager.current
            }
        }, () => this.fetchLibraryMedia());
    };

    deleteSelectedMedia(ids) {
        deleteLibraryMedia(this.props.match.params.domid, ids)
            .then((res) => {
                if (res.data.success === 1) {
                    this.setState({
                        media: this.state.media.filter((r) => ids.indexOf(r._id) === -1)
                    })
                    message.success("Image(s) deleted successfully!");
                } else {
                    message.error(res.data.msg);
                }
            }).catch(e => {
                message.error(e.message);
            })
    }

    confirmDelete(ids) {
        const that = this;
        Modal.confirm({
            title: 'Are you sure',
            content: 'Are you sure you want to delete the selected image(s). Once deleted, the image(s) cannot be recovered',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                that.deleteSelectedMedia(ids);
            }
        });
    }

    onSelect(id) {
        const checked = this.state.selectedMedia.findIndex((m) => m === id) > -1 ? false : true;
        if (checked) {
            this.setState({
                selectedMedia: [...this.state.selectedMedia, id]
            })
        } else {
            this.setState({
                selectedMedia: this.state.selectedMedia.filter((m) => m !== id)
            })
        }
    }

    selectAll(checked) {
        if (checked) {
            this.setState({
                selectedMedia: this.state.media.map((m) => m._id)
            })
        } else {
            this.setState({
                selectedMedia: []
            })
        }
    }

    goToPage(page) {
        this.setState({
            media: [],
            selectedMedia: [],
            loading: true,
            paging: {
                ...this.state.paging,
                page
            }
        }, () => this.fetchLibraryMedia());
    }

    toggleLibraryModal(type = "image") {
        this.props.toggleLibraryModal(type);
    }

    render() {
        if (this.state.loading) {
            return <Skeleton active />
        }

        if (!this.state.loading && this.state.error) {
            return <Alert
                message="Error"
                description={this.state.errorMsg}
                type="error"
                showIcon
            />
        }

        if (this.state.media.length === 0) {
            return <Row style={{ textAlign: "center" }}>
                <NoDataIcon />
                <p style={{ margin: 0 }}>No Images Found!</p>
            </Row>
        }

        return <React.Fragment>
            <Row style={{ marginBottom: "2.5rem" }}>
                <Checkbox
                    defaultChecked={this.state.selectedMedia.length === this.state.media}
                    indeterminate={this.state.selectedMedia.length > 0 && this.state.selectedMedia.length < this.state.media.length}
                    onChange={(e) => this.selectAll(e.target.checked)}> Select All</Checkbox>
                {this.state.selectedMedia.length > 0 &&
                    <Button
                        style={{ background: "#F84B3D", color: "white", marginLeft: "1rem" }}
                        onClick={() => this.confirmDelete(this.state.selectedMedia)}>Delete Selected</Button>}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {this.state.media.map(media => {
                    let media_link = '';
                    if (media.media_type === "link") {
                        media_link = `${BASE_URL}uploads/links/${media.media_filename}`;
                    } else if (media.media_type === "image") {
                        media_link = `${BASE_URL}uploads/images/${media.media_filename}`;
                    } else if (media.media_type === "gifs") {
                        media_link = `${media.media_url}`;
                    } else {
                        if (media.processed === 1 || media.processed === '1') {
                            if (media.media_type === "video") {
                                media_link = `${CDN_URL}images/gifs/${media._id}.gif`;
                            } else {
                                media_link = `${CDN_URL}images/thumbs/${media._id}.png`;
                            }
                        } else {
                            media_link = `${CDN_URL}global/images/processing.gif`;
                        }
                    }
                    return (
                        <Col xs={24} sm={12} md={6} key={media._id}>
                            <Card
                                style={{ margin: "0 auto 2.9rem auto", cursor: "pointer" }}
                                cover={<div style={{
                                    background: `url(${media_link})`,
                                    height: "100%",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }} />}
                                onClick={() => this.onSelect(media._id)}
                            >
                                {this.state.selectedMedia.indexOf(media._id) !== -1 && <div className="selected-overlay"></div>}
                                <Meta
                                    title={
                                        <div className="media-meta">
                                            <p>{moment(media.media_created).format("MMM DD, YYYY")}</p>
                                            <Checkbox size="large" checked={this.state.selectedMedia.indexOf(media._id) !== -1}></Checkbox>
                                        </div>
                                    }
                                />
                            </Card>
                        </Col>
                    )
                })}
            </Row>
            <Row style={{ textAlign: 'right' }}>
                <Pagination current={this.state.paging.page} pageSize={this.state.paging.pageSize} onChange={(page) => this.goToPage(page)} total={this.state.total} />
            </Row>
        </React.Fragment>
    }
}

class Library extends Component {
    state = {
        libraryModal: {
            visible: false,
            type: "image"
        }
    }

    toggleLibraryModal(type = 'image') {
        this.setState({
            libraryModal: {
                visible: !this.state.libraryModal.visible,
                type
            }
        })
    }

    uploadSuccess() {
        if (this.state.libraryModal.type === "image") {
            this.refs.imageViewer.reloadViewer();
        } else if (this.state.libraryModal.type === "videonew") {
            this.refs.videoViewer.reloadViewer();
        }
        this.toggleLibraryModal();
    }

    render() {
        return (
            <div className="library">
                <MainHeadingBar title="Library" helpText="Here you can manage all your images." pageCTA={
                    <span>
                        <Dropdown overlay={
                            <Menu>
                                <Menu.Item key="1" onClick={() => this.toggleLibraryModal("image")}>Image</Menu.Item>
                                <Menu.Item key="2" onClick={() => this.toggleLibraryModal("videonew")}>Video</Menu.Item>
                            </Menu>}>
                            <Button size="large">
                                <Icon type="plus" /> Upload <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </span>} {...this.props} />

                <Card>
                    <Tabs defaultActiveKey="1">
                        <TabPane forceRender tab="Images" key="1"><LibraryViewer toggleLibraryModal={(type) => this.toggleLibraryModal(type)} ref="imageViewer" {...this.props} type="image" /></TabPane>
                        <TabPane forceRender tab="GIFs" key="2"><LibraryViewer toggleLibraryModal={(type) => this.toggleLibraryModal(type)} ref="gifViewer" {...this.props} type="gifs" /></TabPane>
                        <TabPane forceRender tab="Videos" key="3"><LibraryViewer toggleLibraryModal={(type) => this.toggleLibraryModal(type)} ref="videoViewer" {...this.props} type="videonew" /></TabPane>
                    </Tabs>
                </Card>

                <LibraryModal
                    {...this.props}
                    uploadType={this.state.libraryModal.type}
                    onCancel={() => this.toggleLibraryModal()}
                    visible={this.state.libraryModal.visible}
                    hidePicker={true}
                    onChoose={() => this.uploadSuccess()} />
            </div>
        );
    }
}

export default Library;