import React from "react";
import "./index.scss";
import Pieslice from "../../Partials/Pieslice";

const DeviceWiseStats = props => {
  const { device_wise_clicks } = props;

  return (
    <Pieslice data={device_wise_clicks} category="device" type={props.type} />
  );
};

export default DeviceWiseStats;
