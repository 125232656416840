import React, { Component } from 'react';
import { Input, Select, Switch, Row, Col, Button, message, Table, Form, Modal, Tooltip, Icon } from 'antd';
import { set, merge } from 'lodash';
import ColorPicker from '../ColorPicker';
const { Option } = Select;
const { confirm } = Modal;

class FormBuilder extends Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.key = values.name.toLowerCase().replace(/\s/g, '_');
        if (values.key === 'email') {
          values.key = "_email";
        }
        if (values.key === 'name') {
          values.key = "_name";
        }
        if (values.options) {
          values.options = values.options.split("\n");
        }
        if (this.props.onSubmit) {
          this.props.onSubmit(values);
        }
      }
    });
  }
  componentDidMount() {
    const { setFieldsValue } = this.props.form;
    if (Object.keys(this.props.data).length) {
      const data = this.props.data;
      if (data.name) {
        setFieldsValue({ name: data.name });
      }
      if (data.type) {
        setFieldsValue({ type: data.type });
      }
      if (data.options) {
        setFieldsValue({ options: data.options.join("\n") });
      }
    }
  }
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    return <Form onSubmit={this.handleSubmit} className="login-form">
      <Form.Item label="Field Name">
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'This is a required field!' }],
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Field Type">
        {getFieldDecorator('type', {
          rules: [{ required: true, message: 'This is a required field!' }],
        })(
          <Select>
            <Option value="text">Text</Option>
            <Option value="email">Email</Option>
            <Option value="url">URL</Option>
            <Option value="select">Dropdown</Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Dropdown options (Enter one option per line)" style={{ display: getFieldValue("type") === "select" ? "block" : "none" }}>
        {getFieldDecorator('options', {
          rules: getFieldValue("type") === "select" ? [{ required: true, message: 'This is a required field!' }] : [],
        })(
          <Input.TextArea />
        )}
      </Form.Item>
      <Button type="primary" className="btn-green" htmlType="submit" style={{ margin: 'auto' }}>Save</Button>
    </Form>
  }
}
const WrappedFormBuilder = Form.create()(FormBuilder);

class QNAElement extends Component {

  state = {
    saving: false,
    fieldsModal: {
      visible: false,
      index: undefined,
    },
    element: {
      element_name: '',
      element_category: '',
      element_type: 'qna',
      element_group: 'self_interacting_elements',
      element_domid: this.props.match.params.domid,
      data: {
        question: '',
        fields: [],
        customurlenabled: "0",
        submitbtntext: "Submit",
        redirect_url: "",
        submittextcolor: "#fff",
        submitbgcolor: "#0000ff"
      }
    },
    errors: {}
  }

  componentDidMount() {
    if (this.props.element !== undefined) {
      this.setState({
        element: merge(this.state.element, this.props.element)
      })
    }
  }

  saveElement() {
    const element = this.state.element;
    let errors = {};
    if (element.element_name.trim().length === 0) {
      errors.element_name = "This is a required field!";
    }
    if (element.data.question.trim().length === 0) {
      errors.question = "This is a required field!";
    }
    if (element.data.fields.length === 0) {
      errors.fields = "You must add at least one field!";
    }
    if (element.data.submitbtntext.trim().length === 0) {
      errors.submitbtntext = "This is a required field!";
    }
    if (element.data.customurlenabled === '1' || element.data.customurlenabled === 1) {
      if (!element.data.redirect_url.trim().match(/https?:\/\/([a-zA-Z0-9-]+\.)\w+\/?[a-zA-Z0-9-/]*\??.*/)) {
        errors.redirect_url = "Please enter a valid URL!";
      }
    }

    if (Object.keys(errors).length === 0) {
      this.setState({
        errors: {},
        saving: true,
      }, () => {
        if (this.props.saveElement) {
          this.props.saveElement(this.state.element, (res) => {
            this.setState({
              saving: false
            })
            if (res.data.success !== 1) {
              message.error(res.data.msg);
            }
          })
        }
      });
    } else {
      this.setState({ errors })
    }
  }

  updateField(path, value) {
    let c = this.state.element;
    if (path.constructor === Array) {
      path.forEach(pv => c = set(c, pv.path, pv.value))
    } else {
      c = set(this.state.element, path, value);
    }
    this.setState({
      element: c
    }, () => this.props.onUpdate(this.state.element));
  }

  deleteField(index) {
    confirm({
      title: 'Are you sure?',
      content: 'Are you sure you want to delete this field?',
      onOk: () => {
        let fields = [...this.state.element.data.fields.slice(0, index), ...this.state.element.data.fields.slice(index + 1)];
        this.setState({
          element: {
            ...this.state.element,
            data: {
              ...this.state.element.data,
              fields
            }
          }
        }, () => this.props.onUpdate(this.state.element));
      },
      onCancel: () => { },
    })
  }

  toggleFieldsModal(index = undefined) {
    this.setState({
      fieldsModal: {
        visible: !this.state.fieldsModal.visible,
        index: index,
      }
    })
  }

  onFieldsFormSubmit(values) {
    let fields = this.state.element.data.fields;
    let index = this.state.fieldsModal.index;
    if (this.state.fieldsModal.index !== undefined) {
      fields = [...fields.slice(0, index), values, ...fields.slice(index + 1)];
    } else {
      fields = [...fields, values];
    }
    this.setState({
      element: {
        ...this.state.element,
        data: {
          ...this.state.element.data,
          fields
        }
      },
      fieldsModal: {
        visible: false,
        index: undefined
      }
    }, () => this.props.onUpdate(this.state.element));
  }

  render() {
    return (
      <form>
        <h1 className="element-form-title">{this.props.formTitle}</h1>
        <Modal
          destroyOnClose
          title="Add Field"
          visible={this.state.fieldsModal.visible}
          onCancel={() => this.toggleFieldsModal()}
          footer={null}>
          <WrappedFormBuilder onSubmit={(values) => this.onFieldsFormSubmit(values)} data={this.state.fieldsModal.index !== undefined ? this.state.element.data.fields[this.state.fieldsModal.index] : {}} />
        </Modal>

        <div className={`ant-form-item-control ${this.state.errors.element_name ? 'has-error' : ''}`}>
          <p className="form-labels">Name
          <Input
              value={this.state.element.element_name}
              onChange={(e) => { this.updateField("element_name", e.target.value) }} />
          </p>
          {this.state.errors.element_name && <div className="ant-form-explain">{this.state.errors.element_name}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Category</p>
          <Select
            value={this.state.element.element_category ? this.state.element.element_category : []}
            onChange={(val) => { this.updateField("element_category", val[val.length - 1]) }}
            showSearch
            mode="tags"
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {this.props.categories.map((c) => <Option key={c._id} value={c._id}>{c._id}</Option>)}
          </Select>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.question ? 'has-error' : ''}`}>
          <p className="form-labels">QNA Form Heading
          <Input
              value={this.state.element.data.question}
              onChange={(e) => { this.updateField("data.question", e.target.value) }} />
          </p>
          {this.state.errors.question && <div className="ant-form-explain">{this.state.errors.question}</div>}
        </div>

        <div className={`ant-form-item-control ${this.state.errors.fields ? 'has-error' : ''}`}>
          <p className="form-labels">Input Fields for Form Entry (<a href="#/" onClick={() => this.toggleFieldsModal()}>Add</a>)</p>
          {this.state.errors.fields && <div className="ant-form-explain">{this.state.errors.fields}</div>}
        </div>

        <div className="fields">
          <Table columns={[
            {
              title: 'Field',
              dataIndex: 'name',
            }, {
              title: 'Type',
              dataIndex: 'type',
              render: (text) => {
                const types = { text: "Text", "email": "Email", url: "URL", select: "Dropdown" };
                return types[text];
              }
            }, {
              title: 'Actions',
              dataIndex: null,
              render: (_, f, index) => <span><a href="#/" onClick={() => this.toggleFieldsModal(index)}>Edit</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="#/" onClick={() => this.deleteField(index)}>Delete</a></span>
            }
          ]}
            pagination={false}
            dataSource={this.state.element.data.fields} />
          <div className={`ant-form-item-control `}></div>
        </div>

        <div className={`ant-form-item-control ${this.state.errors.submitbtntext ? 'has-error' : ''}`}>
          <p className="form-labels">Submit Button Text
          <Input
              value={this.state.element.data.submitbtntext}
              onChange={(e) => { this.updateField("data.submitbtntext", e.target.value) }} />
          </p>
          {this.state.errors.submitbtntext && <div className="ant-form-explain">{this.state.errors.submitbtntext}</div>}
        </div>

        <div className={`ant-form-item-control`}>
          <p className="form-labels">Enable Custom Thank You URL &nbsp;&nbsp;&nbsp;
            <Switch
              disabled={!(this.props.plan && this.props.plan.plan_features && this.props.plan.plan_features.CUSTOM_URLS === 1)}
              checked={this.state.element.data.customurlenabled === '1' || this.state.element.data.customurlenabled === 1}
              onChange={(checked) => { this.updateField("data.customurlenabled", checked ? '1' : '0') }} />
            {!(this.props.plan && this.props.plan.plan_features && this.props.plan.plan_features.CUSTOM_URLS === 1) && <Tooltip title="Your plan does not allow you to add a Custom Thank You URL!"><Icon type="info-circle" style={{ marginLeft: 15, verticalAlign: 'middle' }} /></Tooltip>}
          </p>
        </div>
        {(this.state.element.data.customurlenabled === '1' || this.state.element.data.customurlenabled === 1) && <div className="url-settings indented">
          <div className={`ant-form-item-control ${this.state.errors.redirect_url ? 'has-error' : ''}`}>
            <p className="form-labels">Custom Thank You URL
            <Input
                value={this.state.element.data.redirect_url}
                onChange={(e) => { this.updateField("data.redirect_url", e.target.value) }}
                onBlur={(e) => { this.updateField("data.redirect_url", e.target.value.match(/https?:\/\/.*/) ? e.target.value : `http://${e.target.value}`) }} />
            </p>
            {this.state.errors.redirect_url && <div className="ant-form-explain">{this.state.errors.redirect_url}</div>}
          </div>
        </div>}

        <Row>
          <Col xs={12}><ColorPicker label="Submit Button Text Color" defaultValue={this.state.element.data.submittextcolor} onChange={(color) => this.updateField("data.submittextcolor", color)} /></Col>
          <Col xs={12}><ColorPicker label="Submit Button Background Color" defaultValue={this.state.element.data.submitbgcolor} onChange={(color) => this.updateField("data.submitbgcolor", color)} /></Col>
        </Row>

        <Row>
          <Button className="btn-green" size="large" onClick={() => this.saveElement()} loading={this.state.saving}>Save</Button>
          <Button type="danger" onClick={() => this.props.onCancel()} size="large">Cancel</Button>
        </Row>
      </form>
    )
  }

}

export default QNAElement;