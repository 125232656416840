import React, { Component } from 'react';
import './index.scss';
import { Card, Menu, Dropdown, Icon, Alert, Modal, Skeleton, Row, Tooltip, message } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ClickToCopy from '../Partials/ClickToCopy';
import { toggleElementArchive } from '../../../helpers/ajax/elementsAjax';
import ElementPreview from './ElementPreview';
import CopyAutoresponderCodeModal from './CopyAutoresponderCodeModal';
import './ElementCard.scss';

class ElementCard extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: '',
    element: this.props.element ? this.props.element : {},
    autoresponderModal: {
      visible: false
    }
  };

  toggleCampaignArchive = (element) => {
    const { domain } = this.props.auth;

    Modal.confirm({
      title: 'Are you sure?',
      content: `This action will ${element.element_archived === 1 ? 'un' : ''}archive this element`,
      okText: 'Confirm',
      onOk: () => toggleElementArchive(domain.id, element._id).then((res) => {
        if (res.data.success === 1) {
          message.success(`Campaign ${element.element_archived === 1 ? 'Unarchived' : 'Archived'} Successfully!`);
          this.setState({
            element: {
              ...this.state.element,
              element_archived: parseInt(this.state.element.element_archived) === 1 ? 0 : 1,
            }
          })
          if (this.props.onArchive) {
            this.props.onArchive(element);
          }
        } else {
          message.error(res.data.msg);
        }
      }).catch((e) => {
        message.error(e.message);
      })
    });
  };

  toggleAutoresponderModal() {
    this.setState({
      autoresponderModal: { visible: !this.state.autoresponderModal.visible }
    })
  }

  render() {
    const { loading, error } = this.state;
    if (loading) {
      return <Card style={{ marginBottom: "30px" }}>
        <Skeleton active />
      </Card>
    }

    if (!loading && error) {
      return <div>
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      </div>;
    }

    const { element } = this.state;
    const groups = {
      "autoplay_video": "AUTOPLAY VIDEOS",
      "self_interacting_elements": "Self Interacting Elements",
      "conversion_elements": "Conversion Elements",
      "growth_elements": "Engaging / Growth Elements",
      "others": "Others",
      "messenger_elements": "Messenger Widgets"
    };
    const types = {
      "video_to_gif": "VIDEO",
      'personalised_video': "Personalised Video",
      'messenger_personalised_video': "Personalised Video for Messenger",
      "yes_no_bar": "Yes / No Bar",
      "feedback": "FEEDBACK",
      "polls": "POLLS",
      "qna": "QNA",
      "link_preview": "Link Preview",
      "self_destruct_message": "Self Destructing Message",
      "youtube_embed": "Youtube Embed",
      "vimeo_embed": "Vimeo Embed",
      "googlemap_embed": "Google Map Embed",
      "instagram_embed": "Instagram embed",
      "pinterest_embed": "Pinterest embed",
      "image_embed": "Image embed",
      "gif_embed": "Gif embed",
      "social_badges": "Social Badges",
      "social_share_buttons": "Social Share Buttons",
      "cta_button": "CTA Button",
      "timers": "Timer",
      "hellobar_timer": "Hellobar Timer",
      "personalized_images": "Personalized Images",
      "messenger_personalized_images": "Personalized Images for Messenger",
      "footer_widget": "Footer Widget",
      "signature": "Signature",
      "twitter_tweet": "Twitter Tweet Embed",
      "twitter_timeline": "Twitter Timeline Embed",
      "group_event_poll": "Group Event Poll"
    }
    let clicks = 0;
    if (element.analytics && element.analytics.clicks && element.analytics.clicks.overall && element.analytics.clicks.overall) {
      clicks = element.analytics.clicks.overall.count;
    }

    let views = 0;
    if (element.analytics && element.analytics.views && element.analytics.views.overall && element.analytics.views.overall.count) {
      views = element.analytics.views.overall.count;
    }
    return (
      <Card className="element">
        <div className="element-preview">
          <ElementPreview ref="preview" element={element} />
        </div>
        <p className="preview-label">{element.processing && (element.processing === 1 || element.processing === '1') ? <span><Icon type="loading" style={{ fontSize: 18 }} /> PROCESSING</span> : 'LIVE PREVIEW'}</p>
        <div className="element-content">
          <div className="element-details">
            <ul>
              <li><b>NAME</b><br />{element.element_name}</li>
              <li><b>CATEGORY</b><br />{element.element_category ? element.element_category : "-"}</li>
              <li><b>GROUP</b><br />{groups[element.element_group]}</li>
              <li><b>TYPE</b><br />{types[element.element_type]}</li>
              <li><b>CREATED</b><br />{moment(element.element_created).format("MMM DD, YYYY \\at HH:MM a")}</li>
              <li style={{ color: "#09a664" }}><b>#IMPRESSIONS<br />{views}</b></li>
              <li style={{ color: "#09a664" }}><b>#CLICKS<br />{clicks}</b></li>
            </ul>
          </div>
          <div className="element-actions">
            <ul>
              <li>
                {(element.processing === 1 || element.processing === '1') ? (
                  <a className="ant-dropdown-link" href="#/">
                    <Tooltip title="You can copy the code only once the processing is complete."><Icon type="copy" /> <br /> Copy <Icon type="down" /></Tooltip>
                  </a>
                ) : (
                    <Dropdown overlay={
                      element.element_group === "messenger_elements" ? (
                        <Menu>
                          <Menu.Item>
                            <ClickToCopy style={{ color: 'black' }} textToCopy={`https://${element.messengerlink}/main/${element.element_type === "messenger_personalised_video" ? 'getmessengervideo' : 'getmessengerimage'}?elemid=${element._id}`}>Copy ManyChat Link</ClickToCopy>&nbsp;&nbsp;&nbsp;<Tooltip placement="right" title="Use This Function To Copy The Link for Messenger Broadcast"><Icon type="info-circle" /></Tooltip>
                          </Menu.Item>
                          <Menu.Item>
                            <ClickToCopy style={{ color: 'black' }} textToCopy={`https://${element.messengerlink}/main/${element.element_type === "messenger_personalised_video" ? 'getactivechatvideo' : 'getactivechatimage'}/${element._id}`}>Copy ActiveChat Link</ClickToCopy>&nbsp;&nbsp;&nbsp;<Tooltip placement="right" title="Use This Function To Copy The Link for Messenger Broadcast"><Icon type="info-circle" /></Tooltip>
                          </Menu.Item>
                        </Menu>
                      ) : (
                          <Menu>
                            <Menu.Item>
                              <ClickToCopy style={{ color: 'black' }} textToCopy={["qna", "group_event_poll"].indexOf(element.element_type) === -1 ? element.previewString : element.previewString.replace(new RegExp("\\[FNAME\\]", 'g'), "").replace(new RegExp("\\[EMAIL\\]", 'g'), "")}>Copy HTML Code</ClickToCopy>&nbsp;&nbsp;&nbsp;<Tooltip placement="right" title="Use This Function To Copy The HTML Source Code To Place It In Your AutoResponder Emails"><Icon type="info-circle" /></Tooltip>
                            </Menu.Item>
                            <Menu.Item>
                              <a href="#/" onClick={() => this.toggleAutoresponderModal()}>Copy Autoresponder Code&nbsp;&nbsp;&nbsp;<Tooltip placement="right" title="Use This Function To Copy The HTML Source Code To Place It In Your AutoResponder Emails"><Icon type="info-circle" /></Tooltip></a>
                            </Menu.Item>
                            {["qna", "group_event_poll", "personalised_video", "personalized_image"].indexOf(element.element_type) === -1 && <Menu.Item>
                              <a href="#/" onClick={() => this.refs.preview.copyElementPreview()}>Copy Element Preview&nbsp;&nbsp;&nbsp;<Tooltip placement="right" title="Use This Function To Directly Copy The Element Final Preview To Place It In Your Normal Email Service Provider Compose Email Box e.g. in Gmail."><Icon type="info-circle" /></Tooltip></a>
                            </Menu.Item>}
                          </Menu>
                        )
                    }>
                      <a className="ant-dropdown-link" href="#/">
                        <Icon type="copy" /> <br /> Copy <Icon type="down" />
                      </a>
                    </Dropdown>
                  )}
              </li>
              {!this.props.hideReport && <li><Link to={`/${this.props.auth.domain.id}/element_details/${element._id}`}><Icon type="pie-chart" /> <br /> Analytics</Link></li>}
              {(this.props.auth.plan && this.props.auth.plan.plan_features && this.props.auth.plan.plan_features.CLONE_CAMPAIGN === 1) ? (
                <li><Link to={`/${this.props.auth.domain.id}/new_element/${element._id}`}><Icon type="copy" /> <br /> Clone</Link></li>
              ) : (
                  <li><Tooltip title="Your plan does not allow you to clone elements!"><Icon type="copy" /> <br /> Clone</Tooltip></li>
                )}
              <li><a href="#/" onClick={() => this.toggleCampaignArchive(element)}><Icon type="book" /> <br /> {element.element_archived === 1 ? "Unarchive" : "Archive"}</a></li>
            </ul>
            <CopyAutoresponderCodeModal onCancel={() => this.toggleAutoresponderModal()} visible={this.state.autoresponderModal.visible} code={this.state.element.previewString} />
          </div>
        </div>
      </Card>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  };
};

export default connect(mapStateToProps)(ElementCard);