import React, { Component } from 'react';
import MainHeadingBar from '../Partials/MainHeadingBar';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.scss';

class BonusPage extends Component {
  render() {
    const { user, domain } = this.props.auth;
    if (domain.role !== "owner") {
      return <Redirect to='/' />
    }
    if (user.origin && (user.origin !== "JVZOO" && user.origin !== "THRIVECART")) {
      return <Redirect to='/' />
    }

    return (
      <div className="bonus-page">
        <MainHeadingBar title="Bonuses & Special Access" {...this.props} hideCTA />
        <div className="col-md-12">
          <h3>Your Special Gifts</h3>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Description</th>
                <th>Access URL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "200px" }}>Meme Treasure</td>
                <td>A 300+ Base Viral Memes to help you create Unlimited Memes to Share.<br />
                  - A set of 300+ Viral Base Memes<br />
                  - Create Unlimited Viral Memes<br />
                </td>
                <td style={{ width: "150px" }}><a target="_blank" rel="noopener noreferrer" href="https://cdn.letspin.io/bonus/memes.zip">Access Here</a></td>
              </tr>
              <tr>
                <td>Viral SociSpy</td>
                <td>Search FB for Viral Images / Ads basis Keywords &amp; Selected Hot Websites with Options to Time &amp; Relevance</td>
                <td><a target="_blank" rel="noopener noreferrer" href="http://bonus.letx.co/fbsearch/login.php?logid=c921e8540596bb8ae2e58dd57ca10c1f3f2b2e59d4a1dc55a30827f0ee3a1f6e">Access Here</a></td>
              </tr>
              <tr>
                <td>Insta IMG Jacker</td>
                <td>This tool helps in dowloading Instagram Images and Videos.<br />
                  - Shows  total number of downloaded images and videos.<br />
                  - Show last downloaded images and videos.<br />
                  -100% Responsive. Built with Bootstrap.<br />
                  - No Instagram API required.<br />
                </td>
                <td><a target="_blank" rel="noopener noreferrer" href="http://bonus.letx.co/instagram-downloader/login.php?logid=c921e8540596bb8ae2e58dd57ca10c1f3f2b2e59d4a1dc55a30827f0ee3a1f6e">Access Here</a></td>
              </tr>
              <tr>
                <td>iFrameCheck by LetX</td>
                <td>A tool to check whether a website is supported in I-frame or not.</td>
                <td><a target="_blank" rel="noopener noreferrer" href="http://bonus.letx.co/iframe-checker/login.php?logid=c921e8540596bb8ae2e58dd57ca10c1f3f2b2e59d4a1dc55a30827f0ee3a1f6e">Access Here</a></td>
              </tr>
              <tr>
                <td>Instant Squeeze Page Builder</td>
                <td>Instant Squeeze Page Generator takes the hard work of designing a professional squeeze page or Lead Capture page and makes it so easy a baby could do it. If you can point and click, you will be creating squeeze pages in minutes.. Developer licence allows you to use this tool for commercial purposes or for any of your clients and make more while you help your client make more</td>
                <td><a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1vSHbbJSH-7Xqfb92vUXypieiwahGKW3B/view?usp=sharing">Access Here</a></td>
              </tr>
              <tr>
                <td>Search Engine Domination 101</td>
                <td>Modern Guide to SEO	The New Guide To SEO ebook is a unique and completely updated 10,000 + words Training Guide. it's up-to-date, informative, and includes the most useful, cutting edge information on current SEO.</td>
                <td><a target="_blank" rel="noopener noreferrer" href="https://s3.amazonaws.com/bonusclub/The-New-Guide-To-SEO-Video-Upgrade-/NewGuideToSEOVids_mrr.zip">Access Here</a></td>
              </tr>
              <tr>
                <td>Facebook Video Traffic Frenzy</td>
                <td>Turn Any Video you post on Facebook into Traffic Magnet without Spending A Dime on It!

                  Learn how Top Marketers Bring Frenzy of Traffic to Their Websites Using Videos Posted On Facebook Without Spending a Single Dollar.

                  We will take you through a Detailed Video Tutorial onto How To Best Achieve The Above Case - It's Literally One Of Our Traffic Mafia Secrets that we are Revealing.</td>
                <td><a target="_blank" rel="noopener noreferrer" href="https://youtu.be/1XeNX6nDyqU">Access Here</a></td>
              </tr>
              <tr>
                <td>10K Email Manifesto</td>
                <td>Info product on 10K Email Manifesto is all about how to start a biz that makes you upto $10K/mo. It teaches the real path to scale a business and also puts through case studies on how a newbie scaled his email biz to 5 figures a month with some hacks.</td>
                <td><a target="_blank" rel="noopener noreferrer" href="http://bit.ly/10kemailmanifesto">Access Here</a></td>
              </tr>
              <tr>
                <td>FB Ninja 101</td>
                <td>FB Ninja info is all about retargeitng, how to become a super affiliate with FB Ads Ninja Tactics and how to bank in an avg. of $3000 a day with these tactics.</td>
                <td><a target="_blank" rel="noopener noreferrer" href="https://goo.gl/F6snqW">Access Here</a></td>
              </tr>
              <tr>
                <td>Facebook Outreach</td>
                <td>Learn how to find your target audience with the Facebook Ads.</td>
                <td><a target="_blank" rel="noopener noreferrer" href="http://bit.ly/facebookoutreach">Access Here</a></td>
              </tr>
              <tr>
                <td>List Building Mistake Pack</td>
                <td>Uncover the most common mistakes people make with email marketing.</td>
                <td><a target="_blank" rel="noopener noreferrer" href="http://bit.ly/top-list-building-mistakes">Access Here</a></td>
              </tr>
              <tr>
                <td>Conversion Samurai Package</td>
                <td>Learn how to increase your conversions and get the most out of your marketing activities.</td>
                <td><a target="_blank" rel="noopener noreferrer" href="http://bit.ly/conversion-samurai">Access Here</a></td>
              </tr>
            </tbody>
          </table>

          <br /><br />
          <h1>Agency Bonuses</h1>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Description</th>
                <th>Access URL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Agency Material</td>
                <td></td>
                <td>
                  {this.props.auth.plan.plan_features && this.props.auth.plan.plan_features.AGENCY_LICENSE === 1 ? (
                    <a target="_blank" rel="noopener noreferrer" href="https://paper.dropbox.com/doc/MailEngageX-Agency-Bonuses--Ak8CFeP66EyLIx0F74FSim6VAg-jRSCyJAAa48kE3BxwhRlk">Access Here</a>
                  ) : (
                      <a href="#!" data-toggle="modal" data-target="#jvzooUpgradeModal">Access Here (Upgrade)</a>
                    )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth
});

export default connect(mapStateToProps)(BonusPage);